import {
  FETCH_POSTS,
  FETCH_POSTS_SUCCESS,
  FETCH_POSTS_FAILURE,
  FETCH_POST,
  FETCH_POST_SUCCESS,
  FETCH_POST_FAILURE,
  RESET_BLOG_PAGE,
} from '../actions/types';

const INITIAL_STATE = {
  posts: [],
  skip: 0,
  lastDate: new Date(),
  lastPage: false,
  error: null,
  isLoading: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_POSTS:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case FETCH_POSTS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        posts: state.posts ? state.posts.concat(action.payload.posts) : action.payload.posts,
        error: null,
        isLoading: false,
      };
    case FETCH_POSTS_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case FETCH_POST:
      return {
        ...state,
        post: null,
        error: null,
        isLoading: true,
      };
    case FETCH_POST_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      };
    case FETCH_POST_FAILURE:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      };
    case RESET_BLOG_PAGE:
      return {
        ...INITIAL_STATE,
        lastDate: new Date(),
      };
    default:
      return state;
  }
};
