import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, NavLink } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import {
  Button,
  ButtonDropdown,
  Collapse,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarBrand,
  NavItem,
  NavDropdown,
  NavbarToggler,
  Row,
} from 'reactstrap';
import { fetchDestinations, fetchCategories, fetchDestination, resetDestinationPage, fetchCategory, resetCategoryPage, showLogInModal, showSignUpModal, logOut } from './../actions';
import SignUpModal from './SignUpModal';
import LogInModal from './LogInModal';

export const items = [
  {
    title: 'Sobre',
    to: {
      pt_BR: '/sobre',
      en_US: '/en_US/about',
    },
    id: 'sobre',
    dropdown: [
      {
        title: 'Quem Somos',
        to: {
          pt_BR: '/quem-somos',
          en_US: '/en_US/vv',
        },
      },
      {
        title: 'Parceiros',
        to: {
          pt_BR: '/parceiros',
          en_US: '/en_US/partners',
        },
      },
      {
        title: 'Imprensa',
        to: {
          pt_BR: '/imprensa',
          en_US: '/en_US/press',
        },
      },
    ],
  },
  {
    title: 'Destinos',
    to: {
      pt_BR: '/destinos',
      en_US: '/destinations',
    },
    id: 'destinos',
    dropdown: [],
    onMouseEnter: true,
  },
  {
    title: 'Projetos VV',
    to: {
      pt_BR: '/projetos',
      en_US: '/en_US/projects',
    },
    id: 'projetos',
    dropdown: [],
    onMouseEnter: true,
  },
  {
    title: 'Blog',
    to: {
      pt_BR: '/blog',
      en_US: '/en_US/blog',
    },
  },
  {
    title: 'Contato',
    to: {
      pt_BR: '/contato',
      en_US: '/en_US/contact',
    },
  },
];

const localization = {
  pt_BR: {
    image: 'flag-br.png',
    Destinos: 'Destinos',
    'Projetos VV': 'Projetos VV',
    Sobre: 'Sobre',
    'Quem Somos': 'Quem Somos',
    Parceiros: 'Parceiros',
    Blog: 'Blog',
    Imprensa: 'Imprensa',
    Contato: 'Contato',
    Olá: 'Olá',
    Viagens: 'Minhas experiências',
    'Detalhes da Conta': 'Detalhes da Conta',
    '/conta': '/conta',
    '/viagens': '/viagens',
    Sair: 'Sair',
    Entrar: 'Entrar',
    Cadastrar: 'Cadastrar',
  },
  en_US: {
    image: 'flag-us.png',
    Destinos: 'Destinations',
    'Projetos VV': 'VV Projects',
    Sobre: 'About',
    'Quem Somos': 'VV',
    Parceiros: 'Partners',
    Blog: 'Blog',
    Imprensa: 'Press',
    Contato: 'Contact',
    Olá: 'Hi',
    Viagens: 'My experiences',
    'Detalhes da Conta': 'Account Details',
    '/conta': '/account',
    '/viagens': '/trips',
    Sair: 'Exit',
    Entrar: 'Log In',
    Cadastrar: 'Sign Up',
  },
};

class NavigationBar extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.state = {
      isOpen: false,
      dropdown: {
        duvidas: false,
        sobre: false,
        account: false,
        locale: false,
      },
    };
  }
  componentWillMount() {
    const { location } = this.props;
    const locale = localization[location.pathname.split('/')[1]] ? location.pathname.split('/')[1] : 'pt_BR';
    this.props.fetchDestinations(locale);
    this.props.fetchCategories();
  }
  toggle() {
    this.setState({
      dropdown: {
        account: !this.state.dropdown.account,
      },
    });
  }
  toggleMenu() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }
  renderLanguageDropDown() {
    const {
      history,
      location,
    } = this.props;
    const locale = localization[location.pathname.split('/')[1]] ? location.pathname.split('/')[1] : 'pt_BR';
    return (
      <ButtonDropdown
        id="locale"
        isOpen={this.state.dropdown.locale}
        toggle={() => {
        this.setState({
          dropdown: {
            locale: !this.state.dropdown.locale,
          },
        });
      }}
      >
        <DropdownToggle caret>
          <img alt={locale} height="20px" src={`/assets/${localization[locale].image}`} />
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem
            onClick={(e) => {
              e.preventDefault();
              history.push('/en_US');
              this.toggleMenu();
            }}
          >
            <img alt="us" style={{ marginRight: 10 }} height="20px" src="/assets/flag-us.png" />English
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem
            onClick={(e) => {
              e.preventDefault();
              history.push('/');
              this.toggleMenu();
            }}
          >
            <img alt="br" style={{ marginRight: 10 }} height="20px" src="/assets/flag-br.png" />Português
          </DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>
    );
  }
  render() {
    const { isOpen } = this.state;
    const {
      history,
      location,
      destinations,
      categories,
      user,
    } = this.props;
    const locale = localization[location.pathname.split('/')[1]] ? location.pathname.split('/')[1] : 'pt_BR';
    destinations.sort((a, b) => {
      if (a[locale].nameNoDiacritics < b[locale].nameNoDiacritics) {
        return -1;
      }
      if (a[locale].nameNoDiacritics > b[locale].nameNoDiacritics) {
        return 1;
      }
      return 0;
    });
    if (destinations.length > 0) {
      items[1].dropdown = [];
      destinations.map(destination => (
        items[1].dropdown.push({
          title: destination[locale].name,
          to: {
            pt_BR: `/destinos/${destination.slug}`,
            en_US: `/en_US/destinations/${destination.slug}`,
          },
          onClick: true,
          slug: destination.slug,
        })
      ));
    }
    if (categories.length > 0) {
      items[2].dropdown = [];
      categories.map((category) => {
        if (category.slug !== 'projetos') {
          items[2].dropdown.push({
            title: category[locale].name,
            to: {
              pt_BR: `/projetos-vv/${category.slug}`,
              en_US: `/en_US/vv-projects/${category.slug}`,
            },
            onClick: true,
            slug: category.slug,
          });
        }
        return null;
      });
    }
    return (
      <div>
        <SignUpModal locale={locale} />
        <LogInModal locale={locale} />
        <Navbar light>
          <MediaQuery minWidth={992}>
            <Container>
              <NavbarBrand href={`/${locale === 'pt_BR' ? '' : locale}`} style={{ marginLeft: 15 }}>
                <img alt="logo" height="40px" src="/assets/logo.png" />
              </NavbarBrand>
              <Nav className="float-right">
                {
                  items.map(item => (
                    <div key={item.to[locale]}>
                      {!item.dropdown &&
                        <NavItem>
                          <NavLink to={item.to[locale]} activeClassName="active" activeStyle={{ textDecoration: 'none', color: '#5593aa' }}>{localization[locale][item.title]}</NavLink>
                        </NavItem>
                      }
                      {item.dropdown &&
                        <NavDropdown
                          id={item.id}
                          isOpen={this.state.dropdown[`${item.id}`]}
                          toggle={() => {
                            this.setState({
                              dropdown: {
                                [item.id]: !this.state.dropdown[`${item.id}`],
                              },
                            });
                          }}
                          onMouseEnter={() => {
                            if (item.onMouseEnter && item.onMouseEnter === true) {
                              this.setState({
                                dropdown: {
                                  [item.id]: true,
                                },
                              });
                            }
                          }}
                          onMouseLeave={() => {
                            if (item.onMouseEnter && item.onMouseEnter === true) {
                              this.setState({
                                dropdown: {
                                  [item.id]: false,
                                },
                              });
                            }
                          }}
                        >
                          <DropdownToggle
                            nav
                            caret
                            onClick={() => {
                              if (item && (item.to.pt_BR === '/destinos' || item.to.pt_BR === '/projetos')) {
                                history.push(`${item.to[locale]}`);
                              }
                            }}
                          >
                            {localization[locale][item.title]}
                          </DropdownToggle>
                          <DropdownMenu>
                            {
                              item.dropdown.map(dropdownItem => (
                                <Row
                                  key={dropdownItem.title}
                                  style={{
                                    marginLeft: 15,
                                    marginTop: 5,
                                    marginBottom: 5,
                                    marginRight: 0,
                                  }}
                                >
                                  <NavLink
                                    to={dropdownItem.to[locale]}
                                    activeClassName="active"
                                    activeStyle={{ textDecoration: 'none', color: '#5593aa' }}
                                    onClick={() => {
                                      if (item.id === 'destinos' && dropdownItem.onClick) {
                                        this.props.resetDestinationPage();
                                        this.props.fetchDestination(dropdownItem.slug);
                                      } else if (item.id === 'projetos') {
                                        this.props.resetCategoryPage();
                                        this.props.fetchCategory(dropdownItem.slug);
                                      }
                                      this.setState({
                                        dropdown: {
                                          [item.id]: false,
                                        },
                                      });
                                    }}
                                  >
                                    {localization[locale][dropdownItem.title] ?
                                      localization[locale][dropdownItem.title] :
                                      dropdownItem.title}
                                  </NavLink>
                                </Row>
                              ))
                            }
                          </DropdownMenu>
                        </NavDropdown>
                      }
                    </div>
                  ))
                }
                {!user &&
                  <div>
                    <Button
                      outline
                      color="primary"
                      style={{ marginRight: 10 }}
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.showSignUpModal();
                      }}
                    >
                      {localization[locale].Cadastrar}
                    </Button>
                    <Button
                      color="primary"
                      style={{ marginRight: 10 }}
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.showLogInModal();
                      }}
                    >
                      {localization[locale].Entrar}
                    </Button>
                  </div>
                }
                {user &&
                  <div style={{ marginTop: -16 }}>
                    <NavDropdown isOpen={this.state.dropdown.account} toggle={this.toggle}>
                      <DropdownToggle>
                        <Row>
                          {`${localization[locale]['Olá']}, ${user.firstName}`}
                          <svg className="svg-button" viewBox="0 0 24 24">
                            <path d="M12,19.2C9.5,19.2 7.29,17.92 6,16C6.03,14 10,12.9 12,12.9C14,12.9 17.97,14 18,16C16.71,17.92 14.5,19.2 12,19.2M12,5A3,3 0 0,1 15,8A3,3 0 0,1 12,11A3,3 0 0,1 9,8A3,3 0 0,1 12,5M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12C22,6.47 17.5,2 12,2Z" />
                          </svg>
                        </Row>
                      </DropdownToggle>
                      <DropdownMenu right className="user-dropdown-menu">
                        <DropdownItem
                          onClick={() => {
                            history.push(`${locale === 'pt_BR' ? '' : `/${locale}`}${localization[locale]['/viagens']}`);
                          }}
                        >
                          {localization[locale].Viagens}
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            history.push(`${locale === 'pt_BR' ? '' : `/${locale}`}${localization[locale]['/conta']}`);
                          }}
                        >
                          {localization[locale]['Detalhes da Conta']}
                        </DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem
                          onClick={() => {
                            this.props.logOut();
                          }}
                        >
                          {localization[locale].Sair}
                        </DropdownItem>
                      </DropdownMenu>
                    </NavDropdown>
                  </div>
                }
                {this.renderLanguageDropDown()}
              </Nav>
            </Container>
          </MediaQuery>
          <MediaQuery maxWidth={991}>
            <NavbarToggler left onClick={this.toggleMenu} style={{ marginTop: 10 }} />
            <NavbarBrand href="/">
              <img alt="logo" height="40px" src="/assets/logo.png" />
            </NavbarBrand>
            {user &&
              <Nav>
                <NavDropdown isOpen={this.state.dropdown.account} toggle={this.toggle}>
                  <DropdownToggle>
                    <svg className="svg-button">
                      <path d="M12,19.2C9.5,19.2 7.29,17.92 6,16C6.03,14 10,12.9 12,12.9C14,12.9 17.97,14 18,16C16.71,17.92 14.5,19.2 12,19.2M12,5A3,3 0 0,1 15,8A3,3 0 0,1 12,11A3,3 0 0,1 9,8A3,3 0 0,1 12,5M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12C22,6.47 17.5,2 12,2Z" />
                    </svg>
                  </DropdownToggle>
                  <DropdownMenu right className="user-dropdown-menu">
                    <DropdownItem
                      onClick={() => {
                        history.push(`${locale === 'pt_BR' ? '' : `/${locale}`}${localization[locale]['/viagens']}`);
                      }}
                    >
                      {localization[locale].Viagens}
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => {
                        history.push(`${locale === 'pt_BR' ? '' : `/${locale}`}${localization[locale]['/conta']}`);
                      }}
                    >
                      {localization[locale]['Detalhes da Conta']}
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem
                      onClick={() => {
                        this.props.logOut();
                      }}
                    >
                      {localization[locale].Sair}
                    </DropdownItem>
                  </DropdownMenu>
                </NavDropdown>
              </Nav>
            }
            <Collapse
              isOpen={isOpen}
              navbar
              style={{ margin: 15, marginTop: !user ? 60 : 15 }}
            >
              <Nav vertical>
                <NavItem className="float-right">
                  {this.renderLanguageDropDown()}
                </NavItem>
                {
                  items.map(item => (
                    <div key={item.title}>
                      {item.id !== 'sobre' &&
                        <NavItem>
                          <NavLink
                            to={item.to[locale]}
                            activeClassName="active"
                            onClick={() => {
                              this.toggleMenu();
                            }}
                          >
                            {localization[locale][item.title]}
                          </NavLink>
                        </NavItem>
                      }
                      {item.id === 'sobre' &&
                        item.dropdown.map(i => (
                          <NavItem key={i.title}>
                            <NavLink
                              to={i.to[locale]}
                              activeClassName="active"
                              onClick={() => {
                                this.toggleMenu();
                              }}
                            >
                              {localization[locale][i.title]}
                            </NavLink>
                          </NavItem>
                        ))
                      }
                    </div>
                  ))
                }
                {!user && user === null &&
                  <div>
                    <Button
                      outline
                      color="primary"
                      style={{ marginRight: 10 }}
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.showSignUpModal();
                        this.toggleMenu();
                      }}
                    >
                      {localization[locale].Cadastrar}
                    </Button>
                    <Button
                      color="primary"
                      style={{ marginRight: 10 }}
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.showLogInModal();
                        this.toggleMenu();
                      }}
                    >
                      {localization[locale].Entrar}
                    </Button>
                  </div>
                }
              </Nav>
            </Collapse>
          </MediaQuery>
        </Navbar>
      </div>
    );
  }
}

NavigationBar.propTypes = {
  fetchDestinations: PropTypes.func,
  fetchDestination: PropTypes.func,
  resetDestinationPage: PropTypes.func,
  fetchCategories: PropTypes.func,
  fetchCategory: PropTypes.func,
  resetCategoryPage: PropTypes.func,
  logOut: PropTypes.func,
  showLogInModal: PropTypes.func,
  showSignUpModal: PropTypes.func,
  history: PropTypes.shape({}),
  location: PropTypes.shape({}),
  destinations: PropTypes.arrayOf(PropTypes.shape({})),
  categories: PropTypes.arrayOf(PropTypes.shape({})),
  user: PropTypes.shape({}),
};

function mapStateToProps({ userReducer, destinationsReducer, categoriesReducer }) {
  return { ...userReducer, ...destinationsReducer, ...categoriesReducer };
}
function mapDispatchToProps(dispatch) {
  return {
    fetchDestinations: (locale) => {
      dispatch(fetchDestinations(locale));
    },
    fetchDestination: (slug) => {
      dispatch(fetchDestination(slug));
    },
    resetDestinationPage: () => {
      dispatch(resetDestinationPage());
    },
    fetchCategories: () => {
      dispatch(fetchCategories());
    },
    fetchCategory: (slug) => {
      dispatch(fetchCategory(slug));
    },
    resetCategoryPage: () => {
      dispatch(resetCategoryPage());
    },
    showLogInModal: () => {
      dispatch(showLogInModal());
    },
    showSignUpModal: () => {
      dispatch(showSignUpModal());
    },
    logOut: () => {
      dispatch(logOut());
    },
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavigationBar));
