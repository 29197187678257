import { Parse } from 'parse';
import {
  FETCH_POSTS,
  FETCH_POSTS_SUCCESS,
  FETCH_POSTS_FAILURE,
  FETCH_POST,
  FETCH_POST_SUCCESS,
  FETCH_POST_FAILURE,
  RESET_BLOG_PAGE,
} from './types';

export function fetchPostsSuccess(posts, skip, lastDate, lastPage) {
  return {
    type: FETCH_POSTS_SUCCESS,
    payload: {
      posts,
      skip,
      lastDate,
      lastPage,
    },
  };
}

export function fetchPostsFailure(error) {
  return {
    type: FETCH_POSTS_FAILURE,
    payload: { error },
  };
}

export function fetchPosts(skip, lastDate) {
  return ((dispatch) => {
    dispatch({ type: FETCH_POSTS });
    const params = {
      skip, lastDate,
    };
    return Parse.Cloud.run('fetchPosts', params).then((results) => {
      dispatch(fetchPostsSuccess(
        JSON.parse(JSON.stringify(results)),
        results.length + skip,
        lastDate,
        results.length === 0,
      ));
    }, (error) => {
      dispatch(fetchPostsFailure(JSON.parse(JSON.stringify(error))));
    });
  });
}

export function fetchPostSuccess(post) {
  return {
    type: FETCH_POST_SUCCESS,
    payload: { post },
  };
}

export function fetchPostFailure(error) {
  return {
    type: FETCH_POST_FAILURE,
    payload: { error },
  };
}

export function fetchPost(slug) {
  return ((dispatch) => {
    dispatch({ type: FETCH_POST });
    return Parse.Cloud.run('fetchPost', { slug }).then((results) => {
      dispatch(fetchPostSuccess(JSON.parse(JSON.stringify(results))));
    }, (error) => {
      dispatch(fetchPostFailure(JSON.parse(JSON.stringify(error))));
    });
  });
}

export function resetBlogPage() {
  return {
    type: RESET_BLOG_PAGE,
  };
}
