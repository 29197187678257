import {
  APPLY_COUPON,
  APPLY_COUPON_SUCCESS,
  APPLY_COUPON_FAILURE,
  RESET_CHECKOUT_FORM,
  START_CHECKOUT,
  START_CHECKOUT_SUCCESS,
  START_CHECKOUT_FAILURE,
  FETCH_BRAND,
  FETCH_BRAND_SUCCESS,
  FETCH_BRAND_FAILURE,
  POSTAL_CODE_ADDRESS,
  POSTAL_CODE_ADDRESS_SUCCESS,
  POSTAL_CODE_ADDRESS_FAILURE,
  CREATE_TOKEN,
  CREATE_TOKEN_SUCCESS,
  CREATE_TOKEN_FAILURE,
  CHANGE_CHECKOUT_FIELD,
  CHECKOUT,
  CHECKOUT_SUCCESS,
  CHECKOUT_FAILURE,
} from '../actions/types';

const INITIAL_STATE = {
  coupon: null,
  couponCode: '',
  couponError: null,
  name: '',
  email: '',
  phoneNumber: '',
  cpf: '',
  birthDate: null,
  street: '',
  addressNumber: '',
  complement: '',
  district: '',
  city: '',
  state: '',
  country: '',
  postalCode: '',
  pagseguroSessionId: null,
  paymentMethods: [],
  paymentMethod: 'creditCard',
  installments: null,
  installment: -1,
  token: null,
  brand: null,
  credit: false,
  debit: false,
  boleto: false,
  number: '',
  expiry: '',
  cvc: '',
  code: null,
  success: null,
  error: null,
  submit: false,
  isLoading: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case APPLY_COUPON:
      return {
        ...state,
        couponError: null,
        isLoading: true,
      };
    case APPLY_COUPON_SUCCESS:
      return {
        ...state,
        couponCode: '',
        coupon: action.payload,
        isLoading: false,
      };
    case APPLY_COUPON_FAILURE:
      return {
        ...state,
        couponError: action.payload,
        isLoading: false,
      };
    case RESET_CHECKOUT_FORM:
      return INITIAL_STATE;
    case START_CHECKOUT:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case START_CHECKOUT_SUCCESS:
      return {
        ...state,
        ...action.payload,
        error: null,
        isLoading: false,
      };
    case START_CHECKOUT_FAILURE:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      };
    case FETCH_BRAND:
      return {
        ...state,
        error: null,
      };
    case FETCH_BRAND_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case FETCH_BRAND_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case POSTAL_CODE_ADDRESS:
      return {
        ...state,
        error: null,
      };
    case POSTAL_CODE_ADDRESS_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case POSTAL_CODE_ADDRESS_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case CREATE_TOKEN:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case CREATE_TOKEN_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      };
    case CREATE_TOKEN_FAILURE:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      };
    case CHANGE_CHECKOUT_FIELD:
      return {
        ...state,
        [action.payload.prop]: action.payload.value,
      };
    case CHECKOUT:
      return {
        ...state,
        error: null,
        isLoading: true,
        submit: true,
      };
    case CHECKOUT_SUCCESS:
      return {
        ...state,
        ...action.payload,
        success: true,
        error: null,
        isLoading: false,
      };
    case CHECKOUT_FAILURE:
      return {
        ...state,
        ...action.payload,
        success: null,
        isLoading: false,
      };
    default:
      return state;
  }
};
