import { Parse } from 'parse';
import validator from 'validator';
import {
  REQUEST_RESET_PASSWORD,
  REQUEST_RESET_PASSWORD_SUCCESS,
  REQUEST_RESET_PASSWORD_FAILURE,
  CHANGE_LOST_PASSWORD_FIELD,
  RESET_LOST_PASSWORD_FORM,
} from './types';

export function requestResetPasswordSuccess() {
  return {
    type: REQUEST_RESET_PASSWORD_SUCCESS,
  };
}

export function requestResetPasswordFailure(error) {
  return {
    type: REQUEST_RESET_PASSWORD_FAILURE,
    payload: error,
  };
}

export function requestResetPassword(email) {
  return ((dispatch) => {
    dispatch({ type: REQUEST_RESET_PASSWORD });
    if (validator.isEmail(email) === false) {
      dispatch(requestResetPasswordFailure({ message: 'Você precisa inserir um e-mail válido.' }));
      return;
    }
    Parse.User.requestPasswordReset(email).then(() => {
      dispatch(requestResetPasswordSuccess());
    }, (error) => {
      const err = error;
      if (err.code === 205) {
        err.message = error.message.replace('No user found with email', 'Nenhum usuário encontrado com o email');
      }
      dispatch(requestResetPasswordFailure(JSON.parse(JSON.stringify(error))));
    });
  });
}

export function changeLostPasswordField({ prop, value }) {
  return {
    type: CHANGE_LOST_PASSWORD_FIELD,
    payload: { prop, value },
  };
}

export function resetLostPasswordForm() {
  return {
    type: RESET_LOST_PASSWORD_FORM,
  };
}
