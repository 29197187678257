import { Parse } from 'parse';
import {
  FETCH_DESTINATIONS,
  FETCH_DESTINATIONS_SUCCESS,
  FETCH_DESTINATIONS_FAILURE,
  FETCH_DESTINATION,
  FETCH_DESTINATION_SUCCESS,
  FETCH_DESTINATION_FAILURE,
  RESET_DESTINATION_PAGE,
} from './types';


export function fetchDestinationsSuccess(destinations) {
  return {
    type: FETCH_DESTINATIONS_SUCCESS,
    payload: { destinations },
  };
}

export function fetchDestinationsFailure(error) {
  return {
    type: FETCH_DESTINATIONS_FAILURE,
    payload: { error },
  };
}

export function fetchDestinations(locale) {
  return ((dispatch) => {
    dispatch({ type: FETCH_DESTINATIONS });
    return Parse.Cloud.run('fetchDestinations', { locale }).then((results) => {
      dispatch(fetchDestinationsSuccess(JSON.parse(JSON.stringify(results))));
    }, (error) => {
      dispatch(fetchDestinationsFailure(JSON.parse(JSON.stringify(error))));
    });
  });
}


export function fetchDestinationSuccess(destination) {
  return {
    type: FETCH_DESTINATION_SUCCESS,
    payload: { destination },
  };
}

export function fetchDestinationFailure(error) {
  return {
    type: FETCH_DESTINATION_FAILURE,
    payload: { error },
  };
}

export function fetchDestination(slug) {
  return ((dispatch) => {
    dispatch({ type: FETCH_DESTINATION });
    const sessionToken = localStorage.user ? JSON.parse(localStorage.user).sessionToken : null;
    return Parse.Cloud.run('fetchDestination', { slug }, { sessionToken }).then((results) => {
      dispatch(fetchDestinationSuccess(JSON.parse(JSON.stringify(results))));
    }, (error) => {
      dispatch(fetchDestinationFailure(JSON.parse(JSON.stringify(error))));
    });
  });
}

export function resetDestinationPage() {
  return {
    type: RESET_DESTINATION_PAGE,
  };
}
