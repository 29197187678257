import {
  RESET_CONTACT_FORM,
  CHANGE_CONTACT_FIELD,
  SAVE_CONTACT,
  SAVE_CONTACT_SUCCESS,
  SAVE_CONTACT_FAILURE,
} from '../actions/types';

const INITIAL_STATE = {
  name: '',
  email: '',
  message: '',
  phoneNumber: '',
  isLoading: false,
  success: null,
  error: null,
  submit: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RESET_CONTACT_FORM:
      return INITIAL_STATE;
    case CHANGE_CONTACT_FIELD:
      return {
        ...state,
        [action.payload.prop]: action.payload.value,
      };
    case SAVE_CONTACT:
      return {
        ...state,
        contact: null,
        error: null,
        isLoading: true,
        submit: true,
      };
    case SAVE_CONTACT_SUCCESS:
      return {
        ...state,
        ...action.payload,
        name: '',
        email: '',
        message: '',
        phoneNumber: '',
        success: true,
        error: null,
        isLoading: false,
        submit: false,
      };
    case SAVE_CONTACT_FAILURE:
      return {
        ...state,
        ...action.payload,
        success: null,
        isLoading: false,
      };
    default:
      return state;
  }
};
