import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Alert, Button, Col, Container, Row } from 'reactstrap';
import ReactLoading from 'react-loading';
import moment from 'moment-timezone';
import currencyFormatter from 'currency-formatter';
import { startCheckout } from './../actions';
import CheckoutForm from './CheckoutForm';

const localization = {
  pt_BR: {
    1: 'Finalizar Compra',
    2: 'Informações',
    3: 'Data e Hora de Chegada',
    4: 'Data e Horário de Partida',
    5: 'Períodos / Custos',
    6: 'Data de Preferência',
    7: 'Gerar Boleto',
    8: 'Valor',
    9: '/viagens',
  },
  en_US: {
    1: 'Checkout',
    2: 'Information',
    3: 'Date and Time of Arrival',
    4: 'Date and Time of Departure',
    5: 'Period / Cost',
    6: 'Date of Preference',
    7: 'Generate Boleto',
    8: 'Amount',
    9: '/trips',
  },
};

class Checkout extends Component {
  componentWillMount() {
    const { match } = this.props;
    this.props.startCheckout({ objectId: match.params.id });
  }
  onChange(e) {
    e.preventDefault();
    this.props.changeCheckoutField({ prop: e.target.id, value: e.target.value });
  }
  render() {
    const {
      coupon,
      match,
      history,
      order,
      isLoading,
      success,
      error,
    } = this.props;
    const locale = match.params.locale ? match.params.locale : 'pt_BR';
    const couponValue = order && coupon && (order.totalAmount * coupon.value) / 100;
    let message = '';
    if (order) {
      switch (order.status.slug) {
        case 'analisando-pagamento':
          message = `Obrigado! Seu pedido foi registrado com sucesso! Iremos analisar seus dados e
          poderemos, em até 1 dia útil, entrar em contato via telefone para confirmar a compra.`;
          break;
        case 'pago':
          message = 'Obrigado! Seu pedido foi pago com sucesso!';
          break;
        case 'aguardando-pagamento':
          message = `Obrigado! Seu pedido foi registrado com sucesso! Você pode gerar o(s) boleto(s)
          acessando os detalhes da sua viagem no botão abaixo.`;
          break;
        default:
          message = '';
          break;
      }
    }
    const symbol = order && order.currency === 'USD' ? { symbol: 'US$ ' } : null;
    return (
      <Container>
        {isLoading === true &&
          <ReactLoading delay={0} className="spin" type="spin" color="#ccc" height={24} width={24} />
        }
        {(success || (order && message &&
          (order.asaasPayments || order.pagseguroTransaction || order.payPalPayment))) &&
          <Alert color="success">
            {message}
          </Alert>
        }
        {order && (order.asaasPayments || order.pagseguroTransaction || order.payPalPayment) &&
          <Button
            color="primary"
            onClick={(e) => {
              e.preventDefault();
              history.push(`${locale === 'pt_BR' ? `${localization[locale][9]}` : `/${locale}${localization[locale][9]}`}/${order.objectId}`);
            }}
          >
            Ver Detalhes da Viagem
          </Button>
        }
        {error &&
          <Alert color="danger">
            {`Oops! ${error.message}`}
          </Alert>
        }
        {order && !order.asaasPayments && !order.pagseguroTransaction && !order.payPalPayment &&
          <div>
            <h1>{localization[locale][1]}</h1>
            <hr />
            <Row>
              <Col xs="12" sm="12" md="6">
                <img alt={order.project[locale].name} style={{ marginBottom: 10 }} className="img-fluid" src={order.project.cover.largeFile.url} />
                <h3>{order.project[locale].name}</h3>
                <h5>{order.project.destination[locale].name}</h5>
                <hr />
                <h3>{localization[locale][2]}</h3>
                <h5>{localization[locale][5]}</h5>
                {`${order.days} dia(s) - ${currencyFormatter.format(order.amount / 100, { code: order.currency, ...symbol })}`}
                <br /><br />
                {order.date &&
                  <div>
                    <h5 style={{ marginTop: 10 }}>{localization[locale][6]}</h5>
                    {moment(order.date).format('DD/MM/YYYY')}<br />
                  </div>
                }
                {order.project.startDate && order.project.timeZone &&
                  <div>
                    <h5>{localization[locale][3]}</h5>
                    {moment(order.project.startDate.iso).tz(order.project.timeZone).format('DD, MMMM YYYY, HH:mm')}
                    <br />
                    <br />
                  </div>
                }
                {order.project.endDate && order.project.timeZone &&
                  <div>
                    <h5>{localization[locale][4]}</h5>
                    {moment(order.project.endDate.iso).tz(order.project.timeZone).format('DD, MMMM YYYY, HH:mm')}
                    <br />
                    <br />
                  </div>
                }
              </Col>
              <Col>
                <div>
                  <h3 style={{ marginTop: 10 }}>{localization[locale][8]}</h3>
                  {!coupon &&
                    currencyFormatter.format(order.paymentAmount / 100, { code: 'BRL' })
                  }
                  {coupon && order.totalAmount &&
                    <div>
                      <h6>Subtotal</h6>
                      {currencyFormatter.format(order.totalAmount / 100, { code: 'BRL' })}
                      <h6 style={{ marginTop: 10 }}>{`Cupom ${coupon.code.toUpperCase()}`}</h6>
                      {`${currencyFormatter.format(couponValue / 100, { code: 'BRL' })} (${coupon.value}%)`}
                      <hr />
                      <h6>Total</h6>
                      {currencyFormatter.format(order.paymentAmount / 100, { code: 'BRL' })}
                    </div>
                  }
                  <CheckoutForm />
                </div>
              </Col>
            </Row>
          </div>
        }
      </Container>
    );
  }
}

Checkout.propTypes = {
  coupon: PropTypes.shape({}),
  match: PropTypes.shape({}),
  history: PropTypes.shape({}),
  startCheckout: PropTypes.func,
  changeCheckoutField: PropTypes.func,
  order: PropTypes.shape({}),
  isLoading: PropTypes.bool,
  success: PropTypes.bool,
  error: PropTypes.shape({}),
};

function mapStateToProps({ orderReducer, checkoutReducer }) {
  return { ...orderReducer, ...checkoutReducer };
}
function mapDispatchToProps(dispatch) {
  return {
    startCheckout: (params) => {
      dispatch(startCheckout(params));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
