import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Row, Col, Button, Label, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, FormFeedback, FormText, Input, Alert } from 'reactstrap';
import MaskedTextInput from 'react-text-mask';
import { hideSignUpModal, showLogInModal, changeSignUpField, signUp } from './../actions';

const localization = {
  pt_BR: {
    Nome: 'Nome',
    Sobrenome: 'Sobrenome',
    'Esse campo é obrigatório': 'Esse campo é obrigatório',
    'nome@exemplo.com': 'nome@exemplo.com',
    Senha: 'Senha',
    'Pelo menos 6 caracteres': 'Pelo menos 6 caracteres',
    Telefone: 'Telefone',
    'Entraremos em contato em caso de problemas (Dê preferência pelo cadastro do seu celular).': 'Entraremos em contato em caso de problemas (Dê preferência pelo cadastro do seu celular).',
    'Criar Conta': 'Criar Conta',
    'Já sou cadastrado!': 'Já sou cadastrado!',
    Cadastrar: 'Cadastrar',
  },
  en_US: {
    Nome: 'First name',
    Sobrenome: 'Last name',
    'Esse campo é obrigatório': 'This field is required',
    'nome@exemplo.com': 'name@example.com',
    Senha: 'Password',
    'Pelo menos 6 caracteres': 'At least 6 characters',
    Telefone: 'Phone',
    'Entraremos em contato em caso de problemas (Dê preferência pelo cadastro do seu celular).': 'We will contact you in case of problems (Please register your mobile phone).',
    'Criar Conta': 'Sign Up',
    'Já sou cadastrado!': 'Already registered!',
    Cadastrar: 'Sign Up',
  },
};

class SignUpModal extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onClick = this.onClick.bind(this);
    this.toggle = this.toggle.bind(this);
  }
  onChange(e) {
    this.props.changeSignUpField({ prop: e.target.id, value: e.target.value });
  }
  onClick(e) {
    e.preventDefault();
    const {
      firstName,
      lastName,
      email,
      password,
      phoneNumber,
    } = this.props;
    this.props.signUp(firstName, lastName, email, password, phoneNumber);
  }
  toggle() {
    this.props.hideSignUpModal();
  }
  renderForm() {
    const {
      locale,
      firstName,
      lastName,
      email,
      password,
      phoneNumber,
      submit,
    } = this.props;
    return (
      <Form>
        <Row>
          <Col style={{ marginRight: 5 }}>
            <FormGroup row color={submit === true && !firstName ? 'danger' : null}>
              <Label for="firstName">{localization[locale].Nome}</Label>
              <Input id="firstName" type="text" value={firstName} placeholder={localization[locale].Nome} onChange={this.onChange} />
              {submit === true &&
                <FormFeedback hidden={firstName}>{localization[locale]['Esse campo é obrigatório']}</FormFeedback>
              }
            </FormGroup>
          </Col>
          <Col style={{ marginLeft: 5 }}>
            <FormGroup row color={submit === true && !lastName ? 'danger' : null}>
              <Label for="lastName">{localization[locale].Sobrenome}</Label>
              <Input id="lastName" type="text" value={lastName} placeholder={localization[locale].Sobrenome} onChange={this.onChange} />
              {submit === true &&
                <FormFeedback hidden={lastName}>{localization[locale]['Esse campo é obrigatório']}</FormFeedback>
              }
            </FormGroup>
          </Col>
        </Row>

        <FormGroup row color={submit === true && !email ? 'danger' : null}>
          <Label for="email">E-mail</Label>
          <Input id="email" type="email" value={email} placeholder={localization[locale]['nome@exemplo.com']} onChange={this.onChange} />
          {submit === true &&
            <FormFeedback hidden={email}>{localization[locale]['Esse campo é obrigatório']}</FormFeedback>
          }
        </FormGroup>
        <FormGroup row color={submit === true && !password ? 'danger' : null}>
          <Label for="password">{localization[locale].Senha}</Label>
          <Input id="password" type="password" value={password} placeholder={localization[locale]['Pelo menos 6 caracteres']} onChange={this.onChange} />
          {submit === true &&
            <FormFeedback hidden={password}>{localization[locale]['Esse campo é obrigatório']}</FormFeedback>
          }
        </FormGroup>
        <FormGroup row color={submit === true && !phoneNumber ? 'danger' : null}>
          <Label for="phoneNumber">{localization[locale].Telefone}</Label>
          <MaskedTextInput
            id="phoneNumber"
            mask={['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            className="form-control"
            guide
            value={phoneNumber}
            placeholder="(xx) xxxxx-xxxx"
            onChange={this.onChange}
          />
          {submit === true &&
            <FormFeedback hidden={phoneNumber}>{localization[locale]['Esse campo é obrigatório']}</FormFeedback>
          }
          <FormText>
            {localization[locale]['Entraremos em contato em caso de problemas (Dê preferência pelo cadastro do seu celular).']}
          </FormText>
        </FormGroup>
      </Form>
    );
  }
  render() {
    const {
      locale,
      isShowing,
      isLoading,
      error,
    } = this.props;
    return (
      <Modal isOpen={isShowing} toggle={this.toggle} autoFocus={false}>
        {error &&
          <Alert color="danger">
            {`Oops! ${error.message}`}
          </Alert>
        }
        <ModalHeader toggle={this.toggle}>{localization[locale]['Criar Conta']}</ModalHeader>
        <ModalBody>
          {this.renderForm()}
        </ModalBody>
        <ModalFooter>
          <Button
            color="link"
            onClick={(e) => {
              e.preventDefault();
              this.props.hideSignUpModal();
              this.props.showLogInModal();
            }}
          >
            {localization[locale]['Já sou cadastrado!']}
          </Button>
          <Button className="float-right" disabled={isLoading} color="primary" onClick={this.onClick}>{localization[locale].Cadastrar}</Button>
        </ModalFooter>
      </Modal>
    );
  }
}

SignUpModal.propTypes = {
  locale: PropTypes.string,
  hideSignUpModal: PropTypes.func,
  showLogInModal: PropTypes.func,
  changeSignUpField: PropTypes.func,
  signUp: PropTypes.func,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  password: PropTypes.string,
  phoneNumber: PropTypes.string,
  submit: PropTypes.bool,
  isShowing: PropTypes.bool,
  isLoading: PropTypes.bool,
  error: PropTypes.shape({}),
};

function mapStateToProps({ userReducer, signUpModalReducer }) {
  return { ...userReducer, ...signUpModalReducer };
}
function mapDispatchToProps(dispatch) {
  return {
    hideSignUpModal: () => {
      dispatch(hideSignUpModal());
    },
    showLogInModal: () => {
      dispatch(showLogInModal());
    },
    changeSignUpField: (field) => {
      dispatch(changeSignUpField(field));
    },
    signUp: (firstName, lastName, mail, password, phoneNumber) => {
      dispatch(signUp(firstName, lastName, mail, password, phoneNumber));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUpModal);
