import React from 'react';
import ReactDOM from 'react-dom';
import scriptLoader from 'react-async-script-loader';
import PropTypes from 'prop-types';

class PayPalButton extends React.Component {
  constructor(props) {
    super(props);
    window.React = React;
    window.ReactDOM = ReactDOM;
    this.state = {
      showButton: false,
    };
  }
  componentWillMount() {
    const { isScriptLoaded, isScriptLoadSucceed } = this.props;
    if (isScriptLoaded && isScriptLoadSucceed) {
      this.setState({ showButton: true });
    }
  }
  componentWillReceiveProps({ isScriptLoaded, isScriptLoadSucceed }) {
    if (!this.state.show) {
      if (isScriptLoaded && !this.props.isScriptLoaded) {
        if (isScriptLoadSucceed) {
          this.setState({ showButton: true });
        } else {
          console.log('Cannot load Paypal script!');
          this.props.onError();
        }
      }
    }
  }
  componentWillUnmount() {
    delete window.React;
    delete window.ReactDOM;
  }
  render() {
    const client = {
      sandbox: process.env.PAYPAL_CLIENT_ID,
      production: process.env.PAYPAL_CLIENT_ID,
    };
    const env = process.env.PAYPAL_ENV;
    const payment = () => window.paypal.rest.payment.create(
      env,
      client,
      {
        transactions: [
          { amount: { total: this.props.total, currency: this.props.currency } },
        ],
      },
    );
    const onAuthorize = (data, actions) => actions.payment.execute().then(() => {
      const p = Object.assign({}, this.props.payment);
      p.paid = true;
      p.cancelled = false;
      p.payerID = data.payerID;
      p.paymentID = data.paymentID;
      p.paymentToken = data.paymentToken;
      p.returnUrl = data.returnUrl;
      p.reference = this.props.order.objectId;
      this.props.onSuccess(p);
    });
    return (
      <div>
        {this.state.showButton &&
          <paypal.Button.react
            env={env}
            client={client}
            locale={this.props.locale}
            payment={payment}
            style={this.props.style}
            commit
            onAuthorize={onAuthorize}
            onCancel={this.props.onCancel}
          />
        }
      </div>
    );
  }
}

PayPalButton.propTypes = {
  onSuccess: PropTypes.func,
  onCancel: PropTypes.func,
  onError: PropTypes.func,
  isScriptLoaded: PropTypes.bool.isRequired,
  isScriptLoadSucceed: PropTypes.bool.isRequired,
  order: PropTypes.shape({}),
  currency: PropTypes.string.isRequired,
  locale: PropTypes.string,
  payment: PropTypes.shape({}),
  style: PropTypes.shape({}),
  total: PropTypes.number.isRequired,
};

PayPalButton.defaultProps = {
  onSuccess: (payment) => {
    console.log('The payment was succeeded!', payment);
  },
  onCancel: (data) => {
    console.log('The payment was cancelled!', data);
  },
  onError: (err) => {
    console.log('Error loading Paypal script!', err);
  },
};

export default scriptLoader('https://www.paypalobjects.com/api/checkout.js')(PayPalButton);
