import { Parse } from 'parse';
import moment from 'moment';
import {
  APPLY_COUPON,
  // APPLY_COUPON_SUCCESS,
  APPLY_COUPON_FAILURE,
  RESET_CHECKOUT_FORM,
  RESET_ORDER_PAGE,
  START_CHECKOUT,
  START_CHECKOUT_SUCCESS,
  START_CHECKOUT_FAILURE,
  FETCH_BRAND,
  FETCH_BRAND_SUCCESS,
  FETCH_BRAND_FAILURE,
  POSTAL_CODE_ADDRESS,
  POSTAL_CODE_ADDRESS_SUCCESS,
  POSTAL_CODE_ADDRESS_FAILURE,
  CHANGE_CHECKOUT_FIELD,
  CHECKOUT,
  CHECKOUT_SUCCESS,
  CHECKOUT_FAILURE,
} from './types';
import { fetchOrderSuccess } from './../actions';

export function startCheckoutSuccess(res) {
  return {
    type: START_CHECKOUT_SUCCESS,
    payload: { ...res },
  };
}

export function startCheckoutFailure(error) {
  return {
    type: START_CHECKOUT_FAILURE,
    payload: { error },
  };
}

export function startCheckout(params) {
  return ((dispatch) => {
    dispatch({ type: RESET_CHECKOUT_FORM });
    dispatch({ type: RESET_ORDER_PAGE });
    dispatch({ type: START_CHECKOUT });
    const sessionToken = localStorage.user ? JSON.parse(localStorage.user).sessionToken : null;
    return Parse.Cloud.run('startCheckout', params, { sessionToken }).then((res) => {
      const response = JSON.parse(JSON.stringify(res));
      localStorage.pagseguroSessionId = response.pagseguroSessionId;
      dispatch(fetchOrderSuccess(response.order));
      dispatch(startCheckoutSuccess(response));
    }, (err) => {
      dispatch(startCheckoutFailure(JSON.parse(JSON.stringify(err))));
    });
  });
}

// export function applyCouponSuccess(coupon) {
//   return {
//     type: APPLY_COUPON_SUCCESS,
//     payload: coupon,
//   };
// }

export function applyCouponFailure(error) {
  return {
    type: APPLY_COUPON_FAILURE,
    payload: error,
  };
}

export function applyCoupon(params) {
  return (async (dispatch) => {
    try {
      dispatch({ type: APPLY_COUPON });
      if (!params.couponCode) {
        dispatch(applyCouponFailure());
        return;
      }
      const user = JSON.parse(localStorage.user);
      const response = JSON.parse(JSON.stringify(await Parse.Cloud.run('applyCoupon', params, { sessionToken: user.sessionToken })));
      dispatch(fetchOrderSuccess(response.order));
      dispatch(startCheckoutSuccess(response));
    } catch (e) {
      dispatch(applyCouponFailure(e));
    }
  });
}

export function resetCheckoutForm() {
  return {
    type: RESET_CHECKOUT_FORM,
  };
}

export function getCardBrandSuccess(brand) {
  return {
    type: FETCH_BRAND_SUCCESS,
    payload: { brand },
  };
}

export function getCardBrandFailure(error) {
  return {
    type: FETCH_BRAND_FAILURE,
    payload: { error },
  };
}

export function getCardBrand(number, brand) {
  return ((dispatch) => {
    dispatch({ type: FETCH_BRAND });
    if (number.replace(/\s/g, '').length < 6) {
      dispatch(getCardBrandSuccess());
      return;
    }
    if (brand) {
      dispatch(getCardBrandSuccess(brand));
      return;
    }
    const sessionToken = localStorage.user ? JSON.parse(localStorage.user).sessionToken : null;
    const { pagseguroSessionId } = localStorage;
    Parse.Cloud.run('getCardBrand', { number, pagseguroSessionId }, { sessionToken }).then((res) => {
      dispatch(getCardBrandSuccess(JSON.parse(JSON.stringify(res))));
    }, (err) => {
      dispatch(getCardBrandFailure(JSON.parse(JSON.stringify(err))));
    });
  });
}

export function postalCodeAddressSuccess(res) {
  return {
    type: POSTAL_CODE_ADDRESS_SUCCESS,
    payload: { ...res },
  };
}

export function postalCodeAddressFailure(error) {
  return {
    type: POSTAL_CODE_ADDRESS_FAILURE,
    payload: { error },
  };
}

export function postalCodeAddress(postalCode) {
  return ((dispatch) => {
    const pc = postalCode.replace('-', '');
    if (pc.length !== 8) {
      return;
    }
    dispatch({ type: POSTAL_CODE_ADDRESS });
    Parse.Cloud.run('postalCodeAddress', { postalCode: pc }).then((res) => {
      dispatch(postalCodeAddressSuccess(JSON.parse(JSON.stringify(res))));
    }, () => {
      // dispatch(postalCodeAddressFailure(JSON.parse(JSON.stringify(err))));
    });
  });
}

function createToken(params) {
  const promise = new Promise((resolve, reject) => {
    if (params.paymentMethod !== 'creditCard') {
      resolve();
    } else if (!params.card.number || !params.card.brand || !params.card.cvc ||
      !params.card.expiry || params.card.isValid === false) {
      reject(new Error('Você precisa inserir informações válidas do cartão de crédito.'));
    } else {
      window.PagSeguroDirectPayment.createCardToken({
        cardNumber: params.card.number.replace(/\s+/g, ''),
        brand: params.card.brand,
        cvv: params.card.cvc,
        expirationMonth: params.card.expiry.substr(0, 2),
        expirationYear: `20${params.card.expiry.substr(params.card.expiry.length - 2)}`,
        success: (res) => {
          resolve(res.card.token);
        },
        error: (err) => {
          reject(new Error(Object.values(err.errors).toString()));
        },
      });
    }
  });
  return promise;
}

export function changeCheckoutField({ prop, value }) {
  return {
    type: CHANGE_CHECKOUT_FIELD,
    payload: { prop, value },
  };
}

export function checkoutSuccess(order) {
  return {
    type: CHECKOUT_SUCCESS,
    payload: { order },
  };
}

export function checkoutFailure(error) {
  return {
    type: CHECKOUT_FAILURE,
    payload: { error },
  };
}

export function checkout(params) {
  return (async (dispatch) => {
    try {
      dispatch({ type: CHECKOUT });
      if (params.paymentMethod === 'creditCard' && (!params.card || !params.card.number || !params.card.expiry || !params.card.cvc || !params.name || !params.phoneNumber || !params.cpf || !params.birthDate ||
      !params.street || !params.addressNumber || !params.district ||
      !params.city || !params.state || !params.postalCode)) {
        dispatch(checkoutFailure());
        return;
      }
      const { pagseguroSessionId } = localStorage;
      window.PagSeguroDirectPayment.setSessionId(pagseguroSessionId);
      const creditCardToken = await createToken(params);
      if (params.installment === -1) {
        dispatch(checkoutFailure({ message: 'Você precisa selecionar o número de parcelas.' }));
        return;
      }
      const sessionToken = localStorage.user ? JSON.parse(localStorage.user).sessionToken : null;
      const res = await Parse.Cloud.run('endCheckout', {
        ...params,
        order: { objectId: params.order.objectId },
        creditCardToken,
        birthDate: params.birthDate ? moment(params.birthDate).utc().toDate() : params.birthDate,
        hash: window.PagSeguroDirectPayment.getSenderHash(),
        pagseguroSessionId,
      }, { sessionToken });
      dispatch(checkoutSuccess(JSON.parse(JSON.stringify(res))));
    } catch (e) {
      dispatch(checkoutFailure(e));
    }
  });
}

export function checkoutPayPal(order, payment) {
  return ((dispatch) => {
    dispatch({ type: CHECKOUT });
    const sessionToken = localStorage.user ? JSON.parse(localStorage.user).sessionToken : null;
    const params = {
      objectId: order.objectId,
      payment,
    };
    Parse.Cloud.run('endCheckoutPayPal', params, { sessionToken }).then((res) => {
      dispatch(checkoutSuccess(JSON.parse(JSON.stringify(res))));
    }, (err) => {
      dispatch(checkoutFailure(JSON.parse(JSON.stringify(err))));
    });
  });
}
