import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Alert, Container, Col, Row } from 'reactstrap';
import ContactForm from './ContactForm';

const localization = {
  pt_BR: {
    Contato: 'Contato',
  },
  en_US: {
    Contato: 'Contact',
  },
};

function Contact(props) {
  const { error, match, success } = props;
  const locale = match.params.locale ? match.params.locale : 'pt_BR';
  return (
    <div style={{ marginTop: 60 }}>
      <div className="cover">
        <img alt="cover" className="img-fluid" src="/assets/cover-contact.jpg" />
      </div>
      <Container style={{ paddingTop: 40 }}>
        {error &&
          <Alert color="danger">
            {`'Oops! ${error.message}`}
          </Alert>
        }
        {success &&
          <Alert color="success">
            {'Sua mensangem foi enviada com sucesso.'}
          </Alert>
        }
        <h1>{localization[locale].Contato}</h1>
        <Row>
          <Col>
            <ContactForm />
          </Col>
          <Col xs="12" sm="12" md="auto">
            <h3>E-mail</h3>
            <div style={{ marginBottom: 30 }}>
              <a href="mailto:vv@volunteervacations.com.br">vv@volunteervacations.com.br</a>
            </div>
            <h3>WhatsApp</h3>
            <a href="whatsapp://send?phone=+55215521989132838">+55 21 98913-2838</a>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

Contact.propTypes = {
  error: PropTypes.shape({}),
  match: PropTypes.shape({}),
  success: PropTypes.bool,
};

function mapStateToProps({ contactReducer }) {
  return contactReducer;
}

export default connect(mapStateToProps, null)(Contact);
