import {
  RESET_ORDER_FORM,
  CHANGE_ORDER_FIELD,
  FETCH_ORDER,
  FETCH_ORDER_SUCCESS,
  FETCH_ORDER_FAILURE,
  SAVE_ORDER,
  SAVE_ORDER_SUCCESS,
  SAVE_ORDER_FAILURE,
  RESET_ORDER_PAGE,
} from '../actions/types';

const INITIAL_STATE = {
  amount: -1,
  date: null,
  term: false,
  order: null,
  success: null,
  error: null,
  submit: false,
  isLoading: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RESET_ORDER_FORM:
      return INITIAL_STATE;
    case CHANGE_ORDER_FIELD:
      return {
        ...state,
        [action.payload.prop]: action.payload.value,
      };
    case FETCH_ORDER:
      return {
        ...state,
        order: null,
        error: null,
        isLoading: true,
      };
    case FETCH_ORDER_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      };
    case FETCH_ORDER_FAILURE:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      };
    case SAVE_ORDER:
      return {
        ...state,
        error: null,
        submit: true,
        isLoading: true,
      };
    case SAVE_ORDER_SUCCESS:
      return {
        ...state,
        ...action.payload,
        success: true,
        error: null,
        isLoading: false,
      };
    case SAVE_ORDER_FAILURE:
      return {
        ...state,
        ...action.payload,
        success: null,
        isLoading: false,
      };
    case RESET_ORDER_PAGE:
      return INITIAL_STATE;
    default:
      return state;
  }
};
