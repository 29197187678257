import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Alert, Button, CardDeck, Col, Container, ListGroup, ListGroupItem, Row } from 'reactstrap';
import ReactLoading from 'react-loading';
import MediaQuery from 'react-responsive';
import Waypoint from 'react-waypoint';
import { fetchProjects, resetProjectsPage } from './../actions';
import ProjectCard from './ProjectCard';

const localization = {
  pt_BR: {
    1: 'Projetos',
    2: 'Não existem projetos.',
  },
  en_US: {
    1: 'Projects',
    2: 'There is no projects.',
  },
};

class Projects extends Component {
  componentWillUnmount() {
    this.props.resetProjectsPage();
  }
  render() {
    const {
      categories,
      error,
      isLoading,
      lastDate,
      lastPage,
      match,
      location,
      projects,
      skip,
    } = this.props;
    const locale = match.params.locale ? match.params.locale : 'pt_BR';
    const params = new URLSearchParams(location.search);
    const tag = params.get('tag');
    const minAmount = Number(params.get('minAmount'));
    const maxAmount = Number(params.get('maxAmount'));
    const items = [];
    categories.map((category) => {
      if (category.slug !== 'projetos') {
        items.push({
          title: category[locale].name,
          to: {
            pt_BR: `/projetos-vv/${category.slug}`,
            en_US: `/en_US/vv-projects/${category.slug}`,
          },
          onClick: true,
          slug: category[locale].slug,
        });
      }
      return null;
    });
    return (
      <div style={{ marginTop: 60 }}>
        <div>
          <img width="100%" className="img-fluid" src="/assets/cover.png" alt="cover" />
        </div>
        <Container style={{ paddingTop: 40 }}>
          {isLoading === true &&
            <ReactLoading delay={0} className="spin" type="spin" color="#ccc" height={24} width={24} />
          }
          {error &&
            <Alert color="danger">
              {`Oops! ${error.message}`}
            </Alert>
          }
          <div>
            <h1>{localization[locale][1]}</h1>
            {tag &&
              <div style={{ marginTop: 10, marginBottom: 10 }}>
                <Button size="sm" color="primary" key={tag} className="tag-button" style={{ margin: 2.5 }}>{tag}</Button>
              </div>
            }
            <MediaQuery maxWidth={991}>
              <ListGroup
                style={{ marginBottom: 20 }}
              >
                {items.map(item => (
                  <ListGroupItem
                    action
                    key={item.slug}
                    tag="a"
                    href={item.to[locale]}
                  >
                    {item.title}
                  </ListGroupItem>
                ))}
              </ListGroup>
            </MediaQuery>
            <Row>
              <Col>
                <CardDeck>
                  {
                    projects.map(project => (
                      <ProjectCard key={project.objectId} project={project} />
                    ))
                  }
                </CardDeck>
              </Col>
            </Row>
            {projects.length === 0 && isLoading === false &&
              localization[locale][2]
            }
          </div>
          {!error && lastPage === false && isLoading === false &&
            <Waypoint
              onEnter={() => {
                this.props.fetchProjects(
                  skip,
                  lastDate,
                  {
                    tag,
                    minAmount,
                    maxAmount,
                    // startDate: new Date(),
                  },
                );
              }}
            />
          }
        </Container>
      </div>
    );
  }
}

Projects.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape({})),
  error: PropTypes.shape({}),
  match: PropTypes.shape({}),
  location: PropTypes.shape({}),
  fetchProjects: PropTypes.func,
  isLoading: PropTypes.bool,
  lastDate: PropTypes.shape({}),
  lastPage: PropTypes.bool,
  projects: PropTypes.arrayOf(PropTypes.shape({})),
  resetProjectsPage: PropTypes.func,
  skip: PropTypes.number,
};

function mapStateToProps({ projectsReducer, categoriesReducer }) {
  return { ...categoriesReducer, ...projectsReducer };
}
function mapDispatchToProps(dispatch) {
  return {
    fetchProjects: (skip, lastDate, filters) => {
      dispatch(fetchProjects(skip, lastDate, filters));
    },
    resetProjectsPage: () => {
      dispatch(resetProjectsPage());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Projects);
