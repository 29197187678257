import {
  SHOW_LOG_IN_MODAL,
  HIDE_LOG_IN_MODAL,
  CHANGE_LOG_IN_FIELD,
  RESET_LOG_IN_MODAL,
} from '../actions/types';

const INITIAL_STATE = {
  isShowing: false,
  email: '',
  password: '',
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SHOW_LOG_IN_MODAL:
      return {
        ...state,
        isShowing: true,
      };
    case HIDE_LOG_IN_MODAL:
      return {
        ...state,
        isShowing: false,
      };
    case CHANGE_LOG_IN_FIELD:
      return {
        ...state,
        [action.payload.prop]: action.payload.value,
      };
    case RESET_LOG_IN_MODAL:
      return {
        ...state,
        email: '',
        password: '',
      };
    default:
      return state;
  }
};
