import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Card, CardBlock, CardImg, CardText, CardTitle, Container, Col, Row } from 'reactstrap';

const localization = {
  pt_BR: {
    1: 'Quem Somos',
    2: 'A Volunteer Vacations pesquisou, conheceu, analisou, catalogou e fechou parcerias com algumas das mais relevantes ONGs do mundo para poder proporcionar a pessoas dos mais variados perfis uma experiência de curto prazo em uma organização de ajuda humanitária. Cuidamos de todo o planejamento e burocracia e montamos junto com você uma experiência capaz de aliar diversão e a realização pessoal de colaborar com uma causa importante para o nosso planeta.',
    3: 'Uma excelente oportunidade de realizar uma viagem nacional ou internacional, conhecer diferentes culturas, fazer o bem, ter contato com algumas das grandes questões da humanidade e fazer a diferença por um mundo melhor. Tudo de acordo com seu gosto, aptidões e em total segurança. Em um dia você está dando um mergulho refrescante em uma praia paradisíaca de mar azul, e no outro colaborar com um projeto agrícola no coração da África. Após uma semana de compras nos mais modernos shoppings da Asia, que tal amamentar elefantes abandonados na Tailândia?',
    4: 'Queremos aliar férias tradicionais a possibilidade de fazer a diferença, enriquecendo essa experiência de uma maneira que poucas pessoas imaginam ser tão prazerosa: ajudando o próximo!',
    5: 'Missão',
    6: 'A Volunteer Vacations (VV) percebeu que o mundo está cada vez mais voltado e sensibilizado para as questões sociais, conectado com o próximo e preocupado com o futuro da humanidade. Empresas e indivíduos de modo geral começam a perceber a importância e o valor de, simplesmente, fazer o bem. Impulsionado por esse desejo, o conceito de “férias voluntárias” tem crescido imensamente nos últimos tempos.',
    7: 'A duração das viagens que propomos não passa de um mês, é o chamado short-term volunteering. Um pequeno período que proporciona uma grande ajuda! E nada impede que essa experiência de voluntariado seja realizada em conjunto com sua viagem de férias tradicional, com o foco em conhecer outra cultura ou paisagens incríveis. Esse estilo de viajar é tão interessante, divertido e recompensador que seu crescimento já fez com que fosse criado um novo conceito de turismo: o voluntourism.',
    8: 'Voluntourism é esse tipo de viagem de turismo que inclui o trabalho voluntário voltado para ajudar alguma causa significativa. É o turismo consciente! O perfil do turista que faz esse tipo de viagem é diverso, mas normalmente são aqueles que compartilham o desejo de “fazer o bem” ao mesmo tempo que conhecem destinos incríveis que geralmente estão fora dos roteiros tradicionais. E o trabalho da Volunteer Vacations é justamente proporcionar essa experiência para você da maneira mais adequada ao seu perfil, com total segurança e garantindo que sua viagem seja, realmente e efetivamente, inesquecível. Um pouco de espírito de aventura, admiração por culturas diferentes e um desejo enorme de fazer a diferença! É só isso que você precisa para realizar uma experiência que pode mudar a sua vida. E a de muitas outras pessoas!',
    9: 'Alice Ratton é co-fundadora e CFO da Volunteer Vacations, é formada em Engenharia Química pela PUC-Rio (2008), Mestre em Química com ênfase em processos de energia renovável pelo IME (2012) e doutoranda em Planjemaneto de Transporte e Meio Ambiente pela Coppe/UFRJ. Deu aulas de reforço como voluntária no educandário União Casa da Criança em 2010, já foi voluntária no Rio de Janeiro, Goiás, e Costa Rica. Em 2012 ingressou na LVA onde exerceu sua função de engenheira em projetos de desenvolvimentos urbanos sustentáveis. Foi professora de MBA na Celso Lisboa e hoje é consultora em sustentabilidade urbana. Foi co-autora do relatório do Painel Brasileiro de Mudanças Climáticas sobre Tecnologias Disruptivas de Baixo Carbono para setores chaves no Brasil elaborado para tomadores de decisão da COP 23 de Bonn.',
    10: 'Mariana Serra é a idealizadora e co-fundadora da Volunteer Vacations (VV), é formada em Relações Internacionais (ESPM-RJ), foi assistente do Ministro das Relações Exteriores Luiz Felipe Lampreia contribuindo para o desenvolvimento de papers focados em geopolítica e comércio exterior. É apaixonada por viagens, já morou na Nova Zelândia e Taiti onde intensificou seu envolvimento com ações humanitárias. Trabalhava como relações institucionais da Andrade Gutierrez quando decidiu largar seu emprego e criar a VV. Trabalha como voluntária desde jovem e já atuou em projetos sociais e humanitários no Haiti, Índia, Tailândia, Costa Rica, EUA, Brasil, Tanzânia, Quênia, Indonésia, Gana, Etiópia, Moçambique, Nicarágua e atuou em campo de refugiados no Oriente Médio, no Líbano e Jordânia. É co-autora do livro Jovens Empreendedores, líderes do Brasil que dá certo, da editora Leader. Mariana entrou na lista de 30 ABAIXO DE 30, da renomada revista Forbes como uma das jovens mais promissoras do Brasil até 30 anos.',
    11: 'André Fran é diretor, escritor, palestrante e co-fundador da BASE#1 Filmes. Um dos criadores da série Não Conta lá em Casa, de viagens aos destinos mais polêmicos do mundo que o levou ao Irã, Iraque, Afeganistão, Coreia do Norte, a participar de uma missão da ONU no Haiti, de uma expedição humanitária ao Japão pós-tsunami e muitas outras aventuras. A série recebeu prêmios (Melhor Programa de Viagem NET e Melhor Reality da TV O Globo). Fran também é co-autor e diretor do programa Que Mundo É Esse?, da Globo News, com o qual foi até a linha de frente da luta contra o Estado Islâmico entre outras pautas de igual relevância. Fran já rodou o Brasil com suas palestras, se apresentando em escolas, universidades, empresas, em um presídio e em 4 edições do renomado circuito TEDx. Seu primeiro livro “Uma viagem aos destinos mais polêmicos do mundo” foi lançado em 2013 pela editora Record, e ele também já colaborou com a Avianca, Vice, Viagem & Turismo, Boa Viagem, revista Claudia, Trip, Vizoo entre outras.',
  },
  en_US: {
    1: 'About Us',
    2: 'Volunteer Vacations has researched, met, analyzed, catalogued and established partnerships with some of the most relevant NGOs in the world to be able to offer to individuals of various profiles a short-term experience in an humanitarian aid organization. We take care of all the planning and bureaucracy and assemble with you an experience capable of combining entertainment and the personal fulfillment of cooperating with an important cause for our planet.',
    3: 'An excellent opportunity to go on a international or national trip, discover different cultures, do good, be in contact with some of humankind’s great questions and to make a difference for a better world. All according to your taste, skills and in complete safety. One day you’ll be plunging into the refreshing blue waters of a paradisiac beach, on another cooperating with an agricultural project in the heart of Africa. After a week of shopping in the most modern shopping malls of Asia, what about feeding milk to abandoned elephants in Thailand?',
    4: 'We want to combine traditional vacations to the possibility of making a difference, enriching the travel experience in a way few people imagine being so pleasant: helping others!',
    5: 'Mission',
    6: 'Volunteer Vacations (VV) has realized that the world is increasingly sensitive towards social issues, connected with others and concerned about the future of mankind. Companies and individuals have generally started to realize de importance and the value of simply doing good. Driven by this desire, the concept of “volunteer vacations” has grown immensely in recent times.',
    7: 'The duration of the trips we propose is less than a month, what is called short-term volunteering. A small period that provides great help! Nothing stops this volunteer experience from being combined with your traditional vacation trip, focused on discovering another culture or amazing landscapes. This style of travel is so interesting, entertaining and rewarding that its growth has already prompted the creation of a new concept of tourism: voluntourism.',
    8: 'Voluntourism is this type of pleasure trip that includesvolunteer work focused towards helping any significant cause. It’s conscious travel! The profile of the tourist who undertakes this sort of trip is diverse, but usually they share the wish to “do good”while they discover incredible destinations that are usually off the traditional itineraries. Volunteer Vacations’work is exactly to provide this experience in the manner most fitting your profile, with complete safety and insuring that your trip will be, indeed, unforgettable. A bit of adventure spirit, admiration for different cultures and an enormous desire to make a difference! That is all you need to have an experience that may change your life - and those of many others!',
    9: 'Alice Ratton is a co-founder and CFO of Volunteer Vacations. She holds a Chemical Engineering degree from PUC-Rio (2008), a Master’s degree in Chemistry with emphasis on renewable energy processes by IME (2012) and a PhD degree in Transport and Environment Planning by Coppe / UFRJ. She gave reinforcement classes as a volunteer at Educundário União Casa da Criança in 2010, already volunteer in Rio de Janeiro, Goiás, and Costa Rica. In 2012, she joined LVA where she worked as an engineer developping sustainable urbanprojects. She’be been a MBA professor at Celso Lisboa and is now a consultant on urban sustainability. She co-authored the Brazilian Panel on Climate Change Low-Carbon Disruptive Technologies report for key sectors in Brazil prepared for decision-makers at COP 23 in Bonn.',
    10: 'Mariana Serra, cofounder and VV’s creator, is a lecturer with a degree in International Relations from ESPM-RJ. In 2009, she published an article about the importance of education for Brazil’s development for ESPM’s Newspaper, was an assistant to Brazil’s former Minister of Foreign Affairs Luis Felipe Lampreia and worked in the Institutional Relations sector at Andrade Gutierrez, a major Brazilian construction company. She is in love with travelling, having lived in New Zealand and Tahiti – where she began her involvement in humanitarian aid. She has recently been a volunteer in projects in Haiti, India, Thailand, Costa Rica, Tanzania, Kenya, Indonesia, USA, Brazil, Gana, Ethiopia, Mozambique, Nicaragua and in Refugees Camp in Lebanon and Jordan.  She is co-author of the book Jovens Empreendedores, líderes do Brasil que dá certo , published in Brazil, from the publishing company  Leader. Mariana was featured in FORBES’ 30 UNDER 30 as one of the most promising entrepreneurs under 30 in 2016.',
    11: 'André Fran is a director, writer, lecturer and co-founder of BASE#1 Filmes, a Brazilian film production company. He is one of the creators of the Não Conta lá em Casa travel show, which focuses on travel to the world’s most polemical destinations, taking him to Iran, Iraq, Afghanistan, North Korea, a UN mission in Haiti, a humanitarian expedition to post-tsunami Japan and various other adventures. The show has won several awards (Best Travel Show from NET and Best Reality TV Show from O Globo). Fran is also a co-author and director of Globo News’ Que Mundo É Esse? TV show. Fran has given lectures all over Brazil, in schools, universities, companies, a penitentiary and in 4 editions of the well-known TEDx. His first book, “A trip to the world’s most polemical destinations”, was released in 2013 by Record (a Brazilian publishing house) and he has also worked with Avianca, Vice, Viagem & Turismo, Boa Viagem, Claudia, Trip and Vizoo, among others.',
  },
};

function About(props) {
  const { match } = props;
  const locale = match.params.locale ? match.params.locale : 'pt_BR';
  return (
    <div style={{ marginTop: 60 }}>
      <div className="cover">
        <img alt="cover" className="img-fluid" src="/assets/cover-about.jpg" />
      </div>
      <Container style={{ paddingTop: 40 }}>
        <h1>{localization[locale][1]}</h1>
        {localization[locale][2]}
        <iframe
          title="vv"
          style={{
            margin: '0 auto',
            display: 'block',
            marginTop: 30,
            marginBottom: 30,
          }}
          src="https://www.youtube.com/embed/CSknKIBW7nQ"
          frameBorder="0"
          allowFullScreen
        />
        {localization[locale][3]}
        <br />
        <br />
        <Alert color="primary" style={{ backgroundColor: '#f5f5f5', fontSize: 'x-large' }}>
          {localization[locale][4]}
        </Alert>
        <h3>{localization[locale][5]}</h3>
        {localization[locale][6]}
        <br />
        <br />
        {localization[locale][7]}
        <br />
        <br />
        {localization[locale][8]}
        <br />
        <br />
        <Row>
          <Col>
            <Card className="profile-card zoom">
              <CardBlock>
                <div className="card-image-block">
                  <CardImg src="/assets/alice.jpg" alt="Alice" />
                </div>
                <CardTitle className="text-center">ALICE RATTON</CardTitle>
              </CardBlock>
              <CardText style={{ margin: 10, textAlign: 'justify' }}>
                {localization[locale][9]}
                <br /><br />
                <em><a href="mailto: alice@volunteervacations.com.br">alice@volunteervacations.com.br</a></em>
              </CardText>
            </Card>
          </Col>
          <Col>
            <Card className="profile-card zoom">
              <CardBlock>
                <div className="card-image-block">
                  <CardImg src="/assets/mariana.jpg" alt="Mariana" />
                </div>
                <CardTitle className="text-center">MARIANA SERRA</CardTitle>
              </CardBlock>
              <CardText style={{ margin: 10, textAlign: 'justify' }}>
                {localization[locale][10]}
                <br /><br />
                <em><a href="mailto: mariana@volunteervacations.com.br">mariana@volunteervacations.com.br</a></em>
                <a href="http://twitter.com/mari_around/" target="_blank" rel="noopener noreferrer" style={{ marginLeft: 10, marginRight: 10 }}><img src="/assets/twitter.png" alt="" width="30" height="30" /></a>
                <a href="http://www.instagram.com/marianaserraa/" target="_blank" rel="noopener noreferrer"><img src="/assets/instagram.png" alt="" width="30" height="30" /></a>
              </CardText>
            </Card>
          </Col>
          <Col>
            <Card className="profile-card zoom">
              <CardBlock>
                <div className="card-image-block">
                  <CardImg src="/assets/fran.jpg" alt="Fran" />
                </div>
                <CardTitle className="text-center">ANDRÉ FRAN</CardTitle>
              </CardBlock>
              <CardText style={{ margin: 10, textAlign: 'justify' }}>
                {localization[locale][11]}
                <br /><br />
                <em><a href="mailto: andrefran@volunteervacations.com.br">andrefran@volunteervacations.com.br</a></em>
                <a href="http://twitter.com/franontheroad/" target="_blank" rel="noopener noreferrer" style={{ marginLeft: 10, marginRight: 10 }}><img src="/assets/twitter.png" alt="" width="30" height="30" /></a>
                <a href="http://www.instagram.com/andrefran/" target="_blank" rel="noopener noreferrer"><img src="/assets/instagram.png" alt="" width="30" height="30" /></a>
              </CardText>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

About.propTypes = {
  match: PropTypes.shape({}),
};


export default About;
