import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button } from 'reactstrap';
import currencyFormatter from 'currency-formatter';
import moment from 'moment';

const localization = {
  pt_BR: {
    PENDING: 'Pendente',
    RECEIVED: 'Pago',
    RECEIVED_IN_CASH: 'Pago em Dinheiro',
  },
  en_US: {
    PENDING: 'Pending',
    RECEIVED: 'Paid',
    RECEIVED_IN_CASH: 'Paid in Cash',
  },
};

const colors = {
  PENDING: '#FF851B',
  RECEIVED: '#2ECC40',
  RECEIVED_IN_CASH: '#2ECC40',
};

function PaymentRow(props) {
  const { payment, match } = props;
  const locale = match.params.locale ? match.params.locale : 'pt_BR';
  return (
    <tr>
      <td>
        {payment.description}
      </td>
      <td>
        {`${currencyFormatter.format(payment.value, { code: 'BRL' })}`}
      </td>
      <td>
        {moment(payment.dueDate).format('DD/MM/YYYY')}
      </td>
      <td style={{ color: colors[payment.status] }}>
        <strong>
          {localization[locale][payment.status]}
        </strong>
      </td>
      <td className="text-right">
        {payment.status !== 'RECEIVED' &&
          <Button
            color="primary"
            href={payment.bankSlipUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            Visualizar Boleto
          </Button>
        }
      </td>
    </tr>
  );
}

PaymentRow.propTypes = {
  match: PropTypes.shape({}),
  payment: PropTypes.shape({}),
};

export default withRouter(connect()(PaymentRow));
