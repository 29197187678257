import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Alert, CardDeck, Col, Container, Row } from 'reactstrap';
import ReactLoading from 'react-loading';
import ReactHtmlParser from 'react-html-parser';
import { fetchCategory, resetCategoryPage } from './../actions';
import ProjectCard from './ProjectCard';

const localization = {
  pt_BR: {
    'Próximas Edições': 'Próximas Edições',
    'Edições Anteriores': 'Edições Anteriores',
  },
  en_US: {
    'Próximas Edições': 'Upcoming Editions',
    'Edições Anteriores': 'Previous Editions',
  },
};

class Category extends Component {
  componentWillMount() {
    const { match } = this.props;
    this.props.fetchCategory(match.params.slug);
  }
  componentWillUnmount() {
    this.props.resetCategoryPage();
  }
  render() {
    const {
      match,
      category,
      isLoading,
      error,
    } = this.props;
    const locale = match.params.locale ? match.params.locale : 'pt_BR';
    return (
      <div style={{ marginTop: 60 }}>
        {isLoading === true &&
          <ReactLoading delay={0} className="spin" type="spin" color="#ccc" height={24} width={24} />
        }
        {category && category.cover &&
          <div className="cover">
            <img width="100%" className="img-fluid" src={category.cover.largeFile.url} alt="cover" />
          </div>
        }
        <Container style={{ paddingTop: 40 }}>
          {error &&
            <Alert color="danger">
              {`'Oops! ${error.message}`}
            </Alert>
          }
          {!error && isLoading === false && category &&
            <div>
              <h1>{category[locale].name}</h1>
              <h3>{category[locale].title}</h3>
              <div className="linebreak">
                {ReactHtmlParser(category[locale].text)}
              </div>
              {category.projects && category.projects.length > 0 &&
                <div>
                  <hr />
                  <h3>{localization[locale]['Próximas Edições']}</h3>
                  <Row>
                    <Col>
                      <CardDeck>
                        {
                          category.projects.map(project => (
                            <ProjectCard key={project.objectId} project={project} />
                          ))
                        }
                      </CardDeck>
                    </Col>
                  </Row>
                </div>
              }
              {category.oldProjects && category.oldProjects.length > 0 &&
                <div>
                  <hr />
                  <h3>{localization[locale]['Edições Anteriores']}</h3>
                  <Row>
                    <Col>
                      <CardDeck>
                        {
                          category.oldProjects.map(project => (
                            <ProjectCard key={project.objectId} project={project} />
                          ))
                        }
                      </CardDeck>
                    </Col>
                  </Row>
                </div>
              }
            </div>
          }
        </Container>
      </div>
    );
  }
}

Category.propTypes = {
  match: PropTypes.shape({}),
  fetchCategory: PropTypes.func,
  resetCategoryPage: PropTypes.func,
  category: PropTypes.shape({}),
  isLoading: PropTypes.bool,
  error: PropTypes.shape({}),
};

function mapStateToProps({ categoryReducer }) {
  return categoryReducer;
}
function mapDispatchToProps(dispatch) {
  return {
    fetchCategory: (slug) => {
      dispatch(fetchCategory(slug));
    },
    resetCategoryPage: () => {
      dispatch(resetCategoryPage());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Category);
