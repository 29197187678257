import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Row, Col, FormFeedback, FormGroup, Label, Input } from 'reactstrap';
import Payment from 'payment';
import Cards from 'react-credit-cards';
import currencyFormatter from 'currency-formatter';
import { changeCheckoutField, getCardBrand } from './../actions';

const localization = {
  pt_BR: {
    0: 'Nome impresso no cartão',
    1: 'Número do cartão',
    2: 'Validade',
    3: 'Código de segurança',
    4: 'Parcelas',
    5: 'validade',
    6: 'SEU NOME',
    7: 'MM / AA',
    8: 'sem juros',
    9: 'com juros',
    10: 'Esse campo é obrigatório',
    11: '* Dados do titular do cartão',
  },
  en_US: {
    0: 'Name printed on card',
    1: 'Card number',
    2: 'Expiration',
    3: 'Security code',
    4: 'Installments',
    5: 'expires',
    6: 'YOUR NAME',
    7: 'MM / YY',
    8: 'no interest fee',
    9: 'with interest fee',
    10: 'This field is required',
    11: '* Card holder data',
  },
};

class CardForm extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.handleInputFocus = this.handleInputFocus.bind(this);
    this.handleCallback = this.handleCallback.bind(this);
    this.state = {
      focused: 'number',
    };
  }
  componentDidMount() {
    Payment.formatCardNumber(document.querySelector('[id="number"]'), 16);
    Payment.formatCardExpiry(document.querySelector('[id="expiry"]'), 4);
    Payment.formatCardCVC(document.querySelector('[id="cvc"]'));
  }
  onChange(e) {
    this.props.changeCheckoutField({ prop: e.target.id, value: e.target.value });
  }
  handleInputFocus(e) {
    this.setState({
      focused: e.target.id,
    });
  }
  handleCallback(type, isValid) {
    this.props.changeCheckoutField({ prop: 'isValid', value: isValid });
  }
  render() {
    const { focused } = this.state;
    const {
      match,
      name,
      number,
      expiry,
      cvc,
      installment,
      installments,
      brand,
      submit,
    } = this.props;
    const locale = match.params.locale ? match.params.locale : 'pt_BR';
    return (
      <div>
        <FormGroup style={{ marginTop: 10, marginBottom: 10 }}>
          <Cards
            name={name}
            number={number}
            expiry={`${expiry.slice(0, 2)}/${expiry.substr(-2)}`}
            cvc={cvc}
            focused={focused}
            locale={{ valid: localization[locale][5] }}
            placeholders={{ name: localization[locale][6] }}
            callback={this.handleCallback}
          />
        </FormGroup>
        <h5>{localization[locale][11]}</h5>
        <FormGroup color={submit === true && !name ? 'danger' : null}>
          <Label for="name">{localization[locale][0]}</Label>
          <Input id="name" type="text" value={name} state={submit === true && !name ? 'danger' : null} onChange={this.onChange} />
          {submit === true &&
            <FormFeedback hidden={name}>{localization[locale][10]}</FormFeedback>
          }
        </FormGroup>
        <FormGroup color={submit === true && !number ? 'danger' : null}>
          <Label for="number">{localization[locale][1]}</Label>
          <Input
            id="number"
            type="tel"
            state={submit === true && !number ? 'danger' : null}
            placeholder="**** **** **** ****"
            onFocus={this.handleInputFocus}
            value={number}
            onChange={(e) => {
              this.props.changeCheckoutField({ prop: 'number', value: e.target.value });
              this.props.getCardBrand(e.target.value, brand);
            }}
          />
          {submit === true &&
            <FormFeedback hidden={number}>{localization[locale][10]}</FormFeedback>
          }
        </FormGroup>
        <Row>
          <Col>
            <FormGroup color={submit === true && !expiry ? 'danger' : null}>
              <Label for="expiry">{localization[locale][2]}</Label>
              <Input
                id="expiry"
                type="tel"
                maxLength={7}
                state={submit === true && !expiry ? 'danger' : null}
                placeholder={localization[locale][7]}
                onChange={this.onChange}
                onFocus={this.handleInputFocus}
                value={expiry}
              />
              {submit === true &&
                <FormFeedback hidden={expiry}>{localization[locale][10]}</FormFeedback>
              }
            </FormGroup>
          </Col>
          <Col>
            <FormGroup color={submit === true && !cvc ? 'danger' : null}>
              <Label for="cvc">{localization[locale][3]}</Label>
              <Input
                id="cvc"
                type="tel"
                state={submit === true && !cvc ? 'danger' : null}
                placeholder="CVC"
                onChange={this.onChange}
                onFocus={this.handleInputFocus}
                value={cvc}
              />
              {submit === true &&
                <FormFeedback hidden={cvc}>{localization[locale][10]}</FormFeedback>
              }
            </FormGroup>
          </Col>
        </Row>
        <FormGroup color={submit === true && (!installment || installment === -1) ? 'danger' : null}>
          <Label for="installment">{localization[locale][4]}</Label>
          <Input
            disabled={!brand}
            id="installment"
            type="select"
            state={submit === true && (!installment || installment === -1) ? 'danger' : null}
            value={installment.quantity}
            onChange={(e) => {
              this.props.changeCheckoutField({
                prop: e.target.id,
                value: Number(e.target.value),
              });
            }}
          >
            <option value={-1} />
            {brand && installments && installments[brand] &&
              installments[brand].map(item => (
                <option key={item.quantity} value={item.quantity}>{`${item.quantity} x ${currencyFormatter.format(item.installmentAmount, { code: 'BRL' })} (${item.interestFree ? localization[locale][8] : localization[locale][9]}) - ${currencyFormatter.format(item.totalAmount, { code: 'BRL' })}`}</option>
              ))
            }
          </Input>
        </FormGroup>
      </div>
    );
  }
}

CardForm.propTypes = {
  match: PropTypes.shape({}),
  getCardBrand: PropTypes.func,
  changeCheckoutField: PropTypes.func,
  name: PropTypes.string,
  number: PropTypes.string,
  expiry: PropTypes.string,
  cvc: PropTypes.string,
  brand: PropTypes.string,
  installments: PropTypes.shape({}),
  installment: PropTypes.number,
  submit: PropTypes.bool,
};

function mapStateToProps({ orderReducer, checkoutReducer }) {
  return { ...orderReducer, ...checkoutReducer };
}
function mapDispatchToProps(dispatch) {
  return {
    getCardBrand: (number, brand) => {
      dispatch(getCardBrand(number, brand));
    },
    changeCheckoutField: (field) => {
      dispatch(changeCheckoutField(field));
    },
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CardForm));
