import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Form, FormGroup, FormFeedback, Label, Input, Button } from 'reactstrap';
import { resetLostPasswordForm, changeLostPasswordField, requestResetPassword } from './../actions';

class LostPasswordForm extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onClick = this.onClick.bind(this);
  }
  componentWillUnmount() {
    this.props.resetLostPasswordForm();
  }
  onChange(e) {
    this.props.changeLostPasswordField({ prop: e.target.id, value: e.target.value });
  }
  onClick(e) {
    e.preventDefault();
    window.scrollTo(0, 0);
    const { email } = this.props;
    this.props.requestResetPassword(email);
  }
  render() {
    const { email, submit, isLoading } = this.props;
    return (
      <Form style={{ maxWidth: 400 }}>
        <FormGroup color={(submit === true && !email) ? 'danger' : null}>
          <Label for="email">E-mail</Label>
          <Input type="email" id="email" value={email} onChange={this.onChange} />
          {submit === true &&
            <FormFeedback hidden={email}>Esse campo é obrigatório</FormFeedback>
          }
        </FormGroup>
        <Button disabled={isLoading} color="primary" onClick={this.onClick}>Redefinir Senha</Button>
      </Form>
    );
  }
}

LostPasswordForm.propTypes = {
  changeLostPasswordField: PropTypes.func,
  resetLostPasswordForm: PropTypes.func,
  requestResetPassword: PropTypes.func,
  email: PropTypes.string,
  submit: PropTypes.bool,
  isLoading: PropTypes.bool,
};

function mapStateToProps({ lostPasswordReducer }) {
  return lostPasswordReducer;
}
function mapDispatchToProps(dispatch) {
  return {
    changeLostPasswordField: (field) => {
      dispatch(changeLostPasswordField(field));
    },
    resetLostPasswordForm: () => {
      dispatch(resetLostPasswordForm());
    },
    requestResetPassword: (email) => {
      dispatch(requestResetPassword(email));
    },
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LostPasswordForm));
