import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Col, Form, FormGroup, FormFeedback, Label, Input, Row } from 'reactstrap';
import NumberFormat from 'react-number-format';
import DatePicker from 'react-datepicker';
import currencyFormatter from 'currency-formatter';
import { changeOrderField, resetOrderForm, saveOrder } from './../actions';

const localization = {
  pt_BR: {
    1: 'Período',
    2: 'Esse campo é obrigatório',
    3: 'Aceito o termo do contrato -',
    4: 'Vizualizar o contrato',
    5: 'Você precisa aceitar os termos do contrato',
    6: 'Enviar',
    7: 'dia(s)',
    8: 'CPF',
    9: 'RG',
    10: 'Data de Nascimento',
    11: 'Nacionalidade',
    12: 'Brasileira',
    13: 'Outra',
    14: 'Data de Preferência',
  },
  en_US: {
    1: 'Period',
    2: 'This field is required',
    3: 'I accept the agreement terms -',
    4: 'View the agreement',
    5: 'You must accept the agreement terms',
    6: 'Submit',
    7: 'day(s)',
    8: 'CPF (Brazilians Citizens Only)',
    9: 'ID Number',
    10: 'Birth Date',
    11: 'Nationality',
    12: 'Brazilian',
    13: 'Other',
    14: 'Date of Preference',
  },
};

class RequestForm extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onClick = this.onClick.bind(this);
    this.state = {
      nationality: 'brazilian',
    };
  }
  componentWillMount() {
    this.props.resetOrderForm();
  }
  onChange(e) {
    let { value } = e.target;
    if (e.target.id === 'term') {
      value = e.target.checked ? e.target.checked : false;
    } else if (e.target.id === 'amount') {
      value = Number(e.target.value);
    }
    this.props.changeOrderField({ prop: e.target.id, value });
  }
  onClick(e) {
    e.preventDefault();
    window.scrollTo(0, 0);
    const {
      project,
      amount,
      date,
      term,
      cpf,
      identity,
      birthDate,
      nationality,
    } = this.props;
    this.props.saveOrder(
      project,
      amount,
      date,
      Object.keys(this.props)
        .filter(key => key.includes('question_'))
        .reduce((obj, key) => {
          const o = obj;
          o[key.replace('question_', '')] = this.props[key];
          return o;
        }, {}),
      cpf,
      identity,
      birthDate,
      nationality,
      term,
    );
  }
  render() {
    const {
      match,
      user,
      project,
      cpf,
      identity,
      birthDate,
      nationality,
      amount,
      date,
      term,
      submit,
      isLoading,
    } = this.props;
    const locale = match.params.locale ? match.params.locale : 'pt_BR';
    const symbol = project.currency === 'USD' ? { symbol: 'US$ ' } : null;
    return (
      <Form style={{ maxWidth: 400 }}>
        <Row>
          <Col style={{ marginRight: 5 }}>
            <FormGroup color={submit === true && nationality === 'brasileira' && !cpf ? 'danger' : null}>
              <Label for="cpf">{localization[locale][8]}</Label>
              <NumberFormat
                disabled={user.cpf}
                id="identity"
                className="form-control"
                value={cpf}
                format="###.###.###-##"
                thousandSeparator="."
                decimalSeparator=","
                fixedDecimalScale
                decimalScale={0}
                allowNegative={false}
                onValueChange={(values) => {
                  const { value } = values;
                  this.props.changeOrderField({ prop: 'cpf', value });
                }}
              />
              {submit === true &&
                <FormFeedback hidden={nationality !== 'brasileira' || cpf}>{localization[locale][2]}</FormFeedback>
              }
            </FormGroup>
          </Col>
          <Col style={{ marginRight: 5 }}>
            <FormGroup color={submit === true && !identity ? 'danger' : null}>
              <Label for="identity">{localization[locale][9]}</Label>
              <Input id="identity" type="text" value={identity} onChange={this.onChange} />
              {submit === true &&
                <FormFeedback hidden={identity}>{localization[locale][2]}</FormFeedback>
              }
            </FormGroup>
          </Col>
        </Row>
        <FormGroup color={submit === true && !birthDate ? 'danger' : null}>
          <Label for="birthDate">{localization[locale][10]}</Label>
          <DatePicker
            disabled={user.birthDate}
            id="birthDate"
            className="form-control"
            selected={birthDate}
            dateFormat="DD/MM/YYYY"
            onChange={(d) => {
              this.props.changeOrderField({ prop: 'birthDate', value: d });
            }}
          />
          {submit === true &&
            <FormFeedback hidden={birthDate}>{localization[locale][2]}</FormFeedback>
          }
        </FormGroup>
        <FormGroup color={submit === true && !nationality ? 'danger' : null}>
          <Label for="nationality">{localization[locale][11]}</Label>
          <FormGroup check>
            <Row>
              <Col>
                <Label check>
                  <Input
                    disabled={user.nationality}
                    type="radio"
                    name="nationality_type"
                    checked={this.state.nationality === 'brazilian' && nationality === 'brasileira'}
                    onChange={() => {
                      this.props.changeOrderField({ prop: 'nationality', value: 'brasileira' });
                      this.setState({
                        nationality: 'brazilian',
                      });
                    }}
                  />{' '}
                  {localization[locale][12]}
                </Label>
              </Col>
            </Row>
            <Row>
              <Col style={{ marginLeft: 15 }} xs="auto">
                <Label check>
                  <Input
                    disabled={user.nationality}
                    type="radio"
                    name="nationality_type"
                    checked={this.state.nationality === 'other' || user.nationality !== 'brasileira'}
                    onChange={() => {
                      this.props.changeOrderField({ prop: 'nationality', value: '' });
                      this.setState({
                        nationality: 'other',
                      });
                    }}
                  />{' '}
                  {localization[locale][13]}
                </Label>
              </Col>
              <Col>
                {(this.state.nationality === 'other' || user.nationality !== 'brasileira') &&
                  <Input disabled={user.nationality} style={{ marginTop: 5 }} id="nationality" type="nationality" value={nationality} onChange={this.onChange} />
                }
              </Col>
            </Row>
          </FormGroup>
        </FormGroup>
        <Row>
          <Col>
            <FormGroup color={submit === true && amount === -1 ? 'danger' : null}>
              <Label for="amount">{localization[locale][1]}</Label>
              <Input id="amount" type="select" value={amount} state={submit === true && amount === -1 ? 'danger' : null} onChange={this.onChange} >
                <option value={-1} />
                {project.amounts &&
                  project.amounts.map((amount, index) => (
                    <option key={amount+index} value={index}>{`${amount.days} ${localization[locale][7]} - ${currencyFormatter.format(amount.amount / 100, { code: project.currency, ...symbol })}${amount.details ? ` - ${amount.details[locale]}` : ''}`}</option>
                  ))
                }
              </Input>
              {submit === true &&
                <FormFeedback hidden={amount !== -1}>{localization[locale][2]}</FormFeedback>
              }
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            {!project.startDate &&
              <FormGroup color={submit === true && !date ? 'danger' : null}>
                <Label for="date">{localization[locale][14]}</Label>
                <DatePicker
                  id="date"
                  className="form-control"
                  selected={date}
                  dateFormat="DD/MM/YYYY"
                  onChange={(d) => {
                    this.props.changeOrderField({ prop: 'date', value: d });
                  }}
                />
                {submit === true &&
                  <FormFeedback hidden={date}>{localization[locale][2]}</FormFeedback>
                }
              </FormGroup>
            }
          </Col>
        </Row>
        {
          project.questions.map(question => (
            <FormGroup key={question.objectId}>
              <Label for={question.objectId}>{question.question[locale].text}</Label>
              <Input
                id={`question_${question.objectId}`}
                type={question.question.type.nameNoDiacritics}
                value={this.props[`question_${question.objectId}`]}
                onChange={this.onChange}
              />
            </FormGroup>
          ))
        }
        {nationality === 'brasileira' && project.agreement && project.agreement.file &&
          <FormGroup check color={submit === true && term === false ? 'danger' : null}>
            <Label check for="term">
              <Input id="term" type="checkbox" value={term} onChange={this.onChange} />{' '}
              {localization[locale][3]}
            </Label>
            <a target="_blank" rel="noopener noreferrer" href={project.agreement.file.url} to={project.agreement.file.url}>
              {localization[locale][4]}
            </a>
            {submit === true &&
              <FormFeedback hidden={term}>{localization[locale][5]}</FormFeedback>
            }
          </FormGroup>
        }
        <Button disabled={isLoading} color="primary" onClick={this.onClick}>
          <img style={{ marginTop: -4, marginRight: 5 }} height={20} src="/assets/logo-white.png" alt="logo" />
          {localization[locale][6]}
        </Button>
      </Form>
    );
  }
}

RequestForm.propTypes = {
  match: PropTypes.shape({}),
  changeOrderField: PropTypes.func,
  resetOrderForm: PropTypes.func,
  saveOrder: PropTypes.func,
  project: PropTypes.shape({}),
  user: PropTypes.shape({}),
  amount: PropTypes.number,
  date: PropTypes.shape({}),
  term: PropTypes.bool,
  cpf: PropTypes.string,
  identity: PropTypes.string,
  nationality: PropTypes.string,
  birthDate: PropTypes.shape({}),
  submit: PropTypes.bool,
  isLoading: PropTypes.bool,
};

function mapStateToProps({ userReducer, orderReducer }) {
  return { ...userReducer, ...orderReducer };
}
function mapDispatchToProps(dispatch) {
  return {
    changeOrderField: (field) => {
      dispatch(changeOrderField(field));
    },
    resetOrderForm: () => {
      dispatch(resetOrderForm());
    },
    saveOrder: (project, amount, date, answers, cpf, identity, birthDate, nationality, term) => {
      dispatch(saveOrder(
        project,
        amount,
        date,
        answers,
        cpf,
        identity,
        birthDate,
        nationality,
        term,
      ));
    },
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RequestForm));
