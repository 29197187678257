import { Parse } from 'parse';
import {
  FETCH_CATEGORIES,
  FETCH_CATEGORIES_SUCCESS,
  FETCH_CATEGORIES_FAILURE,
  FETCH_CATEGORY,
  FETCH_CATEGORY_SUCCESS,
  FETCH_CATEGORY_FAILURE,
  RESET_CATEGORY_PAGE,
} from './types';


export function fetchCategoriesSuccess(categories) {
  return {
    type: FETCH_CATEGORIES_SUCCESS,
    payload: { categories },
  };
}

export function fetchCategoriesFailure(error) {
  return {
    type: FETCH_CATEGORIES_FAILURE,
    payload: { error },
  };
}

export function fetchCategories(locale) {
  return ((dispatch) => {
    dispatch({ type: FETCH_CATEGORIES });
    return Parse.Cloud.run('fetchCategories', { locale }).then((results) => {
      dispatch(fetchCategoriesSuccess(JSON.parse(JSON.stringify(results))));
    }, (error) => {
      dispatch(fetchCategoriesFailure(JSON.parse(JSON.stringify(error))));
    });
  });
}


export function fetchCategorySuccess(category) {
  return {
    type: FETCH_CATEGORY_SUCCESS,
    payload: { category },
  };
}

export function fetchCategoryFailure(error) {
  return {
    type: FETCH_CATEGORY_FAILURE,
    payload: { error },
  };
}

export function fetchCategory(slug) {
  return ((dispatch) => {
    dispatch({ type: FETCH_CATEGORY });
    const sessionToken = localStorage.user ? JSON.parse(localStorage.user).sessionToken : null;
    return Parse.Cloud.run('fetchCategory', { slug }, { sessionToken }).then((results) => {
      dispatch(fetchCategorySuccess(JSON.parse(JSON.stringify(results))));
    }, (error) => {
      dispatch(fetchCategoryFailure(JSON.parse(JSON.stringify(error))));
    });
  });
}

export function resetCategoryPage() {
  return {
    type: RESET_CATEGORY_PAGE,
  };
}
