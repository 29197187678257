import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Alert, Button, Col, Collapse, Form, FormFeedback, FormGroup, Label, Input, Row } from 'reactstrap';
import DatePicker from 'react-datepicker';
import MaskedTextInput from 'react-text-mask';
import NumberFormat from 'react-number-format';
import currencyFormatter from 'currency-formatter';
import PayPalButton from './PayPalButton';
import { applyCoupon, checkoutPayPal, checkout, checkoutFailure, changeCheckoutField, resetOrderPage, postalCodeAddress } from './../actions';
import CardForm from './CardForm';

const localization = {
  pt_BR: {
    'Forma de Pagamento': 'Forma de Pagamento',
    'Dados do Titular do Cartão': 'Dados do Titular do Cartão',
    'Nome Completo': 'Nome Completo',
    Sobrenome: 'Sobrenome',
    'Esse campo é obrigatório': 'Esse campo é obrigatório',
    'E-mail': 'E-mail',
    Telefone: 'Telefone',
    CPF: 'CPF',
    'Data de Nascimento': 'Data de Nascimento',
    Endereço: 'Endereço',
    Número: 'Número',
    Complemento: 'Complemento',
    Bairro: 'Bairro',
    Cidade: 'Cidade',
    Estado: 'Estado',
    País: 'País',
    Cep: 'Cep',
    'Finalizar Compra': 'Finalizar Compra',
    'Pagamento processado pelo': 'Pagamento processado pelo',
    1: 'de desconto à vista',
    2: 'Parcelas',
    6: 'Forma de Pagamento',
    7: 'Cartão de Crédito',
    8: 'Débito Online',
    9: 'Aplicar Cupom',
  },
  en_US: {
    'Forma de Pagamento': 'Payment Method',
    'Dados do Titular do Cartão': 'Cardholder Information',
    'Nome Completo': 'Full Name',
    'Esse campo é obrigatório': 'This field is required',
    'E-mail': 'E-mail',
    Telefone: 'Phone',
    Mensagem: 'Message',
    CPF: 'CPF',
    'Data de Nascimento': 'Birth Date',
    Endereço: 'Address',
    Número: 'Number',
    Complemento: 'Complement',
    Bairro: 'District',
    Cidade: 'City',
    Estado: 'State',
    País: 'Country',
    Cep: 'Postal Code',
    'Finalizar Compra': 'Checkout',
    'Pagamento processado pelo': 'Payment processed by',
    1: 'off cash discount',
    2: 'Installments',
    6: 'Payment Method',
    7: 'Credit Card',
    8: 'Online Debit',
    9: 'Apply Coupon',
  },
};

class CheckoutForm extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onChangeDate = this.onChangeDate.bind(this);
    this.onClick = this.onClick.bind(this);
  }
  componentWillUnmount() {
    this.props.resetOrderPage();
  }
  onChange(e) {
    this.props.changeCheckoutField({ prop: e.target.id, value: e.target.value });
  }
  onChangeDate(date) {
    this.props.changeCheckoutField({ prop: 'birthDate', value: date });
  }
  onClick(e) {
    e.preventDefault();
    window.scrollTo(0, 0);
    const {
      order,
      name,
      phoneNumber,
      cpf,
      birthDate,
      street,
      addressNumber,
      complement,
      district,
      city,
      state,
      postalCode,
      paymentMethod,
      number,
      expiry,
      cvc,
      brand,
      isValid,
      installment,
      coupon,
    } = this.props;
    this.props.checkout({
      order,
      name,
      phoneNumber,
      cpf,
      birthDate,
      street,
      addressNumber,
      complement,
      district,
      city,
      state,
      postalCode,
      paymentMethod,
      card: {
        number,
        expiry,
        cvc,
        brand,
        isValid,
      },
      installment,
      coupon,
    });
  }
  renderCouponForm() {
    const {
      couponCode,
      couponError,
      isLoading,
      match,
      order,
    } = this.props;
    const { objectId } = order;
    const locale = match.params.locale ? match.params.locale : 'pt_BR';
    return (
      <Row>
        <Col>
          {couponError &&
            <Alert color="danger">
              {`Oops! ${couponError.message}`}
            </Alert>
          }
          <Row>
            <Input
              id="couponCode"
              type="text"
              value={couponCode}
              placeholder="Código"
              style={{ marginLeft: 15, textTransform: 'uppercase', maxWidth: 150 }}
              onChange={this.onChange}
            />
            <Button
              outline
              color="primary"
              style={{ marginLeft: 10 }}
              disabled={isLoading}
              onClick={(e) => {
                e.preventDefault();
                this.props.applyCoupon({ couponCode, objectId });
              }}
            >
              {localization[locale][9]}
            </Button>
          </Row>
        </Col>
      </Row>
    );
  }
  render() {
    const {
      match,
      user,
      paymentMethod,
      order,
      installment,
      phoneNumber,
      cpf,
      birthDate,
      street,
      addressNumber,
      complement,
      district,
      city,
      state,
      postalCode,
      boletoInstallments,
      submit,
      isLoading,
    } = this.props;
    const locale = match.params.locale ? match.params.locale : 'pt_BR';
    let marginBottom = 0;
    if ($(window).width() < 768) {
      marginBottom = 30;
    }
    const client = {
      sandbox: process.env.PAYPAL_CLIENT_ID,
      production: process.env.PAYPAL_CLIENT_ID,
    };
    return (
      <div>
        {order && boletoInstallments &&
          <Form style={{ maxWidth: 400, marginBottom, marginTop: 10 }}>
            <FormGroup>
              {this.renderCouponForm()}
            </FormGroup>
            <hr />
            <h3>{localization[locale]['Forma de Pagamento']}</h3>
            {user.nationality !== 'brasileira' &&
              <PayPalButton
                order={order}
                disabled={isLoading}
                env={process.env.PAYPAL_ENV}
                client={client}
                currency="BRL"
                locale={locale}
                shipping={1}
                style={{ size: 'medium', shape: 'rect', zIndex: 0 }}
                total={order.paymentAmount / 100}
                onError={(err) => {
                  this.props.checkoutFailure(err);
                  console.log('Error!', err);
                }}
                onSuccess={(payment) => {
                  this.props.checkoutPayPal(order, payment);
                  console.log('The payment was succeeded!', payment);
                }}
                onCancel={(data) => {
                  console.log('The payment was cancelled!', data);
                }}
              />
            }
            {user.nationality === 'brasileira' &&
              <FormGroup tag="paymentMethod">
                <FormGroup check>
                  <Row>
                    <Col>
                      <Label check>
                        <Input
                          type="radio"
                          id="paymentMethod"
                          checked={paymentMethod === 'creditCard'}
                          onChange={(e) => {
                            this.props.changeCheckoutField({ prop: e.target.id, value: 'creditCard' });
                          }}
                        />{' '}
                        {localization[locale][7]}
                      </Label>
                    </Col>
                    <Col>
                      <Label check>
                        <Input
                          type="radio"
                          id="paymentMethod"
                          checked={paymentMethod === 'boleto'}
                          onChange={(e) => {
                            this.props.changeCheckoutField({ prop: e.target.id, value: 'boleto' });
                          }}
                        />{' '}
                        {`Boleto (5% ${localization[locale][1]})`}
                      </Label>
                    </Col>
                  </Row>
                  <hr />
                  <Collapse isOpen={paymentMethod === 'boleto'}>
                    <FormGroup color={submit === true && (!installment || installment === -1) ? 'danger' : null}>
                      <Label for="installment">{localization[locale][2]}</Label>
                      <Input
                        id="installment"
                        type="select"
                        state={submit === true && (!installment || installment === -1) ? 'danger' : null}
                        value={installment.quantity}
                        onChange={(e) => {
                          this.props.changeCheckoutField({
                            prop: e.target.id,
                            value: Number(e.target.value),
                          });
                        }}
                      >
                        <option value={-1} />
                        {
                          boletoInstallments.map(item => (
                            <option
                              key={item.quantity}
                              value={item.quantity}
                            >
                              {`${item.quantity} x ${currencyFormatter.format(item.installmentAmount, { code: 'BRL' })} (sem juros)`}
                            </option>
                          ))
                        }
                      </Input>
                    </FormGroup>
                  </Collapse>
                  <Collapse isOpen={paymentMethod === 'creditCard'}>
                    <CardForm />
                    <Row>
                      <Col>
                        <FormGroup color={submit === true && !cpf ? 'danger' : null}>
                          <Label for="CPF">{localization[locale].CPF}</Label>
                          <NumberFormat
                            id="identity"
                            className="form-control"
                            value={cpf}
                            format="###.###.###-##"
                            thousandSeparator="."
                            decimalSeparator=","
                            fixedDecimalScale
                            decimalScale={0}
                            allowNegative={false}
                            onValueChange={(values) => {
                              const { value } = values;
                              this.props.changeCheckoutField({ prop: 'cpf', value });
                            }}
                          />
                          {submit === true &&
                            <FormFeedback hidden={cpf}>{localization[locale]['Esse campo é obrigatório']}</FormFeedback>
                          }
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup color={submit === true && !phoneNumber ? 'danger' : null}>
                          <Label for="number">{localization[locale].Telefone}</Label>
                          <MaskedTextInput
                            id="phoneNumber"
                            mask={['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                            className="form-control"
                            guide
                            value={phoneNumber}
                            onChange={this.onChange}
                          />
                          {submit === true &&
                            <FormFeedback hidden={phoneNumber}>{localization[locale]['Esse campo é obrigatório']}</FormFeedback>
                          }
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label for="birthDate">{localization[locale]['Data de Nascimento']}</Label>
                          <DatePicker
                            id="birthDate"
                            className="form-control"
                            selected={birthDate}
                            dateFormat="DD/MM/YYYY"
                            onChange={this.onChangeDate}
                          />
                        </FormGroup>
                      </Col>
                      <Col />
                    </Row>
                    <hr />
                    <h3>Endereço de Cobrança</h3>
                    <Row>
                      <Col>
                        <FormGroup color={submit === true && !postalCode ? 'danger' : null}>
                          <Label for="postalCode">{localization[locale].Cep}</Label>
                          <MaskedTextInput
                            id="postalCode"
                            mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
                            className="form-control"
                            guide={false}
                            value={postalCode}
                            onChange={(e) => {
                              this.props.changeCheckoutField({
                                prop: e.target.id,
                                value: e.target.value,
                              });
                              this.props.postalCodeAddress(e.target.value);
                            }}
                          />
                          {submit === true &&
                            <FormFeedback hidden={postalCode}>{localization[locale]['Esse campo é obrigatório']}</FormFeedback>
                          }
                        </FormGroup>
                      </Col>
                      <Col />
                    </Row>
                    <FormGroup color={submit === true && !street ? 'danger' : null}>
                      <Label for="street">{localization[locale].Endereço}</Label>
                      <Input id="street" type="text" value={street} state={submit === true && !street ? 'danger' : null} onChange={this.onChange} />
                      {submit === true &&
                        <FormFeedback hidden={street}>{localization[locale]['Esse campo é obrigatório']}</FormFeedback>
                      }
                    </FormGroup>
                    <Row>
                      <Col>
                        <FormGroup color={submit === true && !addressNumber ? 'danger' : null}>
                          <Label for="addressNumber">{localization[locale].Número}</Label>
                          <Input id="addressNumber" type="text" value={addressNumber} state={submit === true && !addressNumber ? 'danger' : null} onChange={this.onChange} />
                          {submit === true &&
                            <FormFeedback hidden={addressNumber}>{localization[locale]['Esse campo é obrigatório']}</FormFeedback>
                          }
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label for="complement">{localization[locale].Complemento}</Label>
                          <Input id="complement" type="text" value={complement} onChange={this.onChange} />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup color={submit === true && !district ? 'danger' : null}>
                          <Label for="district">{localization[locale].Bairro}</Label>
                          <Input id="district" type="text" value={district} state={submit === true && !district ? 'danger' : null} onChange={this.onChange} />
                          {submit === true &&
                            <FormFeedback hidden={district}>{localization[locale]['Esse campo é obrigatório']}</FormFeedback>
                          }
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup color={submit === true && !city ? 'danger' : null}>
                          <Label for="city">{localization[locale].Cidade}</Label>
                          <Input id="city" type="text" value={city} state={submit === true && !city ? 'danger' : null} onChange={this.onChange} />
                          {submit === true &&
                            <FormFeedback hidden={city}>{localization[locale]['Esse campo é obrigatório']}</FormFeedback>
                          }
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup color={submit === true && !state ? 'danger' : null}>
                          <Label for="state">{localization[locale].Estado}</Label>
                          <Input id="state" type="text" value={state} state={submit === true && !state ? 'danger' : null} onChange={this.onChange} />
                          {submit === true &&
                            <FormFeedback hidden={postalCode}>{localization[locale]['Esse campo é obrigatório']}</FormFeedback>
                          }
                        </FormGroup>
                      </Col>
                    </Row>
                  </Collapse>
                </FormGroup>
                <Button disabled={isLoading} color="primary" onClick={this.onClick}>{localization[locale]['Finalizar Compra']}</Button>
                {paymentMethod === 'creditCard' &&
                  <div>
                    <hr />
                    <Row>
                      <Col>
                        {localization[locale]['Pagamento processado pelo']}
                        <img className="img-fluid" src="/assets/pagseguro.png" alt="pagseguro" style={{ height: 40 }} />
                      </Col>
                    </Row>
                  </div>
                }
              </FormGroup>
            }
          </Form>
        }
      </div>
    );
  }
}

CheckoutForm.propTypes = {
  coupon: PropTypes.shape({}),
  match: PropTypes.shape({}),
  applyCoupon: PropTypes.func,
  couponCode: PropTypes.string,
  couponError: PropTypes.shape({}),
  changeCheckoutField: PropTypes.func,
  postalCodeAddress: PropTypes.func,
  resetOrderPage: PropTypes.func,
  checkoutPayPal: PropTypes.func,
  checkout: PropTypes.func,
  checkoutFailure: PropTypes.func,
  user: PropTypes.shape({}),
  order: PropTypes.shape({}),
  paymentMethod: PropTypes.string,
  number: PropTypes.string,
  expiry: PropTypes.string,
  cvc: PropTypes.string,
  brand: PropTypes.string,
  isValid: PropTypes.bool,
  installment: PropTypes.number,
  name: PropTypes.string,
  cpf: PropTypes.string,
  phoneNumber: PropTypes.string,
  birthDate: PropTypes.shape({}),
  street: PropTypes.string,
  addressNumber: PropTypes.string,
  complement: PropTypes.string,
  district: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  postalCode: PropTypes.string,
  boletoInstallments: PropTypes.arrayOf(PropTypes.shape({})),
  submit: PropTypes.bool,
  isLoading: PropTypes.bool,
};

function mapStateToProps({ userReducer, orderReducer, checkoutReducer }) {
  return { ...userReducer, ...orderReducer, ...checkoutReducer };
}
function mapDispatchToProps(dispatch) {
  return {
    applyCoupon: (params) => {
      dispatch(applyCoupon(params));
    },
    changeCheckoutField: ({ prop, value }) => {
      dispatch(changeCheckoutField({ prop, value }));
    },
    postalCodeAddress: (postalCode) => {
      dispatch(postalCodeAddress(postalCode));
    },
    resetOrderPage: () => {
      dispatch(resetOrderPage());
    },
    checkoutPayPal: (order, payment) => {
      dispatch(checkoutPayPal(order, payment));
    },
    checkoutFailure: (error) => {
      dispatch(checkoutFailure(error));
    },
    checkout: (params) => {
      dispatch(checkout(params));
    },
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CheckoutForm));
