import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import ReactHtmlParser from 'react-html-parser';
import moment from 'moment';

function PostRow(props) {
  const { post } = props;
  return (
    <Link className="post-row" href={`/blog/${post.slug}`} to={`/blog/${post.slug}`}>
      <h3>{post.name}</h3>
      <small><i>{moment(post.date.iso).format('DD/MM/YYYY H:mm')}</i><br /></small>
      <Row style={{ marginTop: 10 }}>
        <Col xs="auto" style={{ marginLeft: 15 }}>
          {post.cover &&
            <div className="image">
              <img src={post.cover.mediumFile.url} alt={post.name} />
            </div>
          }
        </Col>
        <Col>
          <div className="linebreak">
            {ReactHtmlParser(`${post.text.substring(0, 256)} - Ler Mais...`)}
          </div>
        </Col>
      </Row>
      <hr />
    </Link>
  );
}

PostRow.propTypes = {
  post: PropTypes.shape({}),
};

export default PostRow;
