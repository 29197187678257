import React from 'react';
import PropTypes from 'prop-types';
import { Col, Card, CardImg } from 'reactstrap';

function PartnerCard(props) {
  const { partner } = props;
  return (
    <Col xs="12" sm="6" md="4" lg="3" xl="3" className="center-block" style={{ marginBottom: 30 }}>
      <a href={partner.link} to={partner.link} target="_blank" rel="noopener noreferrer">
        <Card className="zoom">
          <CardImg className="img-fluid" src={partner.cover.mediumFile.url} alt={partner.name} />
        </Card>
      </a>
    </Col>
  );
}

PartnerCard.propTypes = {
  partner: PropTypes.shape({}),
};

export default PartnerCard;
