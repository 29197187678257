import {
  FETCH_PROJECTS,
  FETCH_PROJECTS_SUCCESS,
  FETCH_PROJECTS_FAILURE,
  FETCH_OLD_PROJECTS,
  FETCH_OLD_PROJECTS_SUCCESS,
  FETCH_OLD_PROJECTS_FAILURE,
  RESET_PROJECTS_PAGE,
} from '../actions/types';

const INITIAL_STATE = {
  projects: [],
  oldProjects: [],
  skip: 0,
  lastDate: new Date(),
  lastPage: false,
  error: null,
  isLoading: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_PROJECTS:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case FETCH_PROJECTS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        projects: state.projects.concat(action.payload.projects),
        isLoading: false,
      };
    case FETCH_PROJECTS_FAILURE:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      };
    case FETCH_OLD_PROJECTS:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case FETCH_OLD_PROJECTS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      };
    case FETCH_OLD_PROJECTS_FAILURE:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      };
    case RESET_PROJECTS_PAGE:
      return {
        ...INITIAL_STATE,
        lastDate: new Date(),
      };
    default:
      return state;
  }
};
