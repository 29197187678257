import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';

const localization = {
  pt_BR: {
    1: 'Dúvidas',
    2: '/como-funciona',
    3: 'Como Funciona',
    4: '/faq',
    5: 'FAQ',
  },
  en_US: {
    1: 'Help',
    2: '/en_US/how-it-works',
    3: 'How It Works',
    4: '/faq',
    5: 'FAQ',
  },
};

function Help(props) {
  const { match } = props;
  const locale = match.params.locale ? match.params.locale : 'pt_BR';
  return (
    <div style={{ marginTop: 60 }}>
      <div>
        <img width="100%" className="img-fluid" src="/assets/cover.png" alt="cover" />
      </div>
      <Container style={{ paddingTop: 40 }}>
        <h1>{localization[locale][1]}</h1>
        <Row>
          <Col>
            <Link
              href={localization[locale][2]}
              to={localization[locale][2]}
            >
              {localization[locale][3]}
            </Link>
          </Col>
        </Row>
        <Row>
          <Col>
            <Link
              href={localization[locale][4]}
              to={localization[locale][4]}
            >
              {localization[locale][5]}
            </Link>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

Help.propTypes = {
  match: PropTypes.shape({}),
};

export default Help;
