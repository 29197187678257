import {
  FETCH_PRESS,
  FETCH_PRESS_SUCCESS,
  FETCH_PRESS_FAILURE,
} from '../actions/types';

const INITIAL_STATE = {
  press: [],
  skip: 0,
  lastDate: new Date(),
  lastPage: false,
  error: null,
  isLoading: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_PRESS:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case FETCH_PRESS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        press: state.press ?
          state.press.concat(action.payload.press) : action.payload.press,
        error: null,
        isLoading: false,
      };
    case FETCH_PRESS_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};
