import React from 'react';
import PropTypes from 'prop-types';
import { Container, Col, Row } from 'reactstrap';

const localization = {
  '': {
    'Como Funciona': 'Como Funciona',
  },
  en_US: {
    'Como Funciona': 'How It Works',
  },
};

function HowItWorks(props) {
  const { match } = props;
  const locale = match.params.locale ? match.params.locale : '';
  return (
    <div style={{ marginTop: 60 }}>
      <div>
        <img width="100%" className="img-fluid" src="/assets/cover.png" alt="cover" />
      </div>
      <Container style={{ paddingTop: 40 }}>
        <h1>{localization[locale]['Como Funciona']}</h1>
        <Row>
          <Col>
            <img className="img-fluid" src="/assets/comofunciona.png" alt="como-funciona" />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

HowItWorks.propTypes = {
  match: PropTypes.shape({}),
};

export default HowItWorks;
