import {
  SHOW_SIGN_UP_MODAL,
  HIDE_SIGN_UP_MODAL,
  CHANGE_SIGN_UP_FIELD,
  RESET_SIGN_UP_MODAL,
  RESET_USER,
} from './types';

export function showSignUpModal() {
  return {
    type: SHOW_SIGN_UP_MODAL,
  };
}

export function hideSignUpModal() {
  return ((dispatch) => {
    dispatch({ type: HIDE_SIGN_UP_MODAL });
    dispatch({ type: RESET_SIGN_UP_MODAL });
    dispatch({ type: RESET_USER });
  });
}

export function changeSignUpField({ prop, value }) {
  return {
    type: CHANGE_SIGN_UP_FIELD,
    payload: { prop, value },
  };
}

export function resetSignUpModal() {
  return {
    type: RESET_SIGN_UP_MODAL,
  };
}
