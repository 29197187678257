import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Button, Container, Row, Col, Nav, NavItem, NavLink } from 'reactstrap';
import MediaQuery from 'react-responsive';

const localization = {
  pt_BR: {
    Contato: 'Contato',
    'Formas de Pagamento': 'Formas de Pagamento',
    Dúvidas: 'Dúvidas',
    'Como Funciona': 'Como Funciona',
    FAQ: 'FAQ',
  },
  en_US: {
    Contato: 'Contact',
    'Formas de Pagamento': 'Payment Methods',
    Dúvidas: 'Help',
    'Como Funciona': 'How It Works',
    FAQ: 'FAQ',
  },
};

const items = [
  {
    title: 'Como Funciona',
    to: {
      pt_BR: '/como-funciona',
      en_US: '/en_US/how-it-works',
    },
  },
  {
    title: 'FAQ',
    to: {
      pt_BR: '/faq',
      en_US: '/en_US/faq',
    },
  },
];

function Footer(props) {
  const { location } = props;
  const locale = localization[location.pathname.split('/')[1]] ? location.pathname.split('/')[1] : 'pt_BR';
  return (
    <div className="footer">
      <Container>
        <Row>
          <Col xs="auto" style={{ marginLeft: 15, marginRight: 40 }}>
            <h5>{localization[locale].Contato}</h5>
            <Nav vertical>
              <NavItem>
                <NavLink href="mailto:vv@volunteervacations.com.br">vv@volunteervacations.com.br</NavLink>
              </NavItem>
              <NavItem>
                <Button
                  style={{ textAlign: 'left', padding: 0 }}
                  color="link"
                  onClick={(e) => {
                    e.preventDefault();
                    window.location = 'whatsapp://send?phone=+5521989132838';
                  }}
                >
                  +55 21 98913-2838
                </Button>
              </NavItem>
            </Nav>
            <MediaQuery maxWidth={992}>
              <hr />
            </MediaQuery>
          </Col>
          <Col xs="auto" style={{ marginLeft: 15 }}>
            <h5>{localization[locale]['Dúvidas']}</h5>
            <Nav vertical>
              {
                items.map(item => (
                  <NavItem key={item.title}>
                    <NavLink
                      href={item.to[locale]}
                      to={item.to[locale]}
                    >
                      {localization[locale][item.title]}
                    </NavLink>
                  </NavItem>
                ))
              }
            </Nav>
            <MediaQuery maxWidth={992}>
              <hr />
            </MediaQuery>
          </Col>
          <Col xs="auto" style={{ marginLeft: 15, marginRight: 40, marginBottom: 15 }}>
            <h5>{localization[locale]['Formas de Pagamento']}</h5>
            <Nav>
              <img src="/assets/amex.png" alt="amex" height="24" style={{ marginRight: 5, borderRadius: 5 }} />
              <img src="/assets/visa.png" alt="visa" height="24" style={{ marginRight: 5, borderRadius: 5 }} />
              <img src="/assets/mastercard.png" alt="mastercard" height="24" style={{ marginRight: 5, borderRadius: 5 }} />
              <img src="/assets/diners.png" alt="diners" height="24" style={{ marginRight: 5, borderRadius: 5 }} />
              <img src="/assets/boleto.png" alt="boleto" height="24" style={{ marginRight: 5, borderRadius: 5 }} />
            </Nav>
            <MediaQuery maxWidth={992}>
              <hr />
            </MediaQuery>
          </Col>
          <Col>
            <Nav className="float-right">
              <NavItem className="zoom">
                <NavLink href="https://www.youtube.com/channel/UCjtplS_O21riX-4SKim2ATQ" target="_blank" rel="noopener noreferrer"><img src="/assets/youtube.png" alt="youtube" width="30" height="30" /></NavLink>
              </NavItem>
              <NavItem className="zoom">
                <NavLink href="http://twitter.com/VolunteerVac" target="_blank" rel="noopener noreferrer"><img src="/assets/twitter.png" alt="twitter" width="30" height="30" /></NavLink>
              </NavItem>
              <NavItem className="zoom">
                <NavLink href="http://instagram.com/volunteervac" target="_blank" rel="noopener noreferrer"><img src="/assets/instagram.png" alt="instagram" width="30" height="30" /></NavLink>
              </NavItem>
              <NavItem className="zoom">
                <NavLink href="https://www.facebook.com/volunteervacations?fref=ts" target="_blank" rel="noopener noreferrer"><img src="/assets/facebook.png" alt="facebook" width="30" height="30" /></NavLink>
              </NavItem>
            </Nav>
          </Col>
        </Row>
        <hr />
        <Row style={{ marginTop: 30 }}>
          <Col className="text-center">
            <div>Copyright 2018 © Volunteer Vacations</div>
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
            <a href="https://marcelocotrim.com" target="_blank" rel="noopener noreferrer">Desenvolvido por Cotrim Alpha Solutions</a>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

Footer.propTypes = {
  location: PropTypes.shape({}),
};

export default withRouter(Footer);
