import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Form, FormGroup, FormFeedback, Label, Input } from 'reactstrap';
import MaskedTextInput from 'react-text-mask';
import { changeContactField, resetContactForm, saveContact } from './../actions';

const localization = {
  pt_BR: {
    Nome: 'Nome',
    'Esse campo é obrigatório': 'Esse campo é obrigatório',
    'E-mail': 'E-mail',
    Telefone: 'Telefone',
    Mensagem: 'Mensagem',
    Enviar: 'Enviar',
  },
  en_US: {
    Nome: 'Name',
    'Esse campo é obrigatório': 'This field is required',
    'E-mail': 'E-mail',
    Telefone: 'Phone',
    Mensagem: 'Message',
    Enviar: 'Submit',
  },
};

class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onClick = this.onClick.bind(this);
  }
  componentWillUnmount() {
    this.props.resetContactForm();
  }
  onChange(e) {
    this.props.changeContactField({ prop: e.target.id, value: e.target.value });
  }
  onClick(e) {
    e.preventDefault();
    const {
      name,
      email,
      phoneNumber,
      message,
    } = this.props;
    this.props.saveContact(name, email, phoneNumber, message);
  }
  render() {
    const {
      match,
      name,
      email,
      phoneNumber,
      message,
      submit,
      isLoading,
    } = this.props;
    const locale = match.params.locale ? match.params.locale : 'pt_BR';
    let marginBottom = 0;
    if ($(window).width() < 768) {
      marginBottom = 30;
    }
    return (
      <Form style={{ maxWidth: 400, marginBottom }}>
        <FormGroup color={submit === true && !name ? 'danger' : null}>
          <Label for="name">{localization[locale].Nome}</Label>
          <Input id="name" type="text" value={name} state={submit === true && !name ? 'danger' : null} onChange={this.onChange} />
          {submit === true &&
            <FormFeedback hidden={name}>{localization[locale]['Esse campo é obrigatório']}</FormFeedback>
          }
        </FormGroup>
        <FormGroup color={submit === true && !email ? 'danger' : null}>
          <Label for="email">{localization[locale]['E-mail']}</Label>
          <Input id="email" type="text" value={email} state={submit === true && !email ? 'danger' : null} onChange={this.onChange} />
          {submit === true &&
            <FormFeedback hidden={email}>{localization[locale]['Esse campo é obrigatório']}</FormFeedback>
          }
        </FormGroup>
        <FormGroup>
          <Label for="number">{localization[locale].Telefone}</Label>
          <MaskedTextInput
            id="phoneNumber"
            mask={['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            className="form-control"
            guide
            value={phoneNumber}
            onChange={this.onChange}
          />
        </FormGroup>
        <FormGroup color={submit === true && !message ? 'danger' : null}>
          <Label for="message">{localization[locale].Mensagem}</Label>
          <Input id="message" type="textarea" rows={5} value={message} onChange={this.onChange} />
          {submit === true &&
            <FormFeedback hidden={message}>{localization[locale]['Esse campo é obrigatório']}</FormFeedback>
          }
        </FormGroup>
        <Button disabled={isLoading} color="primary" onClick={this.onClick}>{localization[locale].Enviar}</Button>
      </Form>
    );
  }
}

ContactForm.propTypes = {
  match: PropTypes.shape({}),
  changeContactField: PropTypes.func,
  resetContactForm: PropTypes.func,
  saveContact: PropTypes.func,
  name: PropTypes.string,
  email: PropTypes.string,
  phoneNumber: PropTypes.string,
  message: PropTypes.string,
  submit: PropTypes.bool,
  isLoading: PropTypes.bool,
};

function mapStateToProps({ contactReducer }) {
  return contactReducer;
}
function mapDispatchToProps(dispatch) {
  return {
    changeContactField: (field) => {
      dispatch(changeContactField(field));
    },
    resetContactForm: () => {
      dispatch(resetContactForm());
    },
    saveContact: (name, email, phoneNumber, message) => {
      dispatch(saveContact(name, email, phoneNumber, message));
    },
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContactForm));
