import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Alert, Col, Container, Row } from 'reactstrap';
import ReactLoading from 'react-loading';
import moment from 'moment';
import { fetchPosts, resetBlogPage } from './../actions';
import PostRow from './PostRow';

const months = () => {
  const startDate = moment();
  const endDate = moment('2014-11-01');
  const dates = [];
  endDate.subtract(1, 'month');
  const month = moment(startDate);
  while (month < endDate) {
    month.add(1, 'month');
    dates.push(month.format('YYYY-MM-DD'));
  }
  return dates;
};

class Blog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      month: null,
    };
  }
  componentWillMount() {
    const { skip, lastDate } = this.props;
    this.props.fetchPosts(skip, lastDate);
  }
  componentWillUnmount() {
    this.props.resetBlogPage();
  }
  render() {
    const { match, posts, isLoading, error } = this.props;
    const locale = match.params.locale ? match.params.locale : 'pt_BR';
    moment.locale(locale);
    return (
      <div style={{ marginTop: 60 }}>
        <div>
          <img alt="cover" width="100%" className="img-fluid" src="/assets/cover.png" />
        </div>
        <Container style={{ paddingTop: 40 }}>
          {isLoading === true &&
            <ReactLoading delay={0} className="spin" type="spin" color="#ccc" height={24} width={24} />
          }
          {error &&
            <Alert color="danger">
              {`'Oops! ${error.message}`}
            </Alert>
          }
          <h1>Blog</h1>
          <br />
          <Row>
            <Col>
              {
                posts.map(post => (
                  <PostRow key={post.objectId} post={post} />
                ))
              }
            </Col>
            <Col xs="auto">

            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

Blog.propTypes = {
  match: PropTypes.shape({}),
  fetchPosts: PropTypes.func,
  resetBlogPage: PropTypes.func,
  posts: PropTypes.arrayOf(PropTypes.shape({})),
  skip: PropTypes.number,
  lastDate: PropTypes.shape({}),
  lastPage: PropTypes.bool,
  isLoading: PropTypes.bool,
  error: PropTypes.shape({}),
};

function mapStateToProps({ postReducer }) {
  return postReducer;
}
function mapDispatchToProps(dispatch) {
  return {
    fetchPosts: (skip, lastDate) => {
      dispatch(fetchPosts(skip, lastDate));
    },
    resetBlogPage: () => {
      dispatch(resetBlogPage());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Blog);
