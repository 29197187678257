import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Alert, Container, Row, Col } from 'reactstrap';
import ReactLoading from 'react-loading';
import AccountForm from './AccountForm';
import { fetchUser } from './../actions';

const localization = {
  pt_BR: {
    1: 'Detalhes da Conta',
  },
  en_US: {
    1: 'Account Details',
  },
};

class Account extends Component {
  componentWillMount() {
    this.props.fetchUser();
  }
  render() {
    const {
      match,
      user,
      isLoading,
      success,
      error,
    } = this.props;
    const locale = match.params.locale ? match.params.locale : 'pt_BR';
    return (
      <Container>
        {isLoading === true &&
          <ReactLoading delay={0} className="spin" type="spin" color="#ccc" height={24} width={24} />
        }
        {success &&
          <Alert color="success">
            {'Seus dados foram salvos com sucesso.'}
          </Alert>
        }
        {error &&
          <Alert color="danger">
            {`'Oops! ${error.message}`}
          </Alert>
        }
        {user &&
          <div>
            <h1>{localization[locale][1]}</h1>
            <Row>
              <Col>
                <AccountForm />
              </Col>
            </Row>
          </div>
        }
      </Container>
    );
  }
}

Account.propTypes = {
  match: PropTypes.shape({}),
  fetchUser: PropTypes.func,
  user: PropTypes.shape({}),
  isLoading: PropTypes.bool,
  success: PropTypes.bool,
  error: PropTypes.shape({}),
};

function mapStateToProps({ userReducer }) {
  return userReducer;
}
function mapDispatchToProps(dispatch) {
  return {
    fetchUser: () => {
      dispatch(fetchUser());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Account);
