import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Col, Card, CardImg } from 'reactstrap';
import { showMediaModal } from './../actions';

class MediaCard extends Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }
  onClick(e) {
    e.preventDefault();
    const { media } = this.props;
    this.props.showMediaModal(media);
  }
  render() {
    const { media } = this.props;
    return (
      <Col xs="6" sm="6" md="4" lg="3" xl="2">
        <Card className="media-card zoom" onClick={this.onClick} style={{ minWidth: 100, marginBottom: 20 }}>
          <div className="image">
            {media.mediumFile &&
              <CardImg className="img-fluid" src={media.mediumFile.url} alt={media.objectId} />
            }
          </div>
        </Card>
      </Col>
    );
  }
}

MediaCard.propTypes = {
  showMediaModal: PropTypes.func,
  media: PropTypes.shape({}),
};

function mapDispatchToProps(dispatch) {
  return {
    showMediaModal: (media) => {
      dispatch(showMediaModal(media));
    },
  };
}

export default withRouter(connect(null, mapDispatchToProps)(MediaCard));
