import { Parse } from 'parse';
import {
  FETCH_PAGE,
  FETCH_PAGE_SUCCESS,
  FETCH_PAGE_FAILURE,
} from './types';

export function fetchPageSuccess(page) {
  return {
    type: FETCH_PAGE_SUCCESS,
    payload: {
      page,
    },
  };
}

export function fetchPageFailure(error) {
  return {
    type: FETCH_PAGE_FAILURE,
    payload: { error },
  };
}

export function fetchPage(slug) {
  return ((dispatch) => {
    dispatch({ type: FETCH_PAGE });
    const params = {
      slug,
    };
    return Parse.Cloud.run('fetchPage', params).then((results) => {
      dispatch(fetchPageSuccess(JSON.parse(JSON.stringify(results))));
    }, (error) => {
      dispatch(fetchPageFailure(JSON.parse(JSON.stringify(error))));
    });
  });
}
