import {
  FETCH_CATEGORY,
  FETCH_CATEGORY_SUCCESS,
  FETCH_CATEGORY_FAILURE,
  RESET_CATEGORY_PAGE,
} from '../actions/types';

const INITIAL_STATE = {
  category: null,
  error: null,
  isLoading: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_CATEGORY:
      return {
        ...state,
        category: null,
        error: null,
        isLoading: true,
      };
    case FETCH_CATEGORY_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      };
    case FETCH_CATEGORY_FAILURE:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      };
    case RESET_CATEGORY_PAGE:
      return INITIAL_STATE;
    default:
      return state;
  }
};
