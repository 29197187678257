import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Button, Card, CardImg, CardText, CardBlock, CardTitle, CardSubtitle, Col } from 'reactstrap';
import currencyFormatter from 'currency-formatter';
import moment from 'moment';
import { showSignUpModal } from './../actions';

const localization = {
  pt_BR: {
    'Quero Participar': 'Quero Participar',
    até: 'até',
    'A partir de': 'A partir de',
    '/projetos': '/projetos',
    '/inscricao': '/inscricao',
    'Inscrições encerradas': 'Inscrições encerradas',
    'Projeto encerrado': 'Projeto encerrado',
  },
  en_US: {
    'Quero Participar': 'I Want to Participate',
    até: 'to',
    'A partir de': 'Starting at',
    '/projetos': '/projects',
    '/inscricao': '/join',
    'Inscrições encerradas': 'Subscription closed',
    'Projeto encerrado': 'Closed project',
  },
};

class ProjectCard extends Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }
  onClick(e) {
    e.preventDefault();
    const {
      match,
      history,
      user,
      project,
    } = this.props;
    const locale = match.params.locale ? match.params.locale : 'pt_BR';
    if (!user) {
      this.props.showSignUpModal();
    } else {
      history.push(`${locale === 'pt_BR' ? '' : `/${locale}`}${localization[locale]['/inscricao']}/${project.slug}`);
    }
  }
  render() {
    const { match, history, project } = this.props;
    const locale = match.params.locale ? match.params.locale : 'pt_BR';
    const symbol = project.currency === 'USD' ? { symbol: 'US$ ' } : null;
    return (
      <Col xs="12" sm="4" md="4" lg="3" xl="3">
        <Card className="project-card zoom" style={{ marginBottom: 20 }}>
          <Link
            href={`${locale === 'pt_BR' ? '' : `/${locale}`}${localization[locale]['/projetos']}/${project.slug}`}
            to={`${locale === 'pt_BR' ? '' : `/${locale}`}${localization[locale]['/projetos']}/${project.slug}`}
            onClick={(e) => {
              e.preventDefault();
              history.push(`${locale === 'pt_BR' ? '' : `/${locale}`}${localization[locale]['/projetos']}/${project.slug}`);
            }}
          >
            <div className="image">
              <CardImg className="img-fluid" src={project.cover ? project.cover.mediumFile.url : process.env.IMAGE_PLACEHOLDER_URL} alt={project.name} />
            </div>
            <CardBlock>
              <CardTitle>{project[locale].name}</CardTitle>
              <CardSubtitle>{`${project.category[locale].name} - ${project.destination[locale].name}`}</CardSubtitle>
            </CardBlock>
          </Link>
          <CardBlock>
            {!project.closed && (!project.startDate ||
            (project.startDate &&
            moment(project.startDate.iso ?
              project.startDate.iso : project.startDate).tz(project.timeZone)
              > moment().tz(project.timeZone))) &&
              <div>
                {project.startDate && project.endDate &&
                  <CardText>{`${moment(project.startDate.iso ? project.startDate.iso : project.startDate).format('DD/MM')} ${localization[locale].até} ${moment(project.endDate.iso ? project.endDate.iso : project.endDate).format('DD/MM')}`}</CardText>
                }
                {project.minAmount &&
                  <div>
                    {`${localization[locale]['A partir de']} `}
                    {project.originalMinAmount &&
                      <b style={{ fontWeight: 'lighter', textDecoration: 'line-through' }}>{`${currencyFormatter.format(project.originalMinAmount / 100, { code: project.currency, symbol: project.currency === 'USD' ? 'US$' : null })} `}</b>
                    }
                    <strong>{`${currencyFormatter.format(project.minAmount / 100, { code: project.currency, ...symbol })}`}</strong>
                  </div>
                }
                <Button color="primary" onClick={this.onClick}>
                  <img style={{ marginTop: -4, marginRight: 5 }} height={20} src="/assets/logo-white.png" alt="logo" />
                  {localization[locale]['Quero Participar']}
                </Button>
              </div>
            }
            {project.closed &&
              <div>
                {localization[locale]['Inscrições encerradas']}
              </div>
            }
            {(project.startDate &&
              moment(project.startDate.iso
              ? project.startDate.iso :
              project.startDate).tz(project.timeZone) < moment().tz(project.timeZone)) &&
              <div>
                {localization[locale]['Projeto encerrado']}
              </div>
            }
          </CardBlock>
        </Card>
      </Col>
    );
  }
}

ProjectCard.propTypes = {
  match: PropTypes.shape({}),
  history: PropTypes.shape({}),
  showSignUpModal: PropTypes.func,
  project: PropTypes.shape({}),
  user: PropTypes.shape({}),
};

function mapStateToProps({ projectsReducer, userReducer, orderReducer }) {
  return { ...projectsReducer, ...userReducer, ...orderReducer };
}
function mapDispatchToProps(dispatch) {
  return {
    showSignUpModal: () => {
      dispatch(showSignUpModal());
    },
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProjectCard));
