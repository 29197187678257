import { Parse } from 'parse';
import {
  FETCH_PRESS,
  FETCH_PRESS_SUCCESS,
  FETCH_PRESS_FAILURE,
} from './types';

export function fetchPressSuccess(press, skip, lastDate, lastPage) {
  return {
    type: FETCH_PRESS_SUCCESS,
    payload: {
      press,
      skip,
      lastDate,
      lastPage,
    },
  };
}

export function fetchPressFailure(error) {
  return {
    type: FETCH_PRESS_FAILURE,
    payload: { error },
  };
}

export function fetchPress(skip, lastDate) {
  return ((dispatch) => {
    dispatch({ type: FETCH_PRESS });
    const params = {
      skip, lastDate,
    };
    return Parse.Cloud.run('fetchPress', params).then((results) => {
      dispatch(fetchPressSuccess(
        JSON.parse(JSON.stringify(results)),
        results.length + skip,
        lastDate,
        results.length === 0,
      ));
    }, (error) => {
      dispatch(fetchPressFailure(JSON.parse(JSON.stringify(error))));
    });
  });
}
