import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Alert, Col, Container, Row } from 'reactstrap';
import ReactLoading from 'react-loading';
import LostPasswordForm from './LostPasswordForm';

const localization = {
  pt_BR: {
    1: 'Perdeu sua senha?',
    2: 'Digite seu endereço de e-mail. Você receberá um link por e-mail para criar uma nova senha.',
  },
  en_US: {
    1: 'Perdeu sua senha?',
    2: 'Digite seu endereço de e-mail. Você receberá um link por e-mail para criar uma nova senha.',
  },
};

function LostPassword(props) {
  const {
    match,
    isLoading,
    success,
    error,
  } = props;
  const locale = match.params.locale ? match.params.locale : 'pt_BR';
  return (
    <div style={{ marginTop: 60 }}>
      <div>
        <img width="100%" className="img-fluid" src="/assets/cover.png" alt="cover" />
      </div>
      <Container style={{ paddingTop: 40 }}>
        {isLoading === true &&
          <ReactLoading delay={0} className="spin" type="spin" color="#ccc" height={24} width={24} />
        }
        {success &&
          <Alert color="success">
            {'O e-mail foi enviado com sucesso.'}
          </Alert>
        }
        {error &&
          <Alert color="danger">
            {`'Oops! ${error.message}`}
          </Alert>
        }
        <h1>{localization[locale][1]}</h1>
        {localization[locale][2]}
        <hr />
        <Row>
          <Col>
            <LostPasswordForm />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

LostPassword.propTypes = {
  match: PropTypes.shape({}),
  success: PropTypes.bool,
  error: PropTypes.shape({}),
  isLoading: PropTypes.bool,
};

function mapStateToProps({ lostPasswordReducer }) {
  return lostPasswordReducer;
}

export default connect(mapStateToProps, null)(LostPassword);
