import { Parse } from 'parse';
import {
  FETCH_FEATURED_CONTENTS,
  FETCH_FEATURED_CONTENTS_SUCCESS,
  FETCH_FEATURED_CONTENTS_FAILURE,
} from './types';

export function fetchFeaturedContentsSuccess(featuredContents) {
  return {
    type: FETCH_FEATURED_CONTENTS_SUCCESS,
    payload: { featuredContents },
  };
}

export function fetchFeaturedContentsFailure(error) {
  return {
    type: FETCH_FEATURED_CONTENTS_FAILURE,
    payload: { error },
  };
}

export function fetchFeaturedContents() {
  return ((dispatch) => {
    dispatch({ type: FETCH_FEATURED_CONTENTS });
    return Parse.Cloud.run('fetchFeaturedContents').then((results) => {
      dispatch(fetchFeaturedContentsSuccess(JSON.parse(JSON.stringify(results))));
    }, (error) => {
      dispatch(fetchFeaturedContentsFailure(JSON.parse(JSON.stringify(error))));
    });
  });
}
