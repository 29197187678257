import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Alert, Card, Col, Container, Row } from 'reactstrap';
import ReactHtmlParser from 'react-html-parser';
import ReactLoading from 'react-loading';
import { fetchPress } from './../actions';

const localization = {
  pt_BR: {
    Imprensa: 'Imprensa',
  },
  en_US: {
    Imprensa: 'Press',
  },
};

class Press extends Component {
  componentWillMount() {
    const { skip, lastDate } = this.props;
    this.props.fetchPress(skip, lastDate);
  }
  render() {
    const {
      match,
      press,
      isLoading,
      error,
    } = this.props;
    const locale = match.params.locale ? match.params.locale : 'pt_BR';
    return (
      <div style={{ marginTop: 60 }}>
        <div className="cover text-center">
          <img width="75%" className="img-fluid" src="/assets/cover-press.jpg" alt="cover" />
        </div>
        <Container style={{ paddingTop: 40 }}>
          <Row>
            <Col>
              {isLoading === true &&
                <ReactLoading delay={0} className="spin" type="spin" color="#ccc" height={24} width={24} />
              }
              {error &&
                <Alert color="danger">
                  {`Oops! ${error.message}`}
                </Alert>
              }
              <h1>{localization[locale].Imprensa}</h1>
              {
                press.map(item => (
                  <div key={item.name} className="zoom">
                    <Row>
                      <Col xs="auto" style={{ marginLeft: 15 }}>
                        {item.cover &&
                          <Card>
                            <a to={item.link} href={item.link} target="_blank" rel="noopener noreferrer">
                              <img alt={item.name} style={{ maxWidth: 200 }} className="img-fluid" src={item.cover.mediumFile.url} />
                            </a>
                          </Card>
                        }
                      </Col>
                      <Col xs="9" sm="9" md="6">
                        <h5>
                          <a to={item.link} href={item.link} target="_blank" rel="noopener noreferrer">{item.name}</a>
                        </h5>
                        <div className="linebreak">
                          {ReactHtmlParser(item.text)}
                        </div>
                      </Col>
                    </Row>
                    <hr />
                  </div>
                ))
              }
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

Press.propTypes = {
  fetchPress: PropTypes.func,
  match: PropTypes.shape({}),
  skip: PropTypes.number,
  lastDate: PropTypes.shape({}),
  // lastPage: PropTypes.bool,
  press: PropTypes.arrayOf(PropTypes.shape({})),
  isLoading: PropTypes.bool,
  error: PropTypes.shape({}),
};

function mapStateToProps({ pressReducer }) {
  return { ...pressReducer };
}
function mapDispatchToProps(dispatch) {
  return {
    fetchPress: (skip, lastDate) => {
      dispatch(fetchPress(skip, lastDate));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Press);
