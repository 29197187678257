import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Alert, CardDeck, Col, Container, Row } from 'reactstrap';
import ReactLoading from 'react-loading';
import ReactHtmlParser from 'react-html-parser';
import { fetchDestination, resetDestinationPage } from './../actions';
import ProjectCard from './ProjectCard';
import MediaCard from './MediaCard';

const localization = {
  pt_BR: {
    1: 'Informações',
    2: 'Programas',
    3: 'Esse destino não possui programas.',
    4: 'Fotos',
    5: 'Edições Anteriores',
  },
  en_US: {
    1: 'Information',
    2: 'Programs',
    3: 'This destination has no programs.',
    4: 'Photos',
    5: 'Previous Editions',
  },
};

class Destination extends Component {
  componentWillMount() {
    const { match } = this.props;
    this.props.fetchDestination(match.params.slug);
  }
  componentWillUnmount() {
    this.props.resetDestinationPage();
  }
  render() {
    const {
      match,
      destination,
      isLoading,
      error,
    } = this.props;
    const locale = match.params.locale ? match.params.locale : 'pt_BR';
    return (
      <div style={{ marginTop: 60 }}>
        {isLoading === true &&
          <ReactLoading delay={0} className="spin" type="spin" color="#ccc" height={24} width={24} />
        }
        {destination && destination.cover &&
          <div className="cover">
            <img width="100%" className="img-fluid" src={destination.cover.largeFile.url} alt="cover" />
          </div>
        }
        <Container style={{ paddingTop: 40 }}>
          <Row>
            <Col>
              {error &&
                <Alert color="danger">
                  {`'Oops! ${error.message}`}
                </Alert>
              }
              {destination &&
                <div>
                  <h1>{destination[locale].name}</h1>
                  <h3>{destination[locale].title}</h3>
                  <div className="linebreak">
                    {ReactHtmlParser(destination[locale].text)}
                  </div>
                  <hr />
                  {destination.information &&
                    <div>
                      <h3>{localization[locale][1]}</h3>
                      <div className="linebreak">
                        {destination[locale].information}
                      </div>
                      <hr />
                    </div>
                  }
                  {destination.categories.length > 0 &&
                    destination.categories.map(category => (
                      <div key={category.objectId}>
                        <h3>{category[locale].name}</h3>
                        <CardDeck>
                          {category.activeProjects.map(project => (
                            <ProjectCard key={project.objectId} project={project} />
                          ))}
                        </CardDeck>
                      </div>
                    ))
                  }
                  <hr />
                  {destination.oldProjectsCount > 0 &&
                    <div>
                      <h3>{localization[locale][5]}</h3>
                      {destination.categories.length > 0 &&
                        destination.categories.map(category => (
                          category.oldProjects.length > 0 &&
                          <div key={category.objectId}>
                            <h3>{category.name}</h3>
                            <CardDeck>
                              {category.oldProjects.map(project => (
                                <ProjectCard key={project.objectId} project={project} />
                              ))}
                            </CardDeck>
                          </div>
                        ))
                      }
                    </div>
                  }
                  {destination.categories.length === 0 &&
                    localization[locale][3]
                  }
                  {destination.media.length > 1 &&
                    <div>
                      <hr />
                      <h3>{localization[locale][4]}</h3>
                      <CardDeck>
                        {
                          destination.media.map((media, index) => (
                            index > 0 &&
                            <MediaCard key={media.objectId} media={media.media} />
                          ))
                        }
                      </CardDeck>
                    </div>
                  }
                </div>
              }
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

Destination.propTypes = {
  match: PropTypes.shape({}),
  fetchDestination: PropTypes.func,
  resetDestinationPage: PropTypes.func,
  destination: PropTypes.shape({}),
  isLoading: PropTypes.bool,
  error: PropTypes.shape({}),
};

function mapStateToProps({ destinationReducer }) {
  return destinationReducer;
}
function mapDispatchToProps(dispatch) {
  return {
    fetchDestination: (slug) => {
      dispatch(fetchDestination(slug));
    },
    resetDestinationPage: () => {
      dispatch(resetDestinationPage());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Destination);
