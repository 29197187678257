import { Parse } from 'parse';
import {
  FETCH_PARTNERS,
  FETCH_PARTNERS_SUCCESS,
  FETCH_PARTNERS_FAILURE,
} from './types';

export function fetchPartnersSuccess(partners, skip, lastDate, lastPage) {
  return {
    type: FETCH_PARTNERS_SUCCESS,
    payload: {
      partners,
      skip,
      lastDate,
      lastPage,
    },
  };
}

export function fetchPartnersFailure(error) {
  return {
    type: FETCH_PARTNERS_FAILURE,
    payload: { error },
  };
}

export function fetchPartners(skip, lastDate) {
  return ((dispatch) => {
    dispatch({ type: FETCH_PARTNERS });
    const params = {
      skip, lastDate,
    };
    return Parse.Cloud.run('fetchPartners', params).then((results) => {
      dispatch(fetchPartnersSuccess(
        JSON.parse(JSON.stringify(results)),
        results.length + skip,
        lastDate,
        results.length === 0,
      ));
    }, (error) => {
      dispatch(fetchPartnersFailure(JSON.parse(JSON.stringify(error))));
    });
  });
}
