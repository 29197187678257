import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Alert, Col, Container, Row } from 'reactstrap';
import ReactLoading from 'react-loading';
import ReactHtmlParser from 'react-html-parser';
import { fetchPage } from './../actions';

class Page extends Component {
  componentWillMount() {
    const { location } = this.props;
    this.props.fetchPage(location.pathname.slice(1));
  }
  render() {
    const {
      match,
      page,
      isLoading,
      error,
    } = this.props;
    const locale = match.params.locale ? match.params.locale : 'pt_BR';
    return (
      <div style={{ marginTop: 60 }}>
        {isLoading === true &&
          <ReactLoading delay={0} className="spin" type="spin" color="#ccc" height={24} width={24} />
        }
        {page && page.cover &&
          <div className="cover">
            <img width="100%" className="img-fluid" src={page.cover.largeFile.url} alt="cover" />
          </div>
        }
        <Container style={{ paddingTop: 40 }}>
          <Row>
            <Col>
              {error &&
                <Alert color="danger">
                  {`'Oops! ${error.message}`}
                </Alert>
              }
              {page &&
                <div>
                  <h1>{page[locale].title}</h1>
                  <div className="linebreak">
                    {ReactHtmlParser(page[locale].text)}
                  </div>
                </div>
              }
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

Page.propTypes = {
  location: PropTypes.shape({}),
  match: PropTypes.shape({}),
  fetchPage: PropTypes.func,
  page: PropTypes.shape({}),
  isLoading: PropTypes.bool,
  error: PropTypes.shape({}),
};

function mapStateToProps({ pageReducer }) {
  return pageReducer;
}
function mapDispatchToProps(dispatch) {
  return {
    fetchPage: (slug) => {
      dispatch(fetchPage(slug));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Page);
