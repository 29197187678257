import {
  SHOW_MEDIA_MODAL,
  HIDE_MEDIA_MODAL,
} from '../actions/types';

const INITIAL_STATE = {
  isShowing: false,
  media: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SHOW_MEDIA_MODAL:
      return {
        ...state,
        isShowing: true,
        media: action.payload,
      };
    case HIDE_MEDIA_MODAL:
      return {
        ...state,
        isShowing: false,
      };
    default:
      return state;
  }
};
