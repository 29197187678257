import {
  SHOW_MEDIA_MODAL,
  HIDE_MEDIA_MODAL,
} from './types';

export function showMediaModal(media) {
  return {
    type: SHOW_MEDIA_MODAL,
    payload: media,
  };
}

export function hideMediaModal() {
  return {
    type: HIDE_MEDIA_MODAL,
  };
}
