import {
  FETCH_DESTINATION,
  FETCH_DESTINATION_SUCCESS,
  FETCH_DESTINATION_FAILURE,
  RESET_DESTINATION_PAGE,
} from '../actions/types';

const INITIAL_STATE = {
  destination: null,
  error: null,
  isLoading: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_DESTINATION:
      return {
        ...state,
        destination: null,
        error: null,
        isLoading: true,
      };
    case FETCH_DESTINATION_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      };
    case FETCH_DESTINATION_FAILURE:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      };
    case RESET_DESTINATION_PAGE:
      return INITIAL_STATE;
    default:
      return state;
  }
};
