import { Parse } from 'parse';
import {
  RESET_CONTACT_FORM,
  CHANGE_CONTACT_FIELD,
  SAVE_CONTACT,
  SAVE_CONTACT_SUCCESS,
  SAVE_CONTACT_FAILURE,
} from '../actions/types';

export function resetContactForm() {
  return {
    type: RESET_CONTACT_FORM,
  };
}

export function changeContactField({ prop, value }) {
  return {
    type: CHANGE_CONTACT_FIELD,
    payload: { prop, value },
  };
}

export function saveContactSuccess(contact) {
  return {
    type: SAVE_CONTACT_SUCCESS,
    payload: { contact },
  };
}

export function saveContactFailure(error) {
  return {
    type: SAVE_CONTACT_FAILURE,
    payload: { error },
  };
}

export function saveContact(name, email, phoneNumber, message) {
  return ((dispatch) => {
    dispatch({ type: SAVE_CONTACT });
    if (!name || !email || !message) {
      dispatch(saveContactFailure());
      return;
    }
    const params = {
      name,
      email,
      phoneNumber,
      message,
    };
    const user = JSON.parse(localStorage.user);
    Parse.Cloud.run('saveContact', params, { sessionToken: user.sessionToken }).then((results) => {
      dispatch(saveContactSuccess(JSON.parse(JSON.stringify(results))));
    }, (error) => {
      dispatch(saveContactFailure(JSON.parse(JSON.stringify(error))));
    });
  });
}
