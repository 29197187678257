import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Alert, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, FormFeedback, Input, Label } from 'reactstrap';
import { hideLogInModal, changeLogInField, logIn } from './../actions';

const localization = {
  pt_BR: {
    'Esse campo é obrigatório': 'Esse campo é obrigatório',
    Senha: 'Senha',
    Entrar: 'Entrar',
    'Esqueci minha senha': 'Esqueci minha senha',
    '/perdi-minha-senha': '/perdi-minha-senha',
  },
  en_US: {
    'Esse campo é obrigatório': 'This field is required',
    Senha: 'Password',
    Entrar: 'Log In',
    'Esqueci minha senha': 'Forgot my password',
    '/perdi-minha-senha': '/en_US/lost-my-password',
  },
};

class LogInModal extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onClick = this.onClick.bind(this);
    this.toggle = this.toggle.bind(this);
  }
  onChange(e) {
    this.props.changeLogInField({ prop: e.target.id, value: e.target.value });
  }
  onClick() {
    const { email, password } = this.props;
    this.props.logIn(email, password);
  }
  toggle() {
    this.props.hideLogInModal();
  }
  renderForm() {
    const {
      locale,
      email,
      password,
      submit,
    } = this.props;
    return (
      <Form>
        <FormGroup row color={submit === true && !email ? 'danger' : null}>
          <Label for="email">E-mail</Label>
          <Input id="email" type="email" value={email} placeholder="E-mail" onChange={this.onChange} />
          {submit === true &&
            <FormFeedback hidden={email}>{ localization[locale]['Esse campo é obrigatório']}</FormFeedback>
          }
        </FormGroup>
        <FormGroup row color={submit === true && !password ? 'danger' : null}>
          <Label for="password">{localization[locale].Senha}</Label>
          <Input id="password" type="password" value={password} placeholder="Senha" onChange={this.onChange} />
          {submit === true &&
            <FormFeedback hidden={password}>{localization[locale]['Esse campo é obrigatório']}</FormFeedback>
          }
        </FormGroup>
      </Form>
    );
  }

  render() {
    const {
      history,
      locale,
      isShowing,
      isLoading,
      error,
    } = this.props;
    return (
      <Modal isOpen={isShowing} toggle={this.toggle} autoFocus={false}>
        {error &&
          <Alert color="danger">
            {`Oops! ${error.message}`}
          </Alert>
        }
        <ModalHeader toggle={this.toggle}>{localization[locale].Entrar}</ModalHeader>
        <ModalBody>
          {this.renderForm()}
        </ModalBody>
        <ModalFooter>
          <Button
            color="link"
            onClick={(e) => {
              e.preventDefault();
              this.toggle();
              history.push(localization[locale]['/perdi-minha-senha']);
            }}
          >
            {localization[locale]['Esqueci minha senha']}
          </Button>
          <Button disabled={isLoading} color="primary" onClick={this.onClick}>{localization[locale].Entrar}</Button>
        </ModalFooter>
      </Modal>
    );
  }
}

LogInModal.propTypes = {
  history: PropTypes.shape({}),
  locale: PropTypes.string,
  hideLogInModal: PropTypes.func,
  changeLogInField: PropTypes.func,
  logIn: PropTypes.func,
  email: PropTypes.string,
  password: PropTypes.string,
  submit: PropTypes.bool,
  isShowing: PropTypes.bool,
  isLoading: PropTypes.bool,
  error: PropTypes.shape({}),
};

function mapStateToProps({ userReducer, logInModalReducer }) {
  return { ...userReducer, ...logInModalReducer };
}
function mapDispatchToProps(dispatch) {
  return {
    hideLogInModal: () => {
      dispatch(hideLogInModal());
    },
    changeLogInField: (field) => {
      dispatch(changeLogInField(field));
    },
    logIn: (email, password) => {
      dispatch(logIn(email, password));
    },
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LogInModal));
