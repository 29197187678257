import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import ReactGA from 'react-ga';
import NavigationBar from './NavigationBar';
import Footer from './Footer';
import MediaModal from './MediaModal';
import ScrollToTop from './ScrollToTop';
import Routes from './../routes';
import { initialize } from './../actions';

ReactGA.initialize('UA-48886086-1');

function logPageView() {
  ReactGA.set({ page: window.location.pathname + window.location.search });
  ReactGA.pageview(window.location.pathname + window.location.search);
}

class App extends Component {
  componentWillMount() {
    this.props.initialize();
  }
  render() {
    return (
      <Router onUpdate={logPageView()}>
        <div>
          <NavigationBar />
          <ScrollToTop>
            <Routes />
          </ScrollToTop>
          <Footer />
          <MediaModal />
        </div>
      </Router>
    );
  }
}

App.propTypes = {
  initialize: PropTypes.func,
};

function mapDispatchToProps(dispatch) {
  return {
    initialize: () => {
      dispatch(initialize());
    },
  };
}

export default connect(null, mapDispatchToProps)(App);
