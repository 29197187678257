import {
  SHOW_LOG_IN_MODAL,
  HIDE_LOG_IN_MODAL,
  CHANGE_LOG_IN_FIELD,
  RESET_LOG_IN_MODAL,
  RESET_USER,
} from './types';

export function showLogInModal() {
  return {
    type: SHOW_LOG_IN_MODAL,
  };
}

export function hideLogInModal() {
  return ((dispatch) => {
    dispatch({ type: HIDE_LOG_IN_MODAL });
    dispatch({ type: RESET_LOG_IN_MODAL });
    dispatch({ type: RESET_USER });
  });
}

export function changeLogInField({ prop, value }) {
  return {
    type: CHANGE_LOG_IN_FIELD,
    payload: { prop, value },
  };
}
