import { Parse } from 'parse';
import validator from 'validator';
import moment from 'moment';
import {
  CHANGE_USER_FIELD,
  SIGN_UP,
  SIGN_UP_SUCCESS,
  SIGN_UP_FAILURE,
  LOG_IN,
  LOG_IN_SUCCESS,
  LOG_IN_FAILURE,
  LOG_OUT,
  LOG_OUT_SUCCESS,
  LOG_OUT_FAILURE,
  RESET_USER,
  FETCH_USER,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILURE,
} from './types';

import { hideSignUpModal, hideLogInModal } from './../actions';

export function changeUserField({ prop, value }) {
  return {
    type: CHANGE_USER_FIELD,
    payload: { prop, value },
  };
}

export function signUpSuccess(user) {
  return {
    type: SIGN_UP_SUCCESS,
    payload: {
      user,
      ...user,
      phoneNumber: user.phoneNumber.nationalNumber,
      birthDate: user.birthDate ? moment.utc(user.birthDate.iso) : null,
    },
  };
}

export function signUpFailure(error) {
  return {
    type: SIGN_UP_FAILURE,
    payload: { error },
  };
}

export function signUp(
  firstName,
  lastName,
  email,
  password,
  phoneNumber,
  cpf,
  identity,
  birthDate,
  nationality,
) {
  return ((dispatch) => {
    dispatch({ type: SIGN_UP });
    let user = localStorage.user ? JSON.parse(localStorage.user) : null;
    if (!firstName || !lastName || !email || (!user && !password) || !phoneNumber) {
      dispatch(signUpFailure());
      return;
    }
    if (validator.isEmail(email) === false) {
      dispatch(signUpFailure({ message: 'Você precisa inserir um e-mail válido.' }));
      return;
    }
    const params = {
      firstName,
      lastName,
      email,
      password,
      phoneNumber: { countryCode: '55', nationalNumber: phoneNumber },
      cpf,
      identity,
      birthDate: birthDate ? moment(birthDate).toDate() : null,
      nationality,
    };
    Parse.Cloud.run('signUp', params, { sessionToken: user ? user.sessionToken : null }).then((results) => {
      user = JSON.parse(JSON.stringify(results));
      localStorage.user = JSON.stringify(user);
      dispatch(signUpSuccess(user));
      dispatch(hideSignUpModal());
    }, (error) => {
      dispatch(signUpFailure(JSON.parse(JSON.stringify(error))));
    });
  });
}

export function logInSuccess(user) {
  return {
    type: LOG_IN_SUCCESS,
    payload: {
      user,
      ...user,
      phoneNumber: user.phoneNumber.nationalNumber,
      birthDate: user.birthDate ? moment.utc(user.birthDate) : null,
    },
  };
}

export function logInFailure(error) {
  return {
    type: LOG_IN_FAILURE,
    payload: { error },
  };
}

export function logIn(email, password) {
  return ((dispatch) => {
    dispatch({ type: LOG_IN });
    if (!email || !password) {
      dispatch(logInFailure());
      return;
    }
    if (validator.isEmail(email) === false) {
      dispatch(logInFailure({ message: 'Você precisa inserir um e-mail válido.' }));
      return;
    }
    Parse.Cloud.run('logIn', { email, password }).then((results) => {
      const user = JSON.parse(JSON.stringify(results));
      localStorage.user = JSON.stringify(user);
      dispatch(logInSuccess(user));
      dispatch(hideLogInModal());
    }, (error) => {
      dispatch(logInFailure(JSON.parse(JSON.stringify(error))));
    });
  });
}

export function logOutSuccess() {
  return {
    type: LOG_OUT_SUCCESS,
  };
}

export function logOutFailure(error) {
  return {
    type: LOG_OUT_FAILURE,
    payload: { error },
  };
}

export function logOut() {
  return ((dispatch) => {
    dispatch({ type: LOG_OUT });
    const user = JSON.parse(localStorage.user);
    return Parse.Cloud.run('logOut', null, { sessionToken: user.sessionToken }).then(() => {
      localStorage.removeItem('user');
      window.location.assign('/');
      dispatch(logOutSuccess());
    }, (error) => {
      localStorage.removeItem('user');
      window.location.assign('/');
      dispatch(logOutFailure(JSON.parse(JSON.stringify(error))));
    });
  });
}

export function resetUser() {
  return {
    type: RESET_USER,
  };
}

export function fetchUserSuccess(user) {
  return {
    type: FETCH_USER_SUCCESS,
    payload: {
      user,
      ...user,
      phoneNumber: user.phoneNumber.nationalNumber,
      birthDate: user.birthDate ? moment.utc(user.birthDate) : null,
    },
  };
}

export function fetchUserFailure(error) {
  return {
    type: FETCH_USER_FAILURE,
    payload: { error },
  };
}

export function fetchUser() {
  return ((dispatch) => {
    dispatch({ type: FETCH_USER });
    let user = JSON.parse(localStorage.user);
    return Parse.Cloud.run('fetchUser', null, { sessionToken: user.sessionToken }).then((results) => {
      user = JSON.parse(JSON.stringify(results));
      localStorage.user = JSON.stringify(user);
      dispatch(fetchUserSuccess(user));
    }, (error) => {
      dispatch(fetchUserFailure(JSON.parse(JSON.stringify(error))));
    });
  });
}

export function initialize() {
  return ((dispatch) => {
    if (localStorage.user) {
      dispatch(logInSuccess(JSON.parse(localStorage.user)));
      dispatch(fetchUser());
    }
  });
}
