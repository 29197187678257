import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Alert, Button, Container, Table } from 'reactstrap';
import ReactLoading from 'react-loading';
import currencyFormatter from 'currency-formatter';
import moment from 'moment';
import { fetchOrder } from './../actions';
import PaymentRow from './PaymentRow';

const localization = {
  pt_BR: {
    'Comprar Agora': 'Comprar Agora',
    '/finalizar-compra': '/finalizar-compra',
    'dia(s)': 'dia(s)',
    'Visualizar Boleto': 'Visualizar Boleto',
    1: 'Quero Participar',
    2: 'Informações',
    3: 'Períodos / Custos',
    4: 'Requisitos',
    5: 'Idade',
    6: 'anos',
    7: 'Data e Hora de Chegada',
    8: 'Data e Horário de Partida',
    9: 'dia(s)',
    10: 'de desconto',
    11: 'Periodo / Custo',
    12: 'Valor Total de Pagamento',
    13: 'Data de Preferência',
    14: 'de',
    15: 'Valor da Parcela',
    16: 'com cartão de crédito',
    17: 'com PayPal',
    18: 'Visualizar Contrato',
  },
  en_US: {
    'Comprar Agora': 'Buy Now',
    '/finalizar-compra': '/checkout',
    'dia(s)': 'day(s)',
    'Visualizar Boleto': 'View Boleto',
    1: 'I Want to Participate',
    2: 'Information',
    3: 'Period / Cost',
    4: 'Requirements',
    5: 'Age',
    6: 'years',
    7: 'Date and Time of Arrival',
    8: 'Date and Time of Departure',
    9: 'day(s)',
    10: 'of discount',
    11: 'Period / Cost',
    12: 'Total Payment Amount',
    13: 'Date of Preference',
    14: 'of',
    15: 'Installment Amount',
    16: 'with credit card',
    17: 'with PayPal',
    18: 'View Agreement',
  },
};

class Trip extends Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }
  componentWillMount() {
    const { match } = this.props;
    this.props.fetchOrder(match.params.objectId);
  }
  onClick(e) {
    e.preventDefault();
    const { match, history, order } = this.props;
    const locale = match.params.locale ? match.params.locale : 'pt_BR';
    history.push(`${locale === 'pt_BR' ? '' : `/${locale}`}${localization[locale]['/finalizar-compra']}/${order.objectId}`);
  }
  render() {
    const {
      match,
      order,
      isLoading,
      error,
    } = this.props;
    const locale = match.params.locale ? match.params.locale : 'pt_BR';
    return (
      <div style={{ marginTop: 60 }}>
        {order && order.project.cover &&
          <div className="cover">
            <img width="100%" className="img-fluid" src={order.project.cover.largeFile.url} alt={order.project.name} />
          </div>
        }
        <Container style={{ paddingTop: 40 }}>
          {isLoading === true &&
            <ReactLoading delay={0} className="spin" type="spin" color="#ccc" height={24} width={24} />
          }
          {error &&
            <Alert color="danger">
              {`Oops! ${error.message}`}
            </Alert>
          }
          {isLoading === false && order &&
            <div>
              <h1>{order.project[locale].name}</h1>
              <h5>{order.project.destination[locale].name}</h5>
              <h5 style={{ color: order.status.color }}>
                {order.status[locale].name}
              </h5>
              {order.paymentAmount &&
                <div>
                  <hr />
                  <h5 style={{ marginTop: 10 }}>{localization[locale][12]}</h5>
                  {currencyFormatter.format(order.totalPaymentAmount ? order.totalPaymentAmount / 100 : order.paymentAmount / 100, { code: 'BRL' })}
                </div>
              }
              {order.pagseguroTransaction &&
                <div>
                  {order.pagseguroTransaction.installmentCount > 1 &&
                    <div>
                      {`${order.pagseguroTransaction.installmentCount}x ${localization[locale][14]} ${currencyFormatter.format(order.totalPaymentAmount / 100 / order.pagseguroTransaction.installmentCount, { code: 'BRL' })} ${localization[locale][16]}`}
                    </div>
                  }
                </div>
              }
              {order.payPalPayment &&
                <div>
                  {localization[locale][17]}
                </div>
              }
              {order.status.slug === 'aprovado-para-pagamento' && !order.transaction &&
                <Button color="primary" onClick={this.onClick}>
                  <img alt="logo" style={{ marginTop: -4, marginRight: 5 }} height={20} src="/assets/logo-white.png" />
                  {localization[locale]['Comprar Agora']}
                </Button>
              }
              {order.asaasPayments &&
                <Table
                  style={{ marginTop: 10 }}
                  striped
                >
                  <thead>
                    <tr>
                      <th>Descrição</th>
                      <th>Valor da Parcela</th>
                      <th>Data de Vencimento</th>
                      <th>Status</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {
                      order.asaasPayments.map(payment => (
                        <PaymentRow key={payment.id} payment={payment} />
                      ))
                    }
                  </tbody>
                </Table>
              }
              <div style={{ marginTop: 10 }}>
                <a target="_blank" rel="noopener noreferrer" href={order.project.agreement.file.url} to={order.project.agreement.file.url}>
                  {localization[locale][18]}
                </a>
              </div>
              <hr />
              <h5>{localization[locale][11]}</h5>
              {`${order.days} ${localization[locale]['dia(s)']} - ${currencyFormatter.format(order.amount / 100, { code: order.currency.toUpperCase() })}${order.details ? ` - ${order.details[locale]}` : ''}`}
              <br /><br />
              {order.date &&
                <div>
                  <h5 style={{ marginTop: 10 }}>{localization[locale][13]}</h5>
                  {moment(order.date.iso).format('DD/MM/YYYY')}
                </div>
              }
              {order.project.startDate && order.project.timeZone &&
                <div>
                  <h5>{localization[locale][7]}</h5>
                  {moment(order.project.startDate.iso).tz(order.project.timeZone).format('DD, MMMM YYYY, HH:mm')}
                  <br />
                  <br />
                </div>
              }
              {order.project.endDate && order.project.timeZone &&
                <div>
                  <h5>{localization[locale][8]}</h5>
                  {moment(order.project.endDate.iso).tz(order.project.timeZone).format('DD, MMMM YYYY, HH:mm')}
                  <br />
                  <br />
                </div>
              }
            </div>
          }
        </Container>
      </div>
    );
  }
}

// {order.transaction && order.transaction.bankSlipUrl &&
//   <div>
//     {order.transaction.description}
//     <h5 style={{ marginTop: 10 }}>{localization[locale][15]}</h5>
//     {currencyFormatter.format(order.transaction.value, { code: 'BRL' })}
//     <div>
//       <Button
//         style={{ marginTop: 10 }}
//         color="primary"
//         href={order.transaction.bankSlipUrl}
//         target="_blank"
//         rel="noopener noreferrer"
//       >
//         {localization[locale]['Visualizar Boleto']}
//       </Button>
//     </div>
//   </div>
// }

Trip.propTypes = {
  match: PropTypes.shape({}),
  history: PropTypes.shape({}),
  fetchOrder: PropTypes.func,
  order: PropTypes.shape({}),
  isLoading: PropTypes.bool,
  error: PropTypes.shape({}),
};

function mapStateToProps({ userReducer, orderReducer }) {
  return { ...userReducer, ...orderReducer };
}
function mapDispatchToProps(dispatch) {
  return {
    fetchOrder: (objectId) => {
      dispatch(fetchOrder(objectId));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Trip);
