import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Col, Card, CardBlock, CardImg, CardSubtitle, CardTitle } from 'reactstrap';

const localization = {
  pt_BR: {
    '/destinos': '/destinos',
  },
  en_US: {
    '/destinos': '/destination',
  },
};

function DestinationCard(props) {
  const { match, destination } = props;
  const locale = match.params.locale ? match.params.locale : 'pt_BR';
  return (
    <Col xs="12" sm="6" md="4" lg="3" xl="3" style={{ marginBottom: 30 }} className="center-block">
      <Card className="destination-card zoom">
        <Link
          href={`${locale === 'pt_BR' ? '' : locale}${localization[locale]['/destinos']}/${destination.slug}`}
          to={`${locale === 'pt_BR' ? '' : locale}${localization[locale]['/destinos']}/${destination.slug}`}
        >
          {destination && destination.cover &&
            <div className="image" >
              <CardImg className="img-fluid" src={destination.cover.mediumFile.url} alt={destination.name} />
            </div>
          }
          <CardBlock>
            <CardTitle className="center-block text-center">
              {destination[locale].name}
            </CardTitle>
            <CardSubtitle className="center-block text-center">
              {destination[locale].title}
            </CardSubtitle>
          </CardBlock>
        </Link>
      </Card>
    </Col>
  );
}

DestinationCard.propTypes = {
  match: PropTypes.shape({}),
  destination: PropTypes.shape({}),
};

export default withRouter(connect(null)(DestinationCard));
