import { Parse } from 'parse';
import {
  FETCH_PROJECTS,
  FETCH_PROJECTS_SUCCESS,
  FETCH_PROJECTS_FAILURE,
  FETCH_OLD_PROJECTS,
  FETCH_OLD_PROJECTS_SUCCESS,
  FETCH_OLD_PROJECTS_FAILURE,
  RESET_PROJECTS_PAGE,
  FETCH_PROJECT,
  FETCH_PROJECT_SUCCESS,
  FETCH_PROJECT_FAILURE,
  RESET_PROJECT_PAGE,
} from './types';

export function fetchProjectsSuccess(projects, skip, lastDate, lastPage) {
  return {
    type: FETCH_PROJECTS_SUCCESS,
    payload: {
      projects,
      skip,
      lastDate,
      lastPage,
    },
  };
}

export function fetchProjectsFailure(error) {
  return {
    type: FETCH_PROJECTS_FAILURE,
    payload: { error },
  };
}

export function fetchProjects(skip, lastDate, filters) {
  return ((dispatch) => {
    dispatch({ type: FETCH_PROJECTS });
    const sessionToken = localStorage.user ? JSON.parse(localStorage.user).sessionToken : null;
    return Parse.Cloud.run('fetchProjects', { skip, lastDate, ...filters }, { sessionToken }).then((results) => {
      dispatch(fetchProjectsSuccess(
        JSON.parse(JSON.stringify(results)),
        results.length + skip,
        lastDate,
        results.length === 0,
      ));
    }, (error) => {
      dispatch(fetchProjectsFailure(JSON.parse(JSON.stringify(error))));
    });
  });
}

export function fetchOldProjectsSuccess(oldProjects) {
  return {
    type: FETCH_OLD_PROJECTS_SUCCESS,
    payload: {
      oldProjects,
    },
  };
}

export function fetchOldProjectsFailure(error) {
  return {
    type: FETCH_OLD_PROJECTS_FAILURE,
    payload: { error },
  };
}

export function fetchOldProjects(filters) {
  return ((dispatch) => {
    dispatch({ type: FETCH_OLD_PROJECTS });
    const sessionToken = localStorage.user ? JSON.parse(localStorage.user).sessionToken : null;
    return Parse.Cloud.run('fetchProjects', { ...filters }, { sessionToken }).then((results) => {
      dispatch(fetchOldProjectsSuccess(JSON.parse(JSON.stringify(results))));
    }, (error) => {
      dispatch(fetchOldProjectsFailure(JSON.parse(JSON.stringify(error))));
    });
  });
}

export function resetProjectsPage() {
  return {
    type: RESET_PROJECTS_PAGE,
  };
}

export function fetchProjectSuccess(project) {
  return {
    type: FETCH_PROJECT_SUCCESS,
    payload: { project },
  };
}

export function fetchProjectFailure(error) {
  return {
    type: FETCH_PROJECT_FAILURE,
    payload: { error },
  };
}

export function fetchProject(slug) {
  return ((dispatch) => {
    dispatch({ type: FETCH_PROJECT });
    const sessionToken = localStorage.user ? JSON.parse(localStorage.user).sessionToken : null;
    return Parse.Cloud.run('fetchProject', { slug }, { sessionToken }).then((results) => {
      dispatch(fetchProjectSuccess(JSON.parse(JSON.stringify(results))));
    }, (error) => {
      dispatch(fetchProjectFailure(JSON.parse(JSON.stringify(error))));
    });
  });
}

export function resetProjectPage() {
  return {
    type: RESET_PROJECT_PAGE,
  };
}
