import {
  CHANGE_LOST_PASSWORD_FIELD,
  RESET_LOST_PASSWORD_FORM,
  REQUEST_RESET_PASSWORD,
  REQUEST_RESET_PASSWORD_SUCCESS,
  REQUEST_RESET_PASSWORD_FAILURE,
} from '../actions/types';

const INITIAL_STATE = {
  email: '',
  isLoading: false,
  success: null,
  error: null,
  submit: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CHANGE_LOST_PASSWORD_FIELD:
      return {
        ...state,
        [action.payload.prop]: action.payload.value,
      };
    case RESET_LOST_PASSWORD_FORM:
      return INITIAL_STATE;
    case REQUEST_RESET_PASSWORD:
      return {
        ...state,
        error: null,
        isLoading: true,
        submit: true,
      };
    case REQUEST_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        email: '',
        success: true,
        error: null,
        isLoading: false,
      };
    case REQUEST_RESET_PASSWORD_FAILURE:
      return {
        ...state,
        ...action.payload,
        success: null,
        isLoading: false,
      };
    default:
      return state;
  }
};
