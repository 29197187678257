import React from 'react';
import { Route, Switch } from 'react-router-dom';
import About from '../components/About';
import Account from '../components/Account';
import Blog from '../components/Blog';
import Category from '../components/Category';
import Checkout from '../components/Checkout';
import Contact from '../components/Contact';
import Destinations from '../components/Destinations';
import Destination from '../components/Destination';
import FAQ from '../components/FAQ';
import Help from '../components/Help';
import Home from '../components/Home';
import HowItWorks from '../components/HowItWorks';
import LostPassword from '../components/LostPassword';
import NotFound from '../components/NotFound';
import Page from '../components/Page';
import Partners from '../components/Partners';
import Post from '../components/Post';
import Press from '../components/Press';
import Project from '../components/Project';
import Projects from '../components/Projects';
import Request from '../components/Request';
import Thinking from '../components/Thinking';
import Trip from '../components/Trip';
import Trips from '../components/Trips';

const locales = [
  {
    name: 'pt_BR',
    routes: [
      {
        path: '/',
        component: Home,
        exact: true,
      },
      {
        path: '/quem-somos',
        component: About,
      },
      {
        path: '/conta',
        component: Account,
      },
      {
        path: '/blog',
        component: Blog,
        exact: true,
      },
      {
        path: '/blog/:slug',
        component: Post,
      },
      {
        path: '/como-funciona',
        component: HowItWorks,
      },
      {
        path: '/contato',
        component: Contact,
      },
      {
        path: '/destinos',
        component: Destinations,
        exact: true,
      },
      {
        path: '/destinos/:slug',
        component: Destination,
      },
      {
        path: '/faq',
        component: Page,
      },
      {
        path: '/finalizar-compra/:id',
        component: Checkout,
      },
      {
        path: '/duvidas',
        component: Help,
      },
      {
        path: '/perdi-minha-senha',
        component: LostPassword,
      },
      {
        path: '/projetos-vv/:slug',
        component: Category,
      },
      {
        path: '/parceiros',
        component: Partners,
      },
      {
        path: '/imprensa',
        component: Press,
      },
      {
        path: '/projetos',
        component: Projects,
        exact: true,
      },
      {
        path: '/projetos/:slug',
        component: Project,
      },
      {
        path: '/inscricao/:slug',
        component: Request,
      },
      {
        path: '/vv-thinking',
        component: Thinking,
      },
      {
        path: '/viagens',
        component: Trips,
        exact: true,
      },
      {
        path: '/viagens/:objectId',
        component: Trip,
      },
    ],
  },
  {
    name: 'en_US',
    routes: [
      {
        path: '/:locale/',
        component: Home,
        exact: true,
      },
      {
        path: '/:locale/vv',
        component: About,
      },
      {
        path: '/:locale/account',
        component: Account,
      },
      {
        path: '/:locale/blog',
        component: Blog,
        exact: true,
      },
      {
        path: '/:locale/blog/:slug',
        component: Post,
      },
      {
        path: '/:locale/help',
        component: Help,
      },
      {
        path: '/:locale/how-it-works',
        component: HowItWorks,
      },
      {
        path: '/:locale/lost-my-password',
        component: LostPassword,
      },
      {
        path: '/:locale/vv-projects/:slug',
        component: Category,
      },
      {
        path: '/:locale/contact',
        component: Contact,
      },
      {
        path: '/:locale/destinations',
        component: Destinations,
        exact: true,
      },
      {
        path: '/:locale/destinations/:slug',
        component: Destination,
      },
      {
        path: '/:locale/faq',
        component: FAQ,
      },
      {
        path: '/:locale/checkout/:id',
        component: Checkout,
      },
      {
        path: '/:locale/partners',
        component: Partners,
      },
      {
        path: '/:locale/press',
        component: Press,
      },
      {
        path: '/:locale/projects',
        component: Projects,
        exact: true,
      },
      {
        path: '/:locale/projects/:slug',
        component: Project,
      },
      {
        path: '/:locale/join/:slug',
        component: Request,
      },
      {
        path: '/:locale/vv-thinking',
        component: Thinking,
      },
      {
        path: '/:locale/trips',
        component: Trips,
        exact: true,
      },
      {
        path: '/:locale/trips/:slug',
        component: Trip,
      },
    ],
  },
];

function Routes() {
  return (
    <Switch>
      {
        locales.map(locale => (
          locale.routes.map(route => (
            <Route
              key={route.path}
              exact={route.exact}
              path={route.path}
              component={route.component}
            />
          ))
        ))
      }
      <Route path="*" component={NotFound} />
    </Switch>
  );
}

export default Routes;
