import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';

const localization = {
  pt_BR: {
    1: 'Tudo que você queria saber sobre a VV e não tinha coragem de perguntar… 😉',
    2: 'O que é Volunteer Vacations?',
    3: 'A oportunidade fazer uma viagem de férias tradicional aliada a oportunidade de ajudar diretamente um projeto social de sua escolha.',
    4: 'E porque eu faria “férias voluntárias”?',
    5: 'O mundo está cada vez mais percebendo a importância e urgência de atuarmos em questões humanitárias, sociais e de sustentabilidade. Estudos recentes comprovaram os enormes benefícios do trabalho voluntário não só para quem recebe a ajuda mas para os próprios voluntários, também!',
    6: 'Por que escolher a VV?',
    7: 'Porque temos conhecimento específico de: turismo voluntário, destinos fora do padrão, roteiros de viagens de aventura, ajuda humanitária, projetos sociais e estabelecemos parcerias com as mais relevantes e confiáveis ONGs desse setor.',
    8: 'Mas eu tenho que pagar para ser voluntário?',
    9: 'Na verdade, o trabalho em si é voluntário (ou seja, de graça). A taxa paga corresponde ao nosso serviço de curadoria das ONGs parceiras, de consultoria, os preparativos que realizamos referentes a sua viagem, nossa mediação junto às organizações, o suporte local, contato direto com nossa equipe e uma doação que é definida de forma independente pelas próprias ONGs. Esta taxa ajuda a manter estas ONGs e seu trabalho de extrema importância e valor. Ah, e sua camisa Volunteer Vacations e o Book Guia de Viagens da VV!',
    10: 'Vem cá: é seguro, mesmo?',
    11: 'Sim. Tanto quanto qualquer outra viagem de férias nos moldes tradicionais. Nossas ONGs parceiras são mundialmente conhecidas por sua reputação e desenvolvem um programa que tem como uma de suas principais preocupações a segurança de seu corpo de voluntários internacionais. Que, no fim das contas, são também os seus embaixadores e de suas causas mundo a fora.',
    12: 'E como eu pago?',
    13: 'A gente usa o renomado e reconhecido sistema PagSeguro onde você pode escolher pagar por boleto ou cartão. Tudo bem claro e com total segurança como você pode ver ',
    14: 'Pra que ser voluntário no exterior?',
    15: 'Porque o mundo todo tem problemas e precisa de pessoas dispostas a ajudar. Sejam as crianças abandonadas no Haiti, as tartarugas ameaçadas de extinção da Costa Rica ou os órfãos da África. Acreditamos que toda forma de vida merece ser preservada, respeitada e cuidada, independente de nacionalidade, cor, religião, idade. “Faça o bem, não importa a quem.”',
    16: 'Mas se eu quiser posso ser voluntário no Brasil?',
    17: 'Claro, estamos baseados no Rio de Janeiro e temos estreitas relações com ONGs locais para atender o visitante com perfil humanitário. Vai lá na nossa página inicial e clique no mapa em Brasil. Ou clique logo aqui que é mais fácil.',
    18: 'Posso ser voluntário com um parente ou grupo de amigos?',
    19: 'Sim! E essa é uma das maneiras mais emocionantes e divertidas de viajar, conhecer culturas diferentes e se divertir fazendo uma diferença. Uma experiência para a vida toda!',
    20: 'Preciso de qualificações específicas para ser voluntário?',
    21: 'Não é necessária nenhuma formação específica para contribuir com sua boa vontade e força de trabalho voluntário. Mas procuramos,  junto com você, identificar e sugerir as opções que sejam mais adequadas ao seu perfil tornando a experiência melhor e mais proveitosa para todos envolvidos.',
    22: 'Fiquei apaixonado(a) pelo projeto que participei! Posso fazer uma doação para eles?',
    23: 'Até mais de uma! 🙂 Da forma mais transparente possível, facilitamos o canal entre você e a ONG de sua preferência, permitindo que se estreitem os laços, o acompanhamento e que sejam feitas doações dentro dos moldes desejados pelo voluntário e possibilitados pela organização.',
    24: 'Eu vou trabalhar com outros voluntários de outros países ou do Brasil?',
    25: 'Sim! É uma das partes mais interessantes da experiência. Fazer novos amigos, conhecer a cultura deles e criar laços para a vida toda durante uma experiência única e extremamente significante.',
    26: 'Eu preciso de Seguro de viagem para fazer minhas Volunteer Vactions?',
    27: 'Sim. Você pode escolher a agência de seguro de sua preferência ou pode fazer uma cotação com nossa agência parceira (Bruck Seguros).',
    28: 'Com quanto tempo de antecedência eu devo reservar e pagar a minha viagem?',
    29: 'Nós não temos um prazo para as inscrições e registros, mas o nosso conselho é que você reserve e pague a sua viagem assim que você tiver as suas datas definidas. Isso vai permitir que possamos lhe ajudar a melhor preparar a sua viagem ao exterior, colocá-lo em contato com os nossos parceiros locais, lhe enviar um documento informativo preparado por nós. As vagas em cada programa são limitadas, dependendo do destino e da ONG não é sempre que se tem a data que deseja. Quanto antes entrar em contato conosco melhor será, mas de modo geral recomendamos iniciar o planejamento da sua viagem dois meses antes.',
    30: 'Estava pensando em ficar hospedado na própria ONG para aproveitar mais a experiência. Mas como é a acomodação e a alimentação?',
    31: 'Cada ONG tem suas próprias especificações de acomodação e alimentação que são passadas em detalhe para o voluntário interessado.',
    32: 'Eu preciso saber falar a língua local? Meu suaíli (língua do Quênia) está meio enferrujado…',
    33: 'Cada destino e ONG tem suas necessidades específicas, mas geralmente o inglês é suficiente. Além, é claro, da linguagem universal da mímica e da boa vontade.',
    34: 'Além de trabalhar como voluntário eu poderei conhecer os pontos turísticos?',
    35: 'Claro! Faz parte da experiência. Diferente dos intercâmbios, a Volunteer Vacation é o casamento perfeito entre uma viagem tradicional para conhecer e desfrutar de uma cultura diferente e  a possibilidade de ajudar a fazer do mundo um lugar melhor.',
    36: 'Eu não sou brasileiro mas gostaria de trabalhar como voluntário no Brasil, é possível?',
    37: 'Yes, my friend. You can check our brazilian partners!',
    38: 'Eu preciso de visto para o país que vou viajar?',
    39: 'Depende. Cada país tem suas especificações diplomáticas que serão passadas para você por nossa equipe.',
    40: 'Qual a idade máxima e mínima para poder ser voluntário?',
    41: 'Todas as pessoas são bem vindas em todos os programas. A idade permitida depende do trabalho voluntário escolhido, mas para pessoas menores de 18 anos temos pacotes em grupo (a partir de duas pessoas) desde que sempre acompanhadas de um adulto.',
    42: 'Preciso tomar vacina?',
    43: 'Para alguns destinos, sim. Notificamos qual e em que casos ela se faz necessária.',
    44: 'O que eu vou fazer?',
    45: 'O que você quiser! Trabalho físico, intelectual, afetivo… Nossos parceiros precisam de ajuda nas mais diferentes áreas, mas ajudamos você a identificar a que estaria mais de acordo com seu perfil.',
    46: 'A VV vai continuar em contato comigo me mantendo informado sobre a organização que ajudei?',
    47: 'Mantemos contato constante com as nossas ONGs parceiras. Nosso blog está sempre sendo atualizado com novidades de nossos amigos humanitários de todo o mundo. Apresentamos updates frequentes e algumas das experiências mais legais do pessoal que participou de trabalhos voluntários mundo a fora.',
    48: 'O VV oferece algum certificado de voluntariado após a viagem?',
    49: 'Sim! Quando retornar de sua experiência como voluntário entregamos um certificado de bons serviços prestados à humanidade e ao planeta. 😉',
    50: 'AQUI.',
    51: 'https://pagseguro.uol.com.br/sobre_o_pagseguro.jhtml#rmcl',
  },
  en_US: {
    1: 'Everything you wanted to know about VV but were afraid to ask… 😉',
    2: 'What is Volunteer Vacations?',
    3: 'The opportunity to go on a traditional holiday trip combined with the opportunity to directly help a social project of your choice.',
    4: 'Why would I go on a “volunteer vacation”?',
    5: 'The world is increasingly realizing the importance and urgency of taking action in humanitarian, social and sustainability issues. Recent studies show the enormous benefits of volunteer work not just for those who receive aid but for the volunteers themselves!',
    6: 'Why choose VV?',
    7: 'Because we are experts in: volunteer tourism, off the beaten track destinations, creating travel itineraries for trips aimed toward adventure, humanitarian aid and social projects. We also have partnerships with the most relevant and reliable NGOs in this area.',
    8: 'But do I have to pay in order to be a volunteer?',
    9: 'Actually, the work itself is voluntary (that is, free). The fees we charge relate to curatorship services in selecting our NGO partners, consultancy services, the preparations we make for your trip, our mediation with the organizations involved in the trip, local support, our team’s availability for direct contact and a donation defined by the NGOs themselves. These fees help to sustain the NGOs and their extremely important work. Oh, and your Volunteer Vacations t- shirt and VV’s Travel Guidebook!',
    10: 'Is this really safe?',
    11: 'Yes. As much as any traditional holiday trip. Our NGO partners have a global reputation and develop programs which are concerned foremost with the safety of their international volunteers. After all, the volunteers are also worldwide ambassadors for them and their causes.',
    12: 'How do I pay?',
    13: 'We use the well-known and highly regarded PayPal system where you can pay through credit card or payment slip. Everything is very transparent and completely secure as you can see ',
    14: 'Why be a volunteer abroad?',
    15: 'Because the whole world has problems and needs people willing to help. Be it abandoned children in Haiti, endangered turtles in Costa Rica or orphans in Africa. We believe that every form of life deserves to be preserved, respected and cared for, regardless of nationality, skin color, religion or age. “Do good for its own sake.”',
    16: 'Can I be a volunteer in Brazil?',
    17: 'Of course, our headquarters are in Rio de Janeiro and we have close bonds with local NGOs in order to meet the demands of visitors with a humanitarian mindset.',
    18: 'Can I be a volunteer with a relative or a group of friends?',
    19: 'Yes! That’s one of the most exciting and fun ways to travel, discovering different cultures and having fun while making a difference - an experience you’ll never forget!',
    20: 'Do I need specific qualifications in order to be a volunteer?',
    21: 'You don’t need any specific educational background to pitch in with your good will and your hard work. But we do look to – with your help - identify and suggest the options that fit your profile best, making the overall experience better and more fruitful for all those involved.',
    22: 'I’m in love with the project I participated in! Can I make a donation?',
    23: 'More than one! :) We facilitate the channels of communication between you and the NGO of your liking in the most transparent way possible, allowing for the strengthening of bonds and for follow-ups and donations to be made in the form preferred by the organization.',
    24: 'Will I be working with volunteers from other countries?',
    25: 'Yes! It’s one of the most interesting parts of the whole experience. Making new friends, getting to know their culture and creating lifetime bonds in a great unique experience.',
    26: 'Do I need travel insurance to go on a Volunteer Vacation?',
    27: 'Yes. You can arrange it yourself with an insurance company of your choice or you can ask for a quotation from our partner (Bruck Seguros).',
    28: 'How long in advance should I book and pay for my trip?',
    29: 'While we don’t have a deadline for booking and registration, our advice is that you book and pay for you trip as soon as you have set a date for your trip. This will allow us to better help you prepare for your trip abroad, put you in touch with our local partners and send you an informative document prepared by us. The vacancies at each program are limited, depending on the destination and NGO the desired date won’t always be available. The more in advance you contact us the better, but overall we recommend you to start planning for your trip two months prior.',
    30: 'I was thinking of staying at the NGO itself, to get the most out of the experience. How is the food and accommodation?',
    31: 'Each NGO has its own particularities regarding food and accommodation which will be relayed in detail to the volunteer.',
    32: 'Do I need to speak the local language? My Swahili (the language of Kenya) is a little rusty…',
    33: 'Each destination and NGO has its own specific needs, but usually English is enough. Besides, of course, the universal language of gestures and good will.',
    34: 'Besides working as a volunteer will I be able to visit the tourist attractions?',
    35: 'Of course! It’s part of the experience. Unlike exchange programs, Volunteer Vacation is the perfect marriage between a traditional trip to discover and enjoy a culture and the possibility of helping to make the world a better place.',
    36: 'I’m not Brazilian but I would like to work as a volunteer in Brazil, is that possible?',
    37: 'Yes, my friend. You can check our brazilian partners!',
    38: 'Do I need a visa for the country I’m travelling to?',
    39: 'It depends. Each country has its diplomatic specifications which will be relayed to you by our team.',
    40: 'What’s the minimum and maximum age to be a volunteer?',
    41: 'Everyone is welcome on all programs. The required age depends on the chosen volunteer work activity, but for persons under 18 we have group packages (starting at two persons) available, as long as they are accompanied by an adult.',
    42: 'Do I need certain vaccines?',
    43: 'For some destinations, yes. We’ll let you know which vaccine is necessary and in which cases.',
    44: 'What will I be doing?',
    45: 'Whatever you want! Physical, intellectual, emotional work… Our partners need help in several diverse areas, but we’ll help you identify which would fit your profile best.',
    46: 'Will VV continue to inform me about the organization I’ve helped?',
    47: 'We are in constant communication with our NGO partners.  Our blog is always being updated with news from our humanitarian friends from all over the world. We have frequent updates and also showcase some of the cooler experiences from the people that have participated in volunteer work worldwide.',
    // 48: 'Does VV offer any volunteer certificate after the trip?',
    // 49: 'Yes! When you return from your experience as a volunteer we deliver a certificate of good services rendered to mankind and the planet.',
    50: 'HERE.',
    51: 'http://paypal.com',
  },
};

function FAQ(props) {
  const { match } = props;
  const locale = match.params.locale ? match.params.locale : 'pt_BR';
  return (
    <div style={{ marginTop: 60 }}>
      <div className="cover">
        <img className="img-fluid" src="/assets/cover-faq.jpg" alt="cover" />
      </div>
      <Container style={{ paddingTop: 40 }}>
        <h1>FAQ</h1>
        <h3>{localization[locale]['1']}</h3>
        <h5>{localization[locale]['2']}</h5>
        {localization[locale]['3']}<br /><br />
        <h5>{localization[locale]['4']}</h5>
        {localization[locale]['5']}<br /><br />
        <h5>{localization[locale]['6']}</h5>
        {localization[locale]['7']}<br /><br />
        <h5>{localization[locale]['8']}</h5>
        {localization[locale]['9']}<br /><br />
        <h5>{localization[locale]['10']}</h5>
        {localization[locale]['11']}<br /><br />
        <h5>{localization[locale]['12']}</h5>
        {localization[locale]['13']}
        <a
          href={localization[locale]['51']}
          target="_blank"
          rel="noopener noreferrer"
        >
          {localization[locale]['50']}
        </a><br /><br />
        <h5>{localization[locale]['14']}</h5>
        {localization[locale]['15']}<br /><br />
        <h5>{localization[locale]['16']}</h5>
        {localization[locale]['17']}<br /><br />
        <h5>{localization[locale]['18']}</h5>
        {localization[locale]['19']}<br /><br />
        <h5>{localization[locale]['20']}</h5>
        {localization[locale]['21']}<br /><br />
        <h5>{localization[locale]['22']}</h5>
        {localization[locale]['23']}<br /><br />
        <h5>{localization[locale]['24']}</h5>
        {localization[locale]['25']}<br /><br />
        <h5>{localization[locale]['26']}</h5>
        {localization[locale]['27']}<br /><br />
        <h5>{localization[locale]['28']}</h5>
        {localization[locale]['29']}<br /><br />
        <h5>{localization[locale]['30']}</h5>
        {localization[locale]['31']}<br /><br />
        <h5>{localization[locale]['32']}</h5>
        {localization[locale]['33']}<br /><br />
        <h5>{localization[locale]['34']}</h5>
        {localization[locale]['35']}<br /><br />
        <h5>{localization[locale]['36']}</h5>
        {localization[locale]['37']}<br /><br />
        <h5>{localization[locale]['38']}</h5>
        {localization[locale]['39']}<br /><br />
        <h5>{localization[locale]['40']}</h5>
        {localization[locale]['41']}<br /><br />
        <h5>{localization[locale]['42']}</h5>
        {localization[locale]['43']}<br /><br />
        <h5>{localization[locale]['44']}</h5>
        {localization[locale]['45']}<br /><br />
        <h5>{localization[locale]['46']}</h5>
        {localization[locale]['47']}<br /><br />
        <h5>{localization[locale]['48']}</h5>
        {localization[locale]['49']}
      </Container>
    </div>
  );
}

FAQ.propTypes = {
  match: PropTypes.shape({}),
};

export default FAQ;
