import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Col, Form, FormGroup, FormFeedback, Label, Input, Row } from 'reactstrap';
import MaskedTextInput from 'react-text-mask';
import NumberFormat from 'react-number-format';
import DatePicker from 'react-datepicker';
import { changeUserField, signUp } from './../actions';

const localization = {
  pt_BR: {
    1: 'Esse campo é obrigatório',
    2: 'Nome',
    3: 'Sobrenome',
    4: 'Telefone',
    5: 'CPF',
    6: 'RG',
    7: 'Data de Nascimento',
    8: 'Nacionalidade',
    9: 'Brasileira',
    10: 'Outra',
    11: 'Salvar',
  },
  en_US: {
    1: 'This field is required',
    2: 'First Name',
    3: 'Last Name',
    4: 'Phone',
    5: 'CPF (Brazilians Citizens only)',
    6: 'Identity',
    7: 'Birth Date',
    8: 'Nationality',
    9: 'Brazilian',
    10: 'Other',
    11: 'Save',
  },
};

class AccountForm extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onChangeDate = this.onChangeDate.bind(this);
  }
  onChange(e) {
    this.props.changeUserField({ prop: e.target.id, value: e.target.value });
  }
  onChangeDate(date) {
    this.props.changeUserField({ prop: 'birthDate', value: date });
  }
  onClick(e) {
    e.preventDefault();
    window.scrollTo(0, 0);
    const {
      firstName,
      lastName,
      email,
      phoneNumber,
      cpf,
      identity,
      birthDate,
      nationality,
    } = this.props;
    this.props.signUp(
      firstName,
      lastName,
      email,
      null,
      phoneNumber,
      cpf,
      identity,
      birthDate,
      nationality,
    );
  }
  render() {
    const {
      match,
      firstName,
      lastName,
      email,
      phoneNumber,
      cpf,
      identity,
      birthDate,
      nationality,
      submit,
      isLoading,
    } = this.props;
    const locale = match.params.locale ? match.params.locale : 'pt_BR';
    return (
      <Form style={{ maxWidth: 640 }}>
        <Row>
          <Col>
            <FormGroup color={submit === true && !firstName ? 'danger' : null}>
              <Label for="firstName">{localization[locale][2]}</Label>
              <Input id="firstName" type="text" value={firstName} placeholder="Nome" onChange={this.onChange} />
              {submit === true &&
                <FormFeedback hidden={firstName}>{localization[locale][1]}</FormFeedback>
              }
            </FormGroup>
          </Col>
          <Col>
            <FormGroup color={submit === true && !lastName ? 'danger' : null}>
              <Label for="lastName">{localization[locale][3]}</Label>
              <Input id="lastName" type="text" value={lastName} placeholder="Sobrenome" onChange={this.onChange} />
              {submit === true &&
                <FormFeedback hidden={lastName}>{localization[locale][1]}</FormFeedback>
              }
            </FormGroup>
          </Col>
        </Row>
        <FormGroup color={submit === true && !email ? 'danger' : null}>
          <Label for="email">E-mail</Label>
          <Input id="email" type="email" value={email} placeholder="nome@exemplo.com" onChange={this.onChange} />
          {submit === true &&
            <FormFeedback hidden={email}>{localization[locale][1]}</FormFeedback>
          }
        </FormGroup>
        <FormGroup color={submit === true && !phoneNumber ? 'danger' : null}>
          <Label for="phoneNumber">{localization[locale][4]}</Label>
          <MaskedTextInput
            id="phoneNumber"
            mask={['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            className="form-control"
            guide
            value={phoneNumber}
            placeholder="(xx) xxxxx-xxxx"
            onChange={this.onChange}
          />
          {submit === true &&
            <FormFeedback hidden={phoneNumber}>{localization[locale][1]}</FormFeedback>
          }
        </FormGroup>
        <Row>
          <Col style={{ marginRight: 5 }}>
            <FormGroup>
              <Label for="cpf">{localization[locale][5]}</Label>
              <NumberFormat
                id="identity"
                className="form-control"
                value={cpf}
                format="###.###.###-##"
                thousandSeparator="."
                decimalSeparator=","
                fixedDecimalScale
                decimalScale={0}
                allowNegative={false}
                onValueChange={(values) => {
                  const { value } = values;
                  this.props.changeUserField({ prop: 'cpf', value });
                }}
              />
            </FormGroup>
          </Col>
          <Col style={{ marginRight: 5 }}>
            <FormGroup>
              <Label for="identity">{localization[locale][6]}</Label>
              <Input id="identity" type="text" value={identity} onChange={this.onChange} />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label for="birthDate">{localization[locale][7]}</Label>
              <DatePicker
                id="birthDate"
                className="form-control"
                selected={birthDate}
                dateFormat="DD/MM/YYYY"
                onChange={this.onChangeDate}
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label for="nationality">{localization[locale][8]}</Label>
              <FormGroup check>
                <Row>
                  <Col>
                    <Label check>
                      <Input
                        type="radio"
                        name="nationality_type"
                        checked={nationality === 'brasileira'}
                        onChange={() => {
                          this.props.changeUserField({ prop: 'nationality', value: 'brasileira' });
                        }}
                      />{' '}
                      {localization[locale][9]}
                    </Label>
                  </Col>
                </Row>
                <Row>
                  <Col style={{ marginLeft: 15 }} xs="auto">
                    <Label check>
                      <Input
                        type="radio"
                        name="nationality_type"
                        checked={nationality !== 'brasileira'}
                        onChange={() => {
                          this.props.changeUserField({ prop: 'nationality', value: '' });
                        }}
                      />{' '}
                      {localization[locale][10]}
                    </Label>
                  </Col>
                  <Col>
                    {nationality !== 'brasileira' &&
                      <Input style={{ marginTop: 5 }} id="nationality" type="nationality" value={nationality} onChange={this.onChange} />
                    }
                  </Col>
                </Row>
              </FormGroup>
            </FormGroup>
          </Col>
        </Row>
        <Button disabled={isLoading} color="primary" onClick={this.onClick}>{localization[locale][11]}</Button>
      </Form>
    );
  }
}

AccountForm.propTypes = {
  match: PropTypes.shape({}),
  changeUserField: PropTypes.func,
  signUp: PropTypes.func,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  phoneNumber: PropTypes.string,
  cpf: PropTypes.string,
  identity: PropTypes.string,
  birthDate: PropTypes.shape({}),
  nationality: PropTypes.string,
  submit: PropTypes.bool,
  isLoading: PropTypes.bool,
};

function mapStateToProps({ userReducer }) {
  return userReducer;
}
function mapDispatchToProps(dispatch) {
  return {
    changeUserField: (field) => {
      dispatch(changeUserField(field));
    },
    signUp: (
      firstName,
      lastName,
      mail,
      password,
      phoneNumber,
      cpf,
      identity,
      birthDate,
      nationality,
    ) => {
      dispatch(signUp(
        firstName,
        lastName,
        mail,
        password,
        phoneNumber,
        cpf,
        identity,
        birthDate,
        nationality,
      ));
    },
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountForm));
