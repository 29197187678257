import { Parse } from 'parse';
import {
  FETCH_TAGS,
  FETCH_TAGS_SUCCESS,
  FETCH_TAGS_FAILURE,
} from './types';

export function fetchTagsSuccess(tags) {
  return {
    type: FETCH_TAGS_SUCCESS,
    payload: { tags },
  };
}

export function fetchTagsFailure(error) {
  return {
    type: FETCH_TAGS_FAILURE,
    payload: { error },
  };
}

export function fetchTags() {
  return ((dispatch) => {
    dispatch({ type: FETCH_TAGS });
    return Parse.Cloud.run('fetchTags').then((results) => {
      dispatch(fetchTagsSuccess(JSON.parse(JSON.stringify(results))));
    }, (error) => {
      dispatch(fetchTagsFailure(JSON.parse(JSON.stringify(error))));
    });
  });
}
