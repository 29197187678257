import { combineReducers } from 'redux';
import CategoryReducer from './CategoryReducer';
import CategoriesReducer from './CategoriesReducer';
import CheckoutReducer from './CheckoutReducer';
import ContactReducer from './ContactReducer';
import DestinationReducer from './DestinationReducer';
import DestinationsReducer from './DestinationsReducer';
import FeaturedContentsReducer from './FeaturedContentsReducer';
import LogInModalReducer from './LogInModalReducer';
import LostPasswordReducer from './LostPasswordReducer';
import MediaModalReducer from './MediaModalReducer';
import OrderReducer from './OrderReducer';
import OrdersReducer from './OrdersReducer';
import PageReducer from './PageReducer';
import PartnerReducer from './PartnerReducer';
import PressReducer from './PressReducer';
import PostReducer from './PostReducer';
import ProjectReducer from './ProjectReducer';
import ProjectsReducer from './ProjectsReducer';
import SignUpModalReducer from './SignUpModalReducer';
import UserReducer from './UserReducer';
import TagsReducer from './TagsReducer';

export default combineReducers({
  categoryReducer: CategoryReducer,
  categoriesReducer: CategoriesReducer,
  checkoutReducer: CheckoutReducer,
  contactReducer: ContactReducer,
  destinationReducer: DestinationReducer,
  destinationsReducer: DestinationsReducer,
  featuredContentsReducer: FeaturedContentsReducer,
  logInModalReducer: LogInModalReducer,
  lostPasswordReducer: LostPasswordReducer,
  mediaModalReducer: MediaModalReducer,
  orderReducer: OrderReducer,
  ordersReducer: OrdersReducer,
  pageReducer: PageReducer,
  partnerReducer: PartnerReducer,
  pressReducer: PressReducer,
  postReducer: PostReducer,
  projectReducer: ProjectReducer,
  projectsReducer: ProjectsReducer,
  signUpModalReducer: SignUpModalReducer,
  userReducer: UserReducer,
  tagsReducer: TagsReducer,
});
