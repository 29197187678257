import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Alert, Button, Col, Container, Row } from 'reactstrap';
import ReactLoading from 'react-loading';
import moment from 'moment-timezone';
import { fetchProject, resetProjectPage, resetOrderPage, saveOrder, showSignUpModal } from './../actions';
import RequestForm from './RequestForm';

const localization = {
  pt_BR: {
    1: 'Informações',
    2: 'Requisitos',
    3: 'Idade',
    4: 'Data e Hora de Chegada',
    5: 'Data e Horário de Partida',
    6: 'Formulário de Inscrição',
    7: 'Recebemos o seu pedido de férias voluntárias! Vamos analisar o seu formulário e consultar nossa ONG parceira sobre a disponibilidade nas suas datas de interesse. Em breve, entre dois e cinco dias úteis, a gente te retorna! Estamos ansiosos pra te ver fazendo a diferença e espalhando o bem pelo mundo com a VV! Qualquer dúvida, só entra em contato através do e-mail vv@volunteervacations.com.br. :)',
    '/projetos': '/projetos',
  },
  en_US: {
    1: 'Information',
    2: 'Requirements',
    3: 'Age',
    4: 'Date and Time of Arrival',
    5: 'Date and Time of Departure',
    6: 'Application Form',
    7: 'We have received your request for a voluntary holiday! Let`s review your form and consult our partner NGO about availability on your dates of interest. Soon, between two and five business days, we will return! We look forward to seeing you making a difference and spreading the good around the world with VV! Any questions, just contact us at vv@volunteervacations.com.br. :)',
    '/projetos': '/projects',
  },
};

class Request extends Component {
  componentWillMount() {
    const { match } = this.props;
    this.props.resetProjectPage();
    this.props.resetOrderPage();
    this.props.fetchProject(match.params.slug);
  }
  onClick(e) {
    e.preventDefault();
    const { user, project } = this.props;
    if (!user) {
      this.props.showSignUpModal();
    } else {
      this.props.saveOrder(project, project.amount[0]);
    }
  }
  render() {
    const {
      match,
      history,
      user,
      project,
      order,
      isLoadingProject,
      isLoading,
      success,
      error,
    } = this.props;
    const locale = match.params.locale ? match.params.locale : 'pt_BR';
    moment.locale(locale);
    if (!user) {
      history.push('/');
    }
    return (
      <Container>
        {isLoading === true &&
          <ReactLoading delay={0} className="spin" type="spin" color="#ccc" height={24} width={24} />
        }
        {isLoadingProject === false && project &&
          <Row>
            <Col xs="12" sm="12" md="6">
              {project &&
                <div>
                  <h1>{project[locale].name}</h1>
                  <h5>{project.destination[locale].name}</h5>
                  <Row>
                    <Col>
                      {
                        project.tags.map(tag => (
                          <Button
                            size="sm"
                            color="primary"
                            key={tag}
                            style={{ margin: 2.5 }}
                            onClick={(e) => {
                              e.preventDefault();
                              history.push(`${locale === 'pt_BR' ? '' : locale}${localization[locale]['/projetos']}?tag=${tag}`);
                            }}
                          >
                            {tag}
                          </Button>
                        ))
                      }
                    </Col>
                  </Row>
                  <hr />
                  <h3>{project[locale].title}</h3>
                  <img alt={project[locale].name} style={{ marginBottom: 10 }} className="img-fluid" src={project.cover.largeFile.url} />
                  <h3>{localization[locale][1]}</h3>
                  {project.requirements &&
                    <div>
                      <h5>{localization[locale][2]}</h5>
                      <div className="linebreak">
                        {project[locale].requirements}
                      </div>
                      <br />
                    </div>
                  }
                  {project.fromAge &&
                    <div style={{ marginTop: 10 }}>
                      <h5>{localization[locale][3]}</h5>
                      {`${project.fromAge} anos`}
                      <br />
                      <br />
                    </div>
                  }
                  {project.startDate && project.timeZone &&
                    <div>
                      <h5>{localization[locale][4]}</h5>
                      {moment(project.startDate).tz(project.timeZone).format('DD, MMMM YYYY, HH:mm')}
                      <br />
                      <br />
                    </div>
                  }
                  {project.endDate && project.timeZone &&
                    <div>
                      <h5>{localization[locale][5]}</h5>
                      {moment(project.endDate).tz(project.timeZone).format('DD, MMMM YYYY, HH:mm')}
                      <br />
                      <br />
                    </div>
                  }
                </div>
              }
            </Col>
            <Col>
              {success &&
                <Alert color="success">
                  {localization[locale][7]}
                </Alert>
              }
              {error &&
                <Alert color="danger">
                  {`'Oops! ${error.message}`}
                </Alert>
              }
              {!order &&
                <div>
                  <h3>{localization[locale][6]}</h3>
                  <RequestForm project={project} />
                </div>
              }
            </Col>
          </Row>
        }
      </Container>
    );
  }
}

Request.propTypes = {
  match: PropTypes.shape({}),
  history: PropTypes.shape({}),
  fetchProject: PropTypes.func,
  resetProjectPage: PropTypes.func,
  resetOrderPage: PropTypes.func,
  showSignUpModal: PropTypes.func,
  saveOrder: PropTypes.func,
  project: PropTypes.shape({}),
  order: PropTypes.shape({}),
  user: PropTypes.shape({}),
  isLoadingProject: PropTypes.bool,
  isLoading: PropTypes.bool,
  success: PropTypes.bool,
  error: PropTypes.shape({}),
};

function mapStateToProps({ userReducer, projectReducer, orderReducer }) {
  return {
    ...userReducer,
    ...projectReducer,
    ...orderReducer,
    isLoadingProject: projectReducer.isLoading === true,
    isLoading: projectReducer.isLoading === true || orderReducer.isLoading === true,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    fetchProject: (slug) => {
      dispatch(fetchProject(slug));
    },
    resetProjectPage: () => {
      dispatch(resetProjectPage());
    },
    resetOrderPage: () => {
      dispatch(resetOrderPage());
    },
    saveOrder: (project, amount) => {
      dispatch(saveOrder(project, amount));
    },
    showSignUpModal: () => {
      dispatch(showSignUpModal());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Request);
