import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Alert, Col, Container, Row } from 'reactstrap';
import ReactLoading from 'react-loading';
import { fetchOrders } from './../actions';
import TripRow from './TripRow';

const localization = {
  pt_BR: {
    1: 'Minhas experiências',
    2: 'Você não possui experiências.',
  },
  en_US: {
    1: 'My experiences',
    2: 'You do not have experiences.',
  },
};

class Trips extends Component {
  componentWillMount() {
    this.props.fetchOrders();
  }
  componentWillUnmount() {
    // this.props.resetOrdersPage();
  }
  render() {
    const {
      match,
      orders,
      isLoading,
      error,
    } = this.props;
    const locale = match.params.locale ? match.params.locale : 'pt_BR';
    return (
      <Container>
        {isLoading === true &&
          <ReactLoading delay={0} className="spin" type="spin" color="#ccc" height={24} width={24} />
        }
        {error &&
          <Alert color="danger">
            {`Oops! ${error.message}`}
          </Alert>
        }
        {isLoading === false &&
          <div>
            <h1>{localization[locale][1]}</h1>
            <Row>
              <Col>
                {
                  orders.map(order => (
                    <TripRow key={order.objectId} order={order} />
                  ))
                }
                {orders.length === 0 &&
                  localization[locale][2]
                }
              </Col>
            </Row>
          </div>
        }
      </Container>
    );
  }
}

Trips.propTypes = {
  match: PropTypes.shape({}),
  fetchOrders: PropTypes.func,
  orders: PropTypes.arrayOf(PropTypes.shape({})),
  isLoading: PropTypes.bool,
  error: PropTypes.shape({}),
};

function mapStateToProps({ ordersReducer }) {
  return ordersReducer;
}
function mapDispatchToProps(dispatch) {
  return {
    fetchOrders: () => {
      dispatch(fetchOrders());
    },
    // resetOrdersPage: () => {
    //   dispatch(resetOrdersPage());
    // }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Trips);
