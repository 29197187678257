import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Container, Col, Row } from 'reactstrap';
import Slider from 'react-slick';
import isMobile from 'ismobilejs';
import { fetchFeaturedContents, fetchTags } from './../actions';

const settings = {
  autoplay: true,
  autoplaySpeed: 10000,
  arrows: false,
  dots: true,
  lazyLoad: true,
  infinite: true,
  slidesToScroll: 1,
  slidesToShow: 1,
  speed: 1000,
};

const localization = {
  pt_BR: {
    Destinos: 'Destinos',
    'Lista de destinos que oferecemos para sua viagem voluntária.': 'Lista de destinos que oferecemos para sua viagem voluntária.',
    Atuação: 'Atuação',
    'Saúde, educação, animais... Veja a área que mais tem a ver com você.': 'Saúde, educação, animais... Veja a área que mais tem a ver com você.',
    Custo: 'Custo',
    'Há uma forma de ajudar para todos os bolsos.': 'Há uma forma de ajudar para todos os bolsos.',
    a: 'a',
    'Acima de': 'Acima de',
    'Projetos VV': 'Projetos VV',
    'Organizamos eventos como palestras e workshops em comunidades em desenvolvimento no mundo inteiro.': 'Organizamos eventos como palestras e workshops em comunidades em desenvolvimento no mundo inteiro.',
    '/quem-somos': '/quem-somos',
    '/destinos': '/destinos',
    '/projetos': '/projetos',
    '/faq': '/faq',
  },
  en_US: {
    Destinos: 'Destinations',
    'Lista de destinos que oferecemos para sua viagem voluntária.': 'List of destinations we offer for your volunteer vacation.',
    Atuação: 'Actions',
    'Saúde, educação, animais... Veja a área que mais tem a ver com você.': 'Health, education, animal care... Find the area that suits you best.',
    Custo: 'Cost',
    'Há uma forma de ajudar para todos os bolsos.': 'There are ways to help to suit any pocket.',
    a: 'to',
    'Acima de': 'Above',
    'Projetos VV': 'VV Projects',
    'Organizamos eventos como palestras e workshops em comunidades em desenvolvimento no mundo inteiro.': 'We organize events such as lectures and workshops in developing communities all over the world.',
    '/quem-somos': '/about',
    '/destinos': '/en_US/destinations',
    '/projetos': '/projects',
    '/faq': '/en_US/faq',
  },
};

class Home extends Component {
  componentWillMount() {
    this.props.fetchFeaturedContents();
    this.props.fetchTags();
  }
  render() {
    const {
      match,
      history,
      featuredContents,
      destinations,
      tags,
      categories,
      isLoading,
    } = this.props;
    const locale = match.params.locale ? match.params.locale : 'pt_BR';
    tags.sort((a, b) => {
      if (a[locale].nameNoDiacritics < b[locale].nameNoDiacritics) {
        return -1;
      }
      if (a[locale].nameNoDiacritics > b[locale].nameNoDiacritics) {
        return 1;
      }
      return 0;
    });
    return (
      <div>
        <div>
          {isLoading === false &&
            <Slider {...settings} className="slider" >
              {
                featuredContents.map(featuredContent => (
                  <div className="slide" key={featuredContent.objectId}>
                    {featuredContent.cover.fileType.ext !== 'mp4' &&
                      <img className="img-fluid" src={featuredContent.cover.largeFile.url} alt={featuredContent[locale].title} />
                    }
                    {featuredContent.cover.fileType.ext === 'mp4' &&
                      <video
                        width="100%"
                        controls={isMobile.any}
                        autoPlay={!isMobile.any}
                        muted
                        loop
                        src={`${process.env.S3_BUCKET_URL}/${featuredContent.cover.file.name}`}
                        type={featuredContent.cover.fileType.mime}
                      />
                    }
                    <Container>
                      <Row>
                        <Col xs="9" sm="9" md="8">
                          <h1>{featuredContent[locale].title}</h1>
                          {featuredContent[locale].buttonLink
                            && featuredContent[locale].buttonTitle &&
                            <Button
                              color="primary"
                              onClick={(e) => {
                                e.preventDefault();
                                history.push(featuredContent[locale].buttonLink);
                              }}
                            >
                              {featuredContent[locale].buttonTitle}
                            </Button>
                          }
                        </Col>
                        <Col />
                      </Row>
                    </Container>
                  </div>
                ))
              }
            </Slider>
          }
        </div>
        <Container className="home" style={{ paddingTop: 15 }}>
          <Row className="text-center">
            <Col className="zoom">
              <img width={200} src="/assets/home-icon-box-1.png" alt="home-icon-box" />
              <h5>{localization[locale].Destinos}</h5>
              <div style={{ textAlign: 'justify' }}>
                {localization[locale]['Lista de destinos que oferecemos para sua viagem voluntária.']}
              </div>
              <Col style={{ marginTop: 10 }}>
                {
                  destinations.map(destination => (
                    <Row key={destination.objectId}>
                      <Button
                        size="sm"
                        color="primary"
                        style={{ marginBottom: 5 }}
                        onClick={(e) => {
                          e.preventDefault();
                          history.push(`${localization[locale]['/destinos']}/${destination.slug}`);
                        }}
                      >
                        {destination[locale].name}
                      </Button>
                    </Row>
                  ))
                }
              </Col>
            </Col>
            <Col className="zoom">
              <img width={200} src="/assets/home-icon-box-2.png" alt="home-icon-box" />
              <h5>{localization[locale]['Atuação']}</h5>
              <div style={{ textAlign: 'justify' }}>
                {localization[locale]['Saúde, educação, animais... Veja a área que mais tem a ver com você.']}
              </div>
              <Col style={{ marginTop: 10 }}>
                {
                  tags.map(tag => (
                    <Row key={tag.objectId}>
                      <Button
                        size="sm"
                        color="primary"
                        style={{ marginBottom: 5 }}
                        onClick={(e) => {
                          e.preventDefault();
                          history.push(`${locale === 'pt_BR' ? '' : locale}${localization[locale]['/projetos']}?tag=${tag.name}`);
                        }}
                      >
                        {tag[locale].name}
                      </Button>
                    </Row>
                  ))
                }
              </Col>
            </Col>
            <Col className="zoom">
              <img width={200} src="/assets/home-icon-box-3.png" alt="home-icon-box" />
              <h5>{localization[locale].Custo}</h5>
              <div style={{ textAlign: 'justify' }}>
                {localization[locale]['Há uma forma de ajudar para todos os bolsos.']}
              </div>
              <Col>
                <Row>
                  <Button
                    size="sm"
                    color="primary"
                    style={{ marginTop: 10, marginBottom: 5 }}
                    onClick={(e) => {
                      e.preventDefault();
                      history.push(`${locale === 'pt_BR' ? '' : locale}${localization[locale]['/projetos']}?minAmount=29000&maxAmount=58000`);
                    }}
                  >
                    {`$290 ${localization[locale].a} $580`}
                  </Button>
                </Row>
                <Row>
                  <Button
                    size="sm"
                    color="primary"
                    style={{ marginBottom: 5 }}
                    onClick={(e) => {
                      e.preventDefault();
                      history.push(`${locale === 'pt_BR' ? '' : locale}${localization[locale]['/projetos']}?minAmount=59000&maxAmount=108000`);
                    }}
                  >
                    {`$590 ${localization[locale].a} $1.080`}
                  </Button>
                </Row>
                <Row>
                  <Button
                    size="sm"
                    color="primary"
                    style={{ marginBottom: 5 }}
                    onClick={(e) => {
                      e.preventDefault();
                      history.push(`${locale === 'pt_BR' ? '' : locale}${localization[locale]['/projetos']}?minAmount=109000&maxAmount=158000`);
                    }}
                  >
                    {`$1.090 ${localization[locale].a} $1.580`}
                  </Button>
                </Row>
                <Row>
                  <Button
                    size="sm"
                    color="primary"
                    onClick={(e) => {
                      e.preventDefault();
                      history.push(`${locale === 'pt_BR' ? '' : locale}${localization[locale]['/projetos']}?minAmount=159000`);
                    }}
                  >
                    {`${localization[locale]['Acima de']} $1.590`}
                  </Button>
                </Row>
              </Col>
            </Col>
            <Col className="zoom">
              <img width={200} src="/assets/home-icon-box-4.png" alt="home-icon-box" />
              <h5>{localization[locale]['Projetos VV']}</h5>
              <div style={{ textAlign: 'justify' }}>
                {localization[locale]['Organizamos eventos como palestras e workshops em comunidades em desenvolvimento no mundo inteiro.']}
              </div>
              <Col style={{ marginTop: 10 }}>
                {
                  categories.map(category => (
                    <div key={category.objectId}>
                      {category.slug !== 'projetos' &&
                        <Row>
                          <Button
                            size="sm"
                            color="primary"
                            style={{ marginBottom: 5 }}
                            onClick={(e) => {
                              e.preventDefault();
                              history.push(`${locale === 'pt_BR' ? '/projetos-vv/' : `${locale}/vv-projects/`}${category.slug}`);
                            }}
                          >
                            {category[locale].name}
                          </Button>
                        </Row>
                      }
                    </div>
                  ))
                }
              </Col>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

Home.propTypes = {
  match: PropTypes.shape({}),
  history: PropTypes.shape({}),
  fetchFeaturedContents: PropTypes.func,
  fetchTags: PropTypes.func,
  featuredContents: PropTypes.arrayOf(PropTypes.shape({})),
  destinations: PropTypes.arrayOf(PropTypes.shape({})),
  tags: PropTypes.arrayOf(PropTypes.shape({})),
  categories: PropTypes.arrayOf(PropTypes.shape({})),
  isLoading: PropTypes.bool,
  error: PropTypes.shape({}),
};

function mapStateToProps({
  featuredContentsReducer,
  destinationsReducer,
  tagsReducer,
  categoriesReducer,
}) {
  return {
    ...destinationsReducer,
    ...tagsReducer,
    ...categoriesReducer,
    ...featuredContentsReducer,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    fetchFeaturedContents: () => {
      dispatch(fetchFeaturedContents());
    },
    fetchTags: () => {
      dispatch(fetchTags());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
