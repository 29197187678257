import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Alert, CardDeck, Col, Container, Row } from 'reactstrap';
import ReactLoading from 'react-loading';
import PartnerCard from './PartnerCard';
import { fetchPartners } from './../actions';

const localization = {
  pt_BR: {
    Parceiros: 'Parceiros',
  },
  en_US: {
    Parceiros: 'Partners',
  },
};

class Partners extends Component {
  componentWillMount() {
    const { skip, lastDate } = this.props;
    this.props.fetchPartners(skip, lastDate);
  }
  render() {
    const {
      match,
      partners,
      isLoading,
      error,
    } = this.props;
    const locale = match.params.locale ? match.params.locale : 'pt_BR';
    return (
      <div style={{ marginTop: 60 }}>
        <div className="cover text-center">
          <img width="75%" className="img-fluid" src="/assets/cover-partners.jpg" alt="cover" />
        </div>
        <Container style={{ paddingTop: 40 }}>
          <Row>
            <Col>
              {isLoading === true &&
                <ReactLoading delay={0} className="spin" type="spin" color="#ccc" height={24} width={24} />
              }
              {error &&
                <Alert color="danger">
                  {`Oops! ${error.message}`}
                </Alert>
              }
              <h1>{localization[locale].Parceiros}</h1>
              <CardDeck>
                {
                  partners.map(partner => (
                    <PartnerCard key={partner.name} partner={partner} />
                  ))
                }
              </CardDeck>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

Partners.propTypes = {
  fetchPartners: PropTypes.func,
  match: PropTypes.shape({}),
  skip: PropTypes.number,
  lastDate: PropTypes.shape({}),
  // lastPage: PropTypes.bool,
  partners: PropTypes.arrayOf(PropTypes.shape({})),
  isLoading: PropTypes.bool,
  error: PropTypes.shape({}),
};

function mapStateToProps({ partnerReducer }) {
  return { ...partnerReducer };
}
function mapDispatchToProps(dispatch) {
  return {
    fetchPartners: (skip, lastDate) => {
      dispatch(fetchPartners(skip, lastDate));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Partners);
