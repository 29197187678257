import {
  CHANGE_USER_FIELD,
  SIGN_UP,
  SIGN_UP_SUCCESS,
  SIGN_UP_FAILURE,
  LOG_IN,
  LOG_IN_SUCCESS,
  LOG_IN_FAILURE,
  LOG_OUT,
  LOG_OUT_SUCCESS,
  LOG_OUT_FAILURE,
  RESET_USER,
  FETCH_USER,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILURE,
} from '../actions/types';

const INITIAL_STATE = {
  firstName: '',
  lastName: '',
  email: '',
  cpf: '',
  identity: '',
  birthDate: '',
  password: '',
  phoneNumber: '',
  nationality: '',
  submit: false,
  isLoading: false,
  user: null,
  success: null,
  error: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CHANGE_USER_FIELD:
      return {
        ...state,
        [action.payload.prop]: action.payload.value,
      };
    case SIGN_UP:
      return {
        ...state,
        submit: true,
        isLoading: true,
        error: null,
      };
    case SIGN_UP_SUCCESS:
      return {
        ...state,
        ...action.payload,
        success: true,
        isLoading: false,
      };
    case SIGN_UP_FAILURE:
      return {
        ...state,
        ...action.payload,
        success: false,
        isLoading: false,
      };
    case LOG_IN:
      return {
        ...state,
        submit: true,
        isLoading: true,
        error: null,
      };
    case LOG_IN_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      };
    case LOG_IN_FAILURE:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      };
    case LOG_OUT:
      return {
        ...state,
        isLoading: true,
      };
    case LOG_OUT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: null,
        error: null,
      };
    case LOG_OUT_FAILURE:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      };
    case RESET_USER:
      return {
        ...state,
        submit: false,
        isLoading: false,
        error: null,
      };
    case FETCH_USER:
      return {
        ...state,
        isLoading: true,
        success: null,
        error: null,
      };
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        ...action.payload,
      };
    case FETCH_USER_FAILURE:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};
