import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Alert, CardDeck, Col, Container, Row } from 'reactstrap';
import ReactLoading from 'react-loading';
import { fetchProjects, resetProjectsPage } from './../actions';
import ProjectCard from './ProjectCard';

const localization = {
  pt_BR: {
    name: 'VV Thinking',
    title: 'VV Thinking, formando empreendedores sociais pelo mundo',
    text: `A VV Thinking é a iniciativa da Volunteer Vacations para formar empreendedores sociais.
      A VV acredita no potencial do empreendedorismo social no mundo, sabe que esse tipo de
      iniciativa pode transformar comunidades, cidades e até mesmo países inteiros.<br/>
      Para fomentar esta prática e formar novos líderes, a VV criou junto com a coordenação do
      prof. Thiago Almeida (IBMEC e ESPM) o curso VV Thinking. São aulas exclusivas com empreendedores
      sociais, certificado de participação, imersão no campo de atuação e todo um ambiente
      criativo e especializado onde cada aluno sai com lições importantes para abrir ou mesmo
      aprimorar o próprio negócio.`,
    'Edições na VV Thinking': 'Edições na VV Thinking',
  },
  en_US: {
    name: 'VV Thinking',
    title: '',
    text: '',
    'Edições na VV Thinking': '',
  },
};

class Thinking extends Component {
  componentWillMount() {
    this.props.fetchProjects(0, new Date(), { category: 'vv-thinking' });
  }
  componentWillUnmount() {
    this.props.resetProjectsPage();
  }
  render() {
    const {
      match,
      projects,
      isLoading,
      error,
    } = this.props;
    const locale = match.params.locale ? match.params.locale : 'pt_BR';
    return (
      <div style={{ marginTop: 60 }}>
        {!error && isLoading === false &&
          <div className="cover">
            <img alt="cover" className="img-fluid" src="/assets/cover-vv-thinking.jpg" />
          </div>
        }
        <Container style={{ paddingTop: 40 }}>
          {isLoading === true &&
            <ReactLoading delay={0} className="spin" type="spin" color="#ccc" height={24} width={24} />
          }
          {error &&
            <Alert color="danger">
              {`Oops! ${error.message}`}
            </Alert>
          }
          {!error && isLoading === false &&
            <div>
              <h1>{localization[locale].name}</h1>
              <h3>{localization[locale].title}</h3>
              {localization[locale].text}
              <hr />
              <h3>{localization[locale]['Edições na VV Thinking']}</h3>
              <Row>
                <Col>
                  <CardDeck>
                    {
                      projects.map(project => (
                        <ProjectCard key={project.objectId} project={project} />
                      ))
                    }
                  </CardDeck>
                </Col>
              </Row>
            </div>
          }
        </Container>
      </div>
    );
  }
}

Thinking.propTypes = {
  match: PropTypes.shape({}),
  fetchProjects: PropTypes.func,
  resetProjectsPage: PropTypes.func,
  projects: PropTypes.arrayOf(PropTypes.shape({})),
  isLoading: PropTypes.bool,
  error: PropTypes.shape({}),
};

function mapStateToProps({ projectsReducer }) {
  return projectsReducer;
}
function mapDispatchToProps(dispatch) {
  return {
    fetchProjects: (skip, lastDate, filters) => {
      dispatch(fetchProjects(skip, lastDate, filters));
    },
    resetProjectsPage: () => {
      dispatch(resetProjectsPage());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Thinking);
