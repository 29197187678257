export const SHOW_SIGN_UP_MODAL = 'SHOW_SIGN_UP_MODAL';
export const HIDE_SIGN_UP_MODAL = 'HIDE_SIGN_UP_MODAL';
export const CHANGE_SIGN_UP_FIELD = 'CHANGE_SIGN_UP_FIELD';
export const RESET_SIGN_UP_MODAL = 'RESET_SIGN_UP_MODAL';
export const SHOW_LOG_IN_MODAL = 'SHOW_LOG_IN_MODAL';
export const HIDE_LOG_IN_MODAL = 'HIDE_LOG_IN_MODAL';
export const CHANGE_LOG_IN_FIELD = 'CHANGE_LOG_IN_FIELD';
export const RESET_LOG_IN_MODAL = 'RESET_LOG_IN_MODAL';
export const SIGN_UP = 'REGISTER';
export const SIGN_UP_SUCCESS = 'REGISTER_SUCCESS';
export const SIGN_UP_FAILURE = 'REGISTER_FAILURE';
export const LOG_IN = 'LOG_IN';
export const LOG_IN_SUCCESS = 'LOG_IN_SUCCESS';
export const LOG_IN_FAILURE = 'LOG_IN_FAILURE';
export const LOG_OUT = 'LOG_OUT';
export const LOG_OUT_SUCCESS = 'LOG_OUT_SUCCESS';
export const LOG_OUT_FAILURE = 'LOG_OUT_FAILURE';
export const RESET_USER = 'RESET_USER';
export const FETCH_USER = 'FETCH_USER';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';
export const CHANGE_USER_FIELD = 'CHANGE_USER_FIELD';

export const REQUEST_RESET_PASSWORD = 'REQUEST_RESET_PASSWORD';
export const REQUEST_RESET_PASSWORD_SUCCESS = 'REQUEST_RESET_PASSWORD_SUCCESS';
export const REQUEST_RESET_PASSWORD_FAILURE = 'REQUEST_RESET_PASSWORD_FAILURE';
export const CHANGE_LOST_PASSWORD_FIELD = 'CHANGE_LOST_PASSWORD_FIELD';
export const RESET_LOST_PASSWORD_FORM = 'RESET_LOST_PASSWORD_FORM';

export const RESET_CONTACT_FORM = 'RESET_CONTACT_FORM';
export const CHANGE_CONTACT_FIELD = 'CHANGE_CONTACT_FIELD';
export const SAVE_CONTACT = 'SAVE_CONTACT';
export const SAVE_CONTACT_SUCCESS = 'SAVE_CONTACT_SUCCESS';
export const SAVE_CONTACT_FAILURE = 'SAVE_CONTACT_FAILURE';

export const FETCH_CATEGORIES = 'FETCH_CATEGORIES';
export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS';
export const FETCH_CATEGORIES_FAILURE = 'FETCH_CATEGORIES_FAILURE';
export const FETCH_CATEGORY = 'FETCH_CATEGORY';
export const FETCH_CATEGORY_SUCCESS = 'FETCH_CATEGORY_SUCCESS';
export const FETCH_CATEGORY_FAILURE = 'FETCH_CATEGORY_FAILURE';
export const RESET_CATEGORY_PAGE = 'RESET_CATEGORY_PAGE';

export const FETCH_DESTINATIONS = 'FETCH_DESTINATIONS';
export const FETCH_DESTINATIONS_SUCCESS = 'FETCH_DESTINATIONS_SUCCESS';
export const FETCH_DESTINATIONS_FAILURE = 'FETCH_DESTINATIONS_FAILURE';
export const FETCH_DESTINATION = 'FETCH_DESTINATION';
export const FETCH_DESTINATION_SUCCESS = 'FETCH_DESTINATION_SUCCESS';
export const FETCH_DESTINATION_FAILURE = 'FETCH_DESTINATION_FAILURE';
export const RESET_DESTINATION_PAGE = 'RESET_DESTINATION_PAGE';

export const FETCH_TAGS = 'FETCH_TAGS';
export const FETCH_TAGS_SUCCESS = 'FETCH_TAGS_SUCCESS';
export const FETCH_TAGS_FAILURE = 'FETCH_TAGS_FAILURE';

export const FETCH_PROJECTS = 'FETCH_PROJECTS';
export const FETCH_PROJECTS_SUCCESS = 'FETCH_PROJECTS_SUCCESS';
export const FETCH_PROJECTS_FAILURE = 'FETCH_PROJECTS_FAILURE';
export const FETCH_OLD_PROJECTS = 'FETCH_OLD_PROJECTS';
export const FETCH_OLD_PROJECTS_SUCCESS = 'FETCH_OLD_PROJECTS_SUCCESS';
export const FETCH_OLD_PROJECTS_FAILURE = 'FETCH_OLD_PROJECTS_FAILURE';
export const RESET_PROJECTS_PAGE = 'RESET_PROJECTS_PAGE';

export const FETCH_PROJECT = 'FETCH_PROJECT';
export const FETCH_PROJECT_SUCCESS = 'FETCH_PROJECT_SUCCESS';
export const FETCH_PROJECT_FAILURE = 'FETCH_PROJECT_FAILURE';
export const RESET_PROJECT_PAGE = 'RESET_PROJECT_PAGE';

export const APPLY_COUPON = 'APPLY_COUPON';
export const APPLY_COUPON_SUCCESS = 'APPLY_COUPON_SUCCESS';
export const APPLY_COUPON_FAILURE = 'APPLY_COUPON_FAILURE';
export const RESET_ORDER_FORM = 'RESET_ORDER_FORM';
export const CHANGE_ORDER_FIELD = 'CHANGE_ORDER_FIELD';
export const FETCH_ORDERS = 'FETCH_ORDERS';
export const FETCH_ORDERS_SUCCESS = 'FETCH_ORDERS_SUCCESS';
export const FETCH_ORDERS_FAILURE = 'FETCH_ORDERS_FAILURE';
export const FETCH_ORDER = 'FETCH_ORDER';
export const FETCH_ORDER_SUCCESS = 'FETCH_ORDER_SUCCESS';
export const FETCH_ORDER_FAILURE = 'FETCH_ORDER_FAILURE';
export const SAVE_ORDER = 'SAVE_ORDER';
export const SAVE_ORDER_SUCCESS = 'SAVE_ORDER_SUCCESS';
export const SAVE_ORDER_FAILURE = 'SAVE_ORDER_FAILURE';
export const RESET_ORDER_PAGE = 'RESET_ORDER_PAGE';

export const RESET_CHECKOUT_FORM = 'RESET_CHECKOUT_FORM';
export const START_CHECKOUT = 'START_CHECKOUT';
export const START_CHECKOUT_SUCCESS = 'START_CHECKOUT_SUCCESS';
export const START_CHECKOUT_FAILURE = 'START_CHECKOUT_FAILURE';
export const FETCH_BRAND = 'FETCH_BRAND';
export const FETCH_BRAND_SUCCESS = 'FETCH_BRAND_SUCCESS';
export const FETCH_BRAND_FAILURE = 'FETCH_BRAND_FAILURE';
export const POSTAL_CODE_ADDRESS = 'POSTAL_CODE_ADDRESS';
export const POSTAL_CODE_ADDRESS_SUCCESS = 'POSTAL_CODE_ADDRESS_SUCCESS';
export const POSTAL_CODE_ADDRESS_FAILURE = 'POSTAL_CODE_ADDRESS_FAILURE';
export const CHANGE_CHECKOUT_FIELD = 'CHANGE_CHECKOUT_FIELD';
export const CHECKOUT = 'CHECKOUT';
export const CHECKOUT_SUCCESS = 'CHECKOUT_SUCCESS';
export const CHECKOUT_FAILURE = 'CHECKOUT_FAILURE';

export const FETCH_POSTS = 'FETCH_POSTS';
export const FETCH_POSTS_SUCCESS = 'FETCH_POSTS_SUCCESS';
export const FETCH_POSTS_FAILURE = 'FETCH_POSTS_FAILURE';
export const FETCH_POST = 'FETCH_POST';
export const FETCH_POST_SUCCESS = 'FETCH_POST_SUCCESS';
export const FETCH_POST_FAILURE = 'FETCH_POST_FAILURE';
export const RESET_BLOG_PAGE = 'RESET_BLOG_PAGE';

export const FETCH_PARTNERS = 'FETCH_PARTNERS';
export const FETCH_PARTNERS_SUCCESS = 'FETCH_PARTNERS_SUCCESS';
export const FETCH_PARTNERS_FAILURE = 'FETCH_PARTNERS_FAILURE';

export const FETCH_PRESS = 'FETCH_PRESS';
export const FETCH_PRESS_SUCCESS = 'FETCH_PRESS_SUCCESS';
export const FETCH_PRESS_FAILURE = 'FETCH_PRESS_FAILURE';

export const FETCH_PAGE = 'FETCH_PAGE';
export const FETCH_PAGE_SUCCESS = 'FETCH_PAGE_SUCCESS';
export const FETCH_PAGE_FAILURE = 'FETCH_PAGE_FAILURE';

export const FETCH_FEATURED_CONTENTS = 'FETCH_FEATURED_CONTENTS';
export const FETCH_FEATURED_CONTENTS_SUCCESS = 'FETCH_FEATURED_CONTENTS_SUCCESS';
export const FETCH_FEATURED_CONTENTS_FAILURE = 'FETCH_FEATURED_CONTENTS_FAILURE';

export const SHOW_MEDIA_MODAL = 'SHOW_MEDIA_MODAL';
export const HIDE_MEDIA_MODAL = 'HIDE_MEDIA_MODAL';
