import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import currencyFormatter from 'currency-formatter';
import moment from 'moment-timezone';

const localization = {
  pt_BR: {
    'Comprar Agora': 'Comprar Agora',
    '/finalizar-compra': '/finalizar-compra',
    'dia(s)': 'dia(s)',
    1: 'Periodo / Custo',
    2: 'Valor Total de Pagamento',
    3: 'Data de Preferência',
    7: 'Data e Hora de Chegada',
    8: 'Data e Horário de Partida',
  },
  en_US: {
    'Comprar Agora': 'Buy Now',
    '/finalizar-compra': '/checkout',
    'dia(s)': 'day(s)',
    1: 'Period / Cost',
    2: 'Total Payment Amount',
    3: 'Date of Preference',
    7: 'Date and Time of Arrival',
    8: 'Date and Time of Departure',
  },
};

class TripRow extends Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }
  onClick(e) {
    e.preventDefault();
    const { match, history, order } = this.props;
    const locale = match.params.locale ? match.params.locale : 'pt_BR';
    history.push(`${locale === 'pt_BR' ? '' : `/${locale}`}${localization[locale]['/finalizar-compra']}/${order.objectId}`);
  }
  render() {
    const { match, order } = this.props;
    const locale = match.params.locale ? match.params.locale : 'pt_BR';
    const symbol = order.currency === 'USD' ? { symbol: 'US$ ' } : null;
    return (
      <Link className="trip-row" href={`/viagens/${order.objectId}`} to={`/viagens/${order.objectId}`}>
        <Row>
          <Col xs="auto" style={{ marginLeft: 15 }}>
            <div className="image">
              <img
                width={200}
                src={order.project.cover.mediumFile.url}
                alt={order.project[locale].name}
              />
            </div>
          </Col>
          <Col>
            <Row>
              <Col>
                <h3>{order.project[locale].name}</h3>
                <h5 style={{ color: order.status.color }}>
                  {order.status[locale].name}
                </h5>
                {order.paymentAmount &&
                  <div>
                    <hr />
                    <h5 style={{ marginTop: 10 }}>{localization[locale][2]}</h5>
                    {currencyFormatter.format(order.paymentAmount / 100, { code: 'BRL' })}
                  </div>
                }
              </Col>
              <Col xs="atuo">
                {order.status.slug === 'aprovado-para-pagamento' && !order.transaction &&
                  <Button color="primary" style={{ marginRight: 15 }} onClick={this.onClick}>
                    <img alt="logo" style={{ marginTop: -4, marginRight: 5 }} height={20} src="/assets/logo-white.png" />
                    {localization[locale]['Comprar Agora']}
                  </Button>
                }
              </Col>
            </Row>
            <hr />
            <h5>{localization[locale][1]}</h5>
            {`${order.days} ${localization[locale]['dia(s)']} - ${currencyFormatter.format(order.amount / 100, { code: order.currency.toUpperCase(), ...symbol })}${order.details ? ` - ${order.details[locale]}` : ''}`}
            <br /><br />
            {order.date &&
              <div>
                <h5 style={{ marginTop: 10 }}>{localization[locale][3]}</h5>
                {moment(order.date.iso).format('DD/MM/YYYY')}<br />
              </div>
            }
            {order.project.startDate && order.project.timeZone &&
              <div>
                <h5>{localization[locale][7]}</h5>
                {moment(order.project.startDate.iso).tz(order.project.timeZone).format('DD, MMMM YYYY, HH:mm')}
                <br />
                <br />
              </div>
            }
            {order.project.endDate && order.project.timeZone &&
              <div>
                <h5>{localization[locale][8]}</h5>
                {moment(order.project.endDate.iso).tz(order.project.timeZone).format('DD, MMMM YYYY, HH:mm')}
                <br />
                <br />
              </div>
            }
          </Col>
        </Row>
        <hr />
      </Link>
    );
  }
}

TripRow.propTypes = {
  match: PropTypes.shape({}),
  history: PropTypes.shape({}),
  order: PropTypes.shape({}),
};

export default withRouter(TripRow);
