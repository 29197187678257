import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Alert, CardDeck, Col, Container, Row } from 'reactstrap';
import ReactLoading from 'react-loading';
import DestinationCard from './DestinationCard';

const localization = {
  pt_BR: {
    Destinos: 'Destinos',
  },
  en_US: {
    Destinos: 'Destinations',
  },
};

function Destinations(props) {
  const {
    match,
    destinations,
    isLoading,
    error,
  } = props;
  const locale = match.params.locale ? match.params.locale : 'pt_BR';
  return (
    <div style={{ marginTop: 60 }}>
      <div className="cover text-center">
        <img width="75%" className="img-fluid" src="/assets/slide-2.png" alt="cover" />
      </div>
      <Container style={{ paddingTop: 40 }}>
        <Row>
          <Col>
            {isLoading === true &&
              <ReactLoading delay={0} className="spin" type="spin" color="#ccc" height={24} width={24} />
            }
            {error &&
              <Alert color="danger">
                {`Oops! ${error.message}`}
              </Alert>
            }
            <h1>{localization[locale].Destinos}</h1>
            <CardDeck>
              {
                destinations.map(destination => (
                  <DestinationCard key={destination.objectId} destination={destination} />
                ))
              }
            </CardDeck>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

Destinations.propTypes = {
  match: PropTypes.shape({}),
  destinations: PropTypes.arrayOf(PropTypes.shape({})),
  isLoading: PropTypes.bool,
  error: PropTypes.shape({}),
};

const mapStateToProps = ({ destinationsReducer }) => destinationsReducer;

export default connect(mapStateToProps, null)(Destinations);
