import moment from 'moment';
import {
  SHOW_SIGN_UP_MODAL,
  HIDE_SIGN_UP_MODAL,
  CHANGE_SIGN_UP_FIELD,
  RESET_SIGN_UP_MODAL,
} from '../actions/types';

const INITIAL_STATE = {
  isShowing: false,
  firstName: '',
  lastName: '',
  email: '',
  cpf: '',
  identity: '',
  birthDate: moment(),
  password: '',
  phoneNumber: '',
  nationality: 'brasileira',
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SHOW_SIGN_UP_MODAL:
      return {
        ...state,
        isShowing: true,
      };
    case HIDE_SIGN_UP_MODAL:
      return {
        ...state,
        isShowing: false,
      };
    case CHANGE_SIGN_UP_FIELD:
      return {
        ...state,
        [action.payload.prop]: action.payload.value,
      };
    case RESET_SIGN_UP_MODAL:
      return {
        ...state,
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        phoneNumber: '',
      };
    default:
      return state;
  }
};
