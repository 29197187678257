import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Alert, Col, Container, Row } from 'reactstrap';
import ReactLoading from 'react-loading';
import ReactHtmlParser from 'react-html-parser';
import moment from 'moment';
import { fetchPost } from './../actions';

class Post extends Component {
  componentWillMount() {
    const { match } = this.props;
    this.props.fetchPost(match.params.slug);
  }
  render() {
    const { post, isLoading, error } = this.props;
    return (
      <Container>
        {isLoading === true &&
          <ReactLoading delay={0} className="spin" type="spin" color="#ccc" height={24} width={24} />
        }
        {error &&
          <Alert color="danger">
            {`Oops! ${error.message}`}
          </Alert>
        }
        {post &&
          <div>
            <h3>{post.name}</h3>
            <small><i>{moment(post.date).format('DD/MM/YYYY H:mm')}</i><br /></small>
            <Row>
              {post.media.length > 0 &&
                <Col xs={12} sm={12} md={6}>
                  {
                    post.media.map(media => (
                      <img width="100%" style={{ marginBottom: 30 }} key={media.objectId} src={media.media.largeFile.url} alt={post.name} />
                    ))
                  }
                </Col>
              }
              <Col xs={12} sm={12} md={6}>
                <div className="linebreak">
                  {ReactHtmlParser(post.text)}
                </div>
              </Col>
            </Row>
          </div>
        }
      </Container>
    );
  }
}

Post.propTypes = {
  match: PropTypes.shape({}),
  fetchPost: PropTypes.func,
  post: PropTypes.shape({}),
  isLoading: PropTypes.bool,
  error: PropTypes.shape({}),
};

function mapStateToProps({ postReducer }) {
  return postReducer;
}
function mapDispatchToProps(dispatch) {
  return {
    fetchPost: (slug) => {
      dispatch(fetchPost(slug));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Post);
