import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { hideMediaModal } from './../actions';

class MediaModal extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
  }
  toggle() {
    this.props.hideMediaModal();
  }
  render() {
    const { media, isShowing } = this.props;
    return (
      <Modal
        autoFocus={false}
        className="modal-lg media-modal"
        isOpen={isShowing}
        toggle={this.toggle}
      >
        <ModalHeader toggle={this.toggle} />
        <ModalBody>
          {media && media.fileType.ext === 'mp4' &&
            <video
              autoPlay
              muted
              loop
              src={`${process.env.S3_BUCKET_URL}/${media.file.name}`}
              type={media.fileType.mime}
            />
          }
          {media && media.fileType.ext !== 'mp4' &&
            <img alt="media" className="img-fluid" src={media.largeFile.url} />
          }
        </ModalBody>
      </Modal>
    );
  }
}

MediaModal.propTypes = {
  hideMediaModal: PropTypes.func,
  media: PropTypes.shape({}),
  isShowing: PropTypes.bool,
};

function mapStateToProps({ mediaModalReducer }) {
  const { isShowing, media } = mediaModalReducer;
  return {
    isShowing, media,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    hideMediaModal: () => {
      dispatch(hideMediaModal());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MediaModal);
