import {
  FETCH_PROJECT,
  FETCH_PROJECT_SUCCESS,
  FETCH_PROJECT_FAILURE,
  RESET_PROJECT_PAGE,
} from '../actions/types';

const INITIAL_STATE = {
  project: null,
  error: null,
  isLoading: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_PROJECT:
      return {
        ...state,
        project: null,
        error: null,
        isLoading: true,
      };
    case FETCH_PROJECT_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      };
    case FETCH_PROJECT_FAILURE:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      };
    case RESET_PROJECT_PAGE:
      return INITIAL_STATE;
    default:
      return state;
  }
};
