import { Parse } from 'parse';
import moment from 'moment';
import {
  RESET_ORDER_FORM,
  CHANGE_ORDER_FIELD,
  FETCH_ORDERS,
  FETCH_ORDERS_SUCCESS,
  FETCH_ORDERS_FAILURE,
  FETCH_ORDER,
  FETCH_ORDER_SUCCESS,
  FETCH_ORDER_FAILURE,
  SAVE_ORDER,
  SAVE_ORDER_SUCCESS,
  SAVE_ORDER_FAILURE,
  RESET_ORDER_PAGE,
} from './types';

export function resetOrderForm() {
  return {
    type: RESET_ORDER_FORM,
  };
}

export function changeOrderField({ prop, value }) {
  return {
    type: CHANGE_ORDER_FIELD,
    payload: { prop, value },
  };
}

export function fetchOrdersSuccess(orders) {
  return {
    type: FETCH_ORDERS_SUCCESS,
    payload: { orders },
  };
}

export function fetchOrdersFailure(error) {
  return {
    type: FETCH_ORDERS_FAILURE,
    payload: { error },
  };
}

export function fetchOrders() {
  return ((dispatch) => {
    dispatch({ type: FETCH_ORDERS });
    const sessionToken = localStorage.user ? JSON.parse(localStorage.user).sessionToken : null;
    return Parse.Cloud.run('fetchOrders', null, { sessionToken }).then((results) => {
      dispatch(fetchOrdersSuccess(JSON.parse(JSON.stringify(results))));
    }, (error) => {
      dispatch(fetchOrdersFailure(JSON.parse(JSON.stringify(error))));
    });
  });
}

export function fetchOrderSuccess(order) {
  return {
    type: FETCH_ORDER_SUCCESS,
    payload: { order },
  };
}

export function fetchOrderFailure(error) {
  return {
    type: FETCH_ORDER_FAILURE,
    payload: { error },
  };
}

export function fetchOrder(objectId) {
  return ((dispatch) => {
    dispatch({ type: FETCH_ORDER });
    const sessionToken = localStorage.user ? JSON.parse(localStorage.user).sessionToken : null;
    return Parse.Cloud.run('fetchOrder', { objectId }, { sessionToken }).then((results) => {
      dispatch(fetchOrderSuccess(JSON.parse(JSON.stringify(results))));
    }, (error) => {
      dispatch(fetchOrderFailure(JSON.parse(JSON.stringify(error))));
    });
  });
}

export function saveOrderSuccess(order) {
  return {
    type: SAVE_ORDER_SUCCESS,
    payload: { order },
  };
}

export function saveOrderFailure(error) {
  return {
    type: SAVE_ORDER_FAILURE,
    payload: { error },
  };
}

export function saveOrder(
  project,
  amount,
  date,
  answers,
  cpf,
  identity,
  birthDate,
  nationality,
  term,
) {
  return ((dispatch) => {
    dispatch({ type: SAVE_ORDER });
    if (!project || amount === -1 || (!project.startDate && !date) || !birthDate || !nationality || (nationality === 'brasileira' && term === false) || !identity) {
      dispatch(saveOrderFailure());
      return;
    }
    if (moment(date) < moment(new Date())) {
      dispatch(saveOrderFailure({ message: 'Data de preferência inválida' }));
      return;
    }
    const sessionToken = localStorage.user ? JSON.parse(localStorage.user).sessionToken : null;
    const params = {
      project: { objectId: project.objectId },
      amount: project.amounts[amount],
      date: date ? moment(date).toDate() : null,
      answers,
      cpf,
      identity,
      birthDate: moment(birthDate).toDate(),
      nationality,
    };
    Parse.Cloud.run('saveOrder', params, { sessionToken }).then((results) => {
      dispatch(saveOrderSuccess(JSON.parse(JSON.stringify(results))));
    }, (error) => {
      dispatch(saveOrderFailure(JSON.parse(JSON.stringify(error))));
    });
  });
}

export function resetOrderPage() {
  return {
    type: RESET_ORDER_PAGE,
  };
}
