import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Alert, Button, Col, Container, Row } from 'reactstrap';
import ReactLoading from 'react-loading';
import Slider from 'react-slick';
import currencyFormatter from 'currency-formatter';
import moment from 'moment-timezone';
import ReactHtmlParser from 'react-html-parser';
import { fetchProject, resetProjectPage, showSignUpModal } from './../actions';

const localization = {
  pt_BR: {
    1: 'Quero Participar',
    2: 'Informações',
    3: 'Períodos / Custos',
    4: 'Requisitos',
    5: 'Idade',
    6: 'anos',
    7: 'Data e Hora de Chegada',
    8: 'Data e Horário de Partida',
    9: 'dia(s)',
    10: 'de desconto',
    11: 'Projeto encerrado',
    12: 'Inscrições encerradas',
    '/inscricao': '/inscricao',
    '/projetos': '/projetos',
  },
  en_US: {
    1: 'I Want to Participate',
    2: 'Information',
    3: 'Period / Cost',
    4: 'Requirements',
    5: 'Age',
    6: 'years',
    7: 'Date and Time of Arrival',
    8: 'Date and Time of Departure',
    9: 'day(s)',
    10: 'of discount',
    11: 'Closed project',
    12: 'Subscription closed',
    '/inscricao': '/join',
    '/projetos': '/projects',
  },
};

const settings = {
  autoplay: true,
  autoplaySpeed: 10000,
  arrows: false,
  dots: true,
  lazyLoad: true,
  infinite: true,
  slidesToScroll: 1,
  slidesToShow: 1,
  speed: 1000,
};

class Project extends Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }
  componentWillMount() {
    const { match } = this.props;
    this.props.resetProjectPage();
    this.props.fetchProject(match.params.slug);
  }
  onClick(e) {
    e.preventDefault();
    const {
      match,
      history,
      user,
      project,
    } = this.props;
    const locale = match.params.locale ? match.params.locale : 'pt_BR';
    if (!user) {
      this.props.showSignUpModal();
    } else {
      history.push(`${locale === 'pt_BR' ? '' : `/${locale}`}${localization[locale]['/inscricao']}/${project.slug}`);
    }
  }
  render() {
    const {
      match,
      history,
      project,
      user,
      isLoading,
      error,
    } = this.props;
    const locale = match.params.locale ? match.params.locale : 'pt_BR';
    moment.locale(locale);
    const symbol = project && project.currency === 'USD' ? { symbol: 'US$ ' } : null;
    return (
      <div style={{ marginTop: 60 }}>
        {isLoading === true &&
          <ReactLoading delay={0} className="spin" type="spin" color="#ccc" height={24} width={24} />
        }
        {project && project.cover &&
          <div className="cover">
            <img width="100%" className="img-fluid" src={project.cover.largeFile.url} alt={project.name} />
          </div>
        }
        <Container style={{ paddingTop: 40 }}>
          <Row>
            <Col>
              {error &&
                <Alert color="danger">
                  {`'Oops! ${error.message}`}
                </Alert>
              }
              {project &&
                <div>
                  <h1>{project[locale].name}</h1>
                  <h5>{project.destination[locale].name}</h5>
                  <Row>
                    <Col>
                      {
                        project.tags.map(tag => (
                          <Button
                            size="sm"
                            color="primary"
                            key={tag}
                            style={{ margin: 2.5 }}
                            onClick={(e) => {
                              e.preventDefault();
                              history.push(`${locale === 'pt_BR' ? '' : locale}${localization[locale]['/projetos']}?tag=${tag}`);
                            }}
                          >
                            {tag}
                          </Button>
                        ))
                      }
                    </Col>
                  </Row>
                  {(project.startDate &&
                    moment(project.startDate).tz(project.timeZone) < moment().tz(project.timeZone))
                    &&
                    <div>
                      {localization[locale][11]}
                    </div>
                  }
                  {project.closed &&
                    <div>
                      {localization[locale][12]}
                    </div>
                  }
                  {!project.closed && (!project.startDate
                    || (moment(project.startDate).tz(project.timeZone)
                    > moment().tz(project.timeZone))) &&
                    <Button
                      style={{ marginTop: 10 }}
                      color="primary"
                      onClick={this.onClick}
                    >
                      <img style={{ marginTop: -4, marginRight: 5 }} height={20} src="/assets/logo-white.png" alt="logo" />
                      {localization[locale][1]}
                    </Button>
                  }
                  <hr />
                  <h3>{project[locale].title}</h3>
                  <div className="linebreak">
                    {ReactHtmlParser(project[locale].text)}
                  </div>
                  <hr />
                  <h3>{localization[locale][2]}</h3>
                  {project.amounts &&
                    <div>
                      <h5>{`${localization[locale][3]}${user && user.loyalCustomer ? ` (5% ${localization[locale][10]})` : ''}`}</h5>
                      {
                        project.amounts.map(amount => (
                          <li key={`${amount.amount}${amount.days}${amount.details ? ` - ${amount.details[locale]}` : ''}`} >
                            {`${amount.days} ${localization[locale][9]} - ${currencyFormatter.format(amount.amount / 100, { code: project.currency, ...symbol })}${amount.details ? ` - ${amount.details[locale]}` : ''}`}
                          </li>
                        ))
                      }
                      <br />
                    </div>
                  }
                  {project[locale].requirements &&
                    <div>
                      <h5>{localization[locale][4]}</h5>
                      <div className="linebreak">
                        {project[locale].requirements}
                      </div>
                      <br />
                    </div>
                  }
                  {project.fromAge &&
                    <div>
                      <h5>{localization[locale][5]}</h5>
                      {`${project.fromAge} ${localization[locale][6]}`}
                      <br />
                      <br />
                    </div>
                  }
                  {project.startDate && project.timeZone &&
                    <div>
                      <h5>{localization[locale][7]}</h5>
                      {moment(project.startDate).tz(project.timeZone).format('DD, MMMM YYYY, HH:mm')}
                      <br />
                      <br />
                    </div>
                  }
                  {project.endDate && project.timeZone &&
                    <div>
                      <h5>{localization[locale][8]}</h5>
                      {moment(project.endDate).tz(project.timeZone).format('DD, MMMM YYYY, HH:mm')}
                      <br />
                      <br />
                    </div>
                  }
                  <Slider {...settings} className="slider">
                    {
                      project.media.map((media, index) => (
                        index > 0 &&
                        <div key={media.objectId} className="slide">
                          <img className="img-fluid" src={media.media.largeFile.url} alt={project.name} />
                        </div>
                      ))
                    }
                  </Slider>
                </div>
              }
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

Project.propTypes = {
  history: PropTypes.shape({}),
  match: PropTypes.shape({}),
  fetchProject: PropTypes.func,
  resetProjectPage: PropTypes.func,
  showSignUpModal: PropTypes.func,
  project: PropTypes.shape({}),
  user: PropTypes.shape({}),
  isLoading: PropTypes.bool,
  error: PropTypes.shape({}),
};

function mapStateToProps({ userReducer, projectReducer }) {
  return { ...userReducer, ...projectReducer };
}
function mapDispatchToProps(dispatch) {
  return {
    fetchProject: (slug) => {
      dispatch(fetchProject(slug));
    },
    resetProjectPage: () => {
      dispatch(resetProjectPage());
    },
    showSignUpModal: () => {
      dispatch(showSignUpModal());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Project);
