import {
  FETCH_PARTNERS,
  FETCH_PARTNERS_SUCCESS,
  FETCH_PARTNERS_FAILURE,
} from '../actions/types';

const INITIAL_STATE = {
  partners: [],
  skip: 0,
  lastDate: new Date(),
  lastPage: false,
  error: null,
  isLoading: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_PARTNERS:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case FETCH_PARTNERS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        partners: state.partners ?
          state.partners.concat(action.payload.partners) : action.payload.partners,
        error: null,
        isLoading: false,
      };
    case FETCH_PARTNERS_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};
